<template>

  <el-tree-select :value="value" :data="treeList" :multiple="multiple" :check-strictly=true
                  :style="`width: ${width}`" @change="handleChangeValue"  default-expand-all node-key="id"
                  filterable clearable>
    <template #default="{ data: { label } }">
      <div style="width: 100%"> {{ label }}  </div>
    </template>
  </el-tree-select>
</template>

<script>
import {useDeptStore} from "@/stores/dept";
import {useCompanyStore} from "@/stores/company";
import {useUserStore} from "@/stores/user";
import {mapStores} from "pinia";

export default {
  emits: ['change'],
  props: {
    value: {
      type: [Array,String],
      default: ()=>[]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width:{
      type: [String, Number],
      default: () => "240px"
    }
  },
  computed: {
    ...mapStores(useDeptStore, useCompanyStore,useUserStore)
  },
  data() {
    return {
      treeList: [],
      select: '',
    }
  },
  created() {
    this.queryList()
  },
  methods: {
    queryList() {

      this.deptStore.list().then(({data}) => {
        let list = data.map(mapper => {
          return {
            id: mapper.id,
            pid: mapper.pid,
            label: mapper.name,
            value: mapper.id,
            children: []
          }
        })

        let toDelete = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].pid) {
            list.filter(filter => filter.id === list[i].pid)[0].children.push(list[i])
            toDelete.push(list[i].id)
          }
        }

        list = list.filter(filter => toDelete.indexOf(filter.id) === -1)

        this.treeList = [{
          id: 'root',
          label: this.companyStore.selectedName(),
          value: 'root',
          disabled:true,
          children: list
        }]
      })

    },
    handleChangeValue(val) {
      this.$emit('input', val);
      this.$emit('change')
    }
  }

}

</script>

<style scoped lang="scss">

</style>