<template>


  <div>
    <div class="groupTitle">{{ element.label }}</div>
    <el-divider></el-divider>
  </div>


</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {}
  },

}

</script>

<style scoped lang="scss">

.groupTitle {

  font-size: 16px;
  font-weight: bold;
  margin-top: 24px;
  color: black;
}

.groupTitle::before {
  content: "#";
  color: #1E90FF; /* 可以根据需要更改颜色 */
  padding-right: 10px;
}


</style>