<template>
  <el-alert :title="element.attrs.title" :type="element.attrs.type" :closable="false" style="margin: 20px 0" >
    <template #default>
      <div  v-html="element.value.replaceAll('\n','<br/>')"></div>
    </template>
  </el-alert>
</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: this.element
    }
  }
}

</script>

<style scoped lang="scss">
:deep(.el-textarea.is-disabled .el-textarea__inner){
  cursor: default;
  background-color: white;
}
</style>