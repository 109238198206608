export const provinces =[
    {
    "id": 1,
    "name": "北京市",
    "sort": 1,
    "remark": "直辖市"
}, {
    "id": 2,
    "name": "天津市",
    "sort": 2,
    "remark": "直辖市"
}, {
    "id": 3,
    "name": "河北省",
    "sort": 5,
    "remark": "省份"
}, {
    "id": 4,
    "name": "山西省",
    "sort": 6,
    "remark": "省份"
}, {
    "id": 5,
    "name": "内蒙古自治区",
    "sort": 32,
    "remark": "自治区"
}, {
    "id": 6,
    "name": "辽宁省",
    "sort": 8,
    "remark": "省份"
}, {
    "id": 7,
    "name": "吉林省",
    "sort": 9,
    "remark": "省份"
}, {
    "id": 8,
    "name": "黑龙江省",
    "sort": 10,
    "remark": "省份"
}, {
    "id": 9,
    "name": "上海市",
    "sort": 3,
    "remark": "直辖市"
}, {
    "id": 10,
    "name": "江苏省",
    "sort": 11,
    "remark": "省份"
}, {
    "id": 11,
    "name": "浙江省",
    "sort": 12,
    "remark": "省份"
}, {
    "id": 12,
    "name": "安徽省",
    "sort": 13,
    "remark": "省份"
}, {
    "id": 13,
    "name": "福建省",
    "sort": 14,
    "remark": "省份"
}, {
    "id": 14,
    "name": "江西省",
    "sort": 15,
    "remark": "省份"
}, {
    "id": 15,
    "name": "山东省",
    "sort": 16,
    "remark": "省份"
}, {
    "id": 16,
    "name": "河南省",
    "sort": 17,
    "remark": "省份"
}, {
    "id": 17,
    "name": "湖北省",
    "sort": 18,
    "remark": "省份"
}, {
    "id": 18,
    "name": "湖南省",
    "sort": 19,
    "remark": "省份"
}, {
    "id": 19,
    "name": "广东省",
    "sort": 20,
    "remark": "省份"
}, {
    "id": 20,
    "name": "海南省",
    "sort": 24,
    "remark": "省份"
}, {
    "id": 21,
    "name": "广西壮族自治区",
    "sort": 28,
    "remark": "自治区"
}, {
    "id": 22,
    "name": "甘肃省",
    "sort": 21,
    "remark": "省份"
}, {
    "id": 23,
    "name": "陕西省",
    "sort": 27,
    "remark": "省份"
}, {
    "id": 24,
    "name": "新疆维吾尔自治区",
    "sort": 31,
    "remark": "自治区"
}, {
    "id": 25,
    "name": "青海省",
    "sort": 26,
    "remark": "省份"
}, {
    "id": 26,
    "name": "宁夏回族自治区",
    "sort": 30,
    "remark": "自治区"
}, {
    "id": 27,
    "name": "重庆市",
    "sort": 4,
    "remark": "直辖市"
}, {
    "id": 28,
    "name": "四川省",
    "sort": 22,
    "remark": "省份"
}, {
    "id": 29,
    "name": "贵州省",
    "sort": 23,
    "remark": "省份"
}, {
    "id": 30,
    "name": "云南省",
    "sort": 25,
    "remark": "省份"
}, {
    "id": 31,
    "name": "西藏自治区",
    "sort": 29,
    "remark": "自治区"
}, {
    "id": 32,
    "name": "台湾省",
    "sort": 7,
    "remark": "省份"
}, {
    "id": 33,
    "name": "澳门特别行政区",
    "sort": 33,
    "remark": "特别行政区"
}, {
    "id": 34,
    "name": "香港特别行政区",
    "sort": 34,
    "remark": "特别行政区"
}]
export const cities = [
    {
        "id": 1,
        "name": "北京市",
        "pid": 1,
        "sort": 1
    }, {
        "id": 2,
        "name": "天津市",
        "pid": 2,
        "sort": 2
    }, {
        "id": 3,
        "name": "上海市",
        "pid": 9,
        "sort": 3
    }, {
        "id": 4,
        "name": "重庆市",
        "pid": 27,
        "sort": 4
    }, {
        "id": 5,
        "name": "邯郸市",
        "pid": 3,
        "sort": 5
    }, {
        "id": 6,
        "name": "石家庄市",
        "pid": 3,
        "sort": 6
    }, {
        "id": 7,
        "name": "保定市",
        "pid": 3,
        "sort": 7
    }, {
        "id": 8,
        "name": "张家口市",
        "pid": 3,
        "sort": 8
    }, {
        "id": 9,
        "name": "承德市",
        "pid": 3,
        "sort": 9
    }, {
        "id": 10,
        "name": "唐山市",
        "pid": 3,
        "sort": 10
    }, {
        "id": 11,
        "name": "廊坊市",
        "pid": 3,
        "sort": 11
    }, {
        "id": 12,
        "name": "沧州市",
        "pid": 3,
        "sort": 12
    }, {
        "id": 13,
        "name": "衡水市",
        "pid": 3,
        "sort": 13
    }, {
        "id": 14,
        "name": "邢台市",
        "pid": 3,
        "sort": 14
    }, {
        "id": 16,
        "name": "朔州市",
        "pid": 4,
        "sort": 16
    }, {
        "id": 17,
        "name": "忻州市",
        "pid": 4,
        "sort": 17
    }, {
        "id": 18,
        "name": "太原市",
        "pid": 4,
        "sort": 18
    }, {
        "id": 19,
        "name": "大同市",
        "pid": 4,
        "sort": 19
    }, {
        "id": 20,
        "name": "阳泉市",
        "pid": 4,
        "sort": 20
    }, {
        "id": 21,
        "name": "晋中市",
        "pid": 4,
        "sort": 21
    }, {
        "id": 22,
        "name": "长治市",
        "pid": 4,
        "sort": 22
    }, {
        "id": 23,
        "name": "晋城市",
        "pid": 4,
        "sort": 23
    }, {
        "id": 24,
        "name": "临汾市",
        "pid": 4,
        "sort": 24
    }, {
        "id": 25,
        "name": "吕梁市",
        "pid": 4,
        "sort": 25
    }, {
        "id": 26,
        "name": "运城市",
        "pid": 4,
        "sort": 26
    }, {
        "id": 27,
        "name": "沈阳市",
        "pid": 6,
        "sort": 27
    }, {
        "id": 28,
        "name": "铁岭市",
        "pid": 6,
        "sort": 28
    }, {
        "id": 29,
        "name": "大连市",
        "pid": 6,
        "sort": 29
    }, {
        "id": 30,
        "name": "鞍山市",
        "pid": 6,
        "sort": 30
    }, {
        "id": 31,
        "name": "抚顺市",
        "pid": 6,
        "sort": 31
    }, {
        "id": 32,
        "name": "本溪市",
        "pid": 6,
        "sort": 32
    }, {
        "id": 33,
        "name": "丹东市",
        "pid": 6,
        "sort": 33
    }, {
        "id": 34,
        "name": "锦州市",
        "pid": 6,
        "sort": 34
    }, {
        "id": 35,
        "name": "营口市",
        "pid": 6,
        "sort": 35
    }, {
        "id": 36,
        "name": "阜新市",
        "pid": 6,
        "sort": 36
    }, {
        "id": 37,
        "name": "辽阳市",
        "pid": 6,
        "sort": 37
    }, {
        "id": 38,
        "name": "朝阳市",
        "pid": 6,
        "sort": 38
    }, {
        "id": 39,
        "name": "盘锦市",
        "pid": 6,
        "sort": 39
    }, {
        "id": 40,
        "name": "葫芦岛市",
        "pid": 6,
        "sort": 40
    }, {
        "id": 41,
        "name": "长春市",
        "pid": 7,
        "sort": 41
    }, {
        "id": 42,
        "name": "吉林市",
        "pid": 7,
        "sort": 42
    }, {
        "id": 43,
        "name": "延边朝鲜族自治州",
        "pid": 7,
        "sort": 43
    }, {
        "id": 44,
        "name": "四平市",
        "pid": 7,
        "sort": 44
    }, {
        "id": 45,
        "name": "通化市",
        "pid": 7,
        "sort": 45
    }, {
        "id": 46,
        "name": "白城市",
        "pid": 7,
        "sort": 46
    }, {
        "id": 47,
        "name": "辽源市",
        "pid": 7,
        "sort": 47
    }, {
        "id": 48,
        "name": "松原市",
        "pid": 7,
        "sort": 48
    }, {
        "id": 49,
        "name": "白山市",
        "pid": 7,
        "sort": 49
    }, {
        "id": 50,
        "name": "哈尔滨市",
        "pid": 8,
        "sort": 50
    }, {
        "id": 51,
        "name": "齐齐哈尔市",
        "pid": 8,
        "sort": 51
    }, {
        "id": 52,
        "name": "鸡西市",
        "pid": 8,
        "sort": 52
    }, {
        "id": 53,
        "name": "牡丹江市",
        "pid": 8,
        "sort": 53
    }, {
        "id": 54,
        "name": "七台河市",
        "pid": 8,
        "sort": 54
    }, {
        "id": 55,
        "name": "佳木斯市",
        "pid": 8,
        "sort": 55
    }, {
        "id": 56,
        "name": "鹤岗市",
        "pid": 8,
        "sort": 56
    }, {
        "id": 57,
        "name": "双鸭山市",
        "pid": 8,
        "sort": 57
    }, {
        "id": 58,
        "name": "绥化市",
        "pid": 8,
        "sort": 58
    }, {
        "id": 59,
        "name": "黑河市",
        "pid": 8,
        "sort": 59
    }, {
        "id": 60,
        "name": "大兴安岭地区",
        "pid": 8,
        "sort": 60
    }, {
        "id": 61,
        "name": "伊春市",
        "pid": 8,
        "sort": 61
    }, {
        "id": 62,
        "name": "大庆市",
        "pid": 8,
        "sort": 62
    }, {
        "id": 63,
        "name": "南京市",
        "pid": 10,
        "sort": 63
    }, {
        "id": 64,
        "name": "无锡市",
        "pid": 10,
        "sort": 64
    }, {
        "id": 65,
        "name": "镇江市",
        "pid": 10,
        "sort": 65
    }, {
        "id": 66,
        "name": "苏州市",
        "pid": 10,
        "sort": 66
    }, {
        "id": 67,
        "name": "南通市",
        "pid": 10,
        "sort": 67
    }, {
        "id": 68,
        "name": "扬州市",
        "pid": 10,
        "sort": 68
    }, {
        "id": 69,
        "name": "盐城市",
        "pid": 10,
        "sort": 69
    }, {
        "id": 70,
        "name": "徐州市",
        "pid": 10,
        "sort": 70
    }, {
        "id": 71,
        "name": "淮安市",
        "pid": 10,
        "sort": 71
    }, {
        "id": 72,
        "name": "连云港市",
        "pid": 10,
        "sort": 72
    }, {
        "id": 73,
        "name": "常州市",
        "pid": 10,
        "sort": 73
    }, {
        "id": 74,
        "name": "泰州市",
        "pid": 10,
        "sort": 74
    }, {
        "id": 75,
        "name": "宿迁市",
        "pid": 10,
        "sort": 75
    }, {
        "id": 76,
        "name": "舟山市",
        "pid": 11,
        "sort": 76
    }, {
        "id": 77,
        "name": "衢州市",
        "pid": 11,
        "sort": 77
    }, {
        "id": 78,
        "name": "杭州市",
        "pid": 11,
        "sort": 78
    }, {
        "id": 79,
        "name": "湖州市",
        "pid": 11,
        "sort": 79
    }, {
        "id": 80,
        "name": "嘉兴市",
        "pid": 11,
        "sort": 80
    }, {
        "id": 81,
        "name": "宁波市",
        "pid": 11,
        "sort": 81
    }, {
        "id": 82,
        "name": "绍兴市",
        "pid": 11,
        "sort": 82
    }, {
        "id": 83,
        "name": "温州市",
        "pid": 11,
        "sort": 83
    }, {
        "id": 84,
        "name": "丽水市",
        "pid": 11,
        "sort": 84
    }, {
        "id": 85,
        "name": "金华市",
        "pid": 11,
        "sort": 85
    }, {
        "id": 86,
        "name": "台州市",
        "pid": 11,
        "sort": 86
    }, {
        "id": 87,
        "name": "合肥市",
        "pid": 12,
        "sort": 87
    }, {
        "id": 88,
        "name": "芜湖市",
        "pid": 12,
        "sort": 88
    }, {
        "id": 89,
        "name": "蚌埠市",
        "pid": 12,
        "sort": 89
    }, {
        "id": 90,
        "name": "淮南市",
        "pid": 12,
        "sort": 90
    }, {
        "id": 91,
        "name": "马鞍山市",
        "pid": 12,
        "sort": 91
    }, {
        "id": 92,
        "name": "淮北市",
        "pid": 12,
        "sort": 92
    }, {
        "id": 93,
        "name": "铜陵市",
        "pid": 12,
        "sort": 93
    }, {
        "id": 94,
        "name": "安庆市",
        "pid": 12,
        "sort": 94
    }, {
        "id": 95,
        "name": "黄山市",
        "pid": 12,
        "sort": 95
    }, {
        "id": 96,
        "name": "滁州市",
        "pid": 12,
        "sort": 96
    }, {
        "id": 97,
        "name": "阜阳市",
        "pid": 12,
        "sort": 97
    }, {
        "id": 98,
        "name": "宿州市",
        "pid": 12,
        "sort": 98
    }, {
        "id": 99,
        "name": "巢湖市",
        "pid": 12,
        "sort": 99
    }, {
        "id": 100,
        "name": "六安市",
        "pid": 12,
        "sort": 100
    }, {
        "id": 101,
        "name": "亳州市",
        "pid": 12,
        "sort": 101
    }, {
        "id": 102,
        "name": "池州市",
        "pid": 12,
        "sort": 102
    }, {
        "id": 103,
        "name": "宣城市",
        "pid": 12,
        "sort": 103
    }, {
        "id": 104,
        "name": "福州市",
        "pid": 13,
        "sort": 104
    }, {
        "id": 105,
        "name": "厦门市",
        "pid": 13,
        "sort": 105
    }, {
        "id": 106,
        "name": "宁德市",
        "pid": 13,
        "sort": 106
    }, {
        "id": 107,
        "name": "莆田市",
        "pid": 13,
        "sort": 107
    }, {
        "id": 108,
        "name": "泉州市",
        "pid": 13,
        "sort": 108
    }, {
        "id": 109,
        "name": "漳州市",
        "pid": 13,
        "sort": 109
    }, {
        "id": 110,
        "name": "龙岩市",
        "pid": 13,
        "sort": 110
    }, {
        "id": 111,
        "name": "三明市",
        "pid": 13,
        "sort": 111
    }, {
        "id": 112,
        "name": "南平市",
        "pid": 13,
        "sort": 112
    }, {
        "id": 113,
        "name": "鹰潭市",
        "pid": 14,
        "sort": 113
    }, {
        "id": 114,
        "name": "新余市",
        "pid": 14,
        "sort": 114
    }, {
        "id": 115,
        "name": "南昌市",
        "pid": 14,
        "sort": 115
    }, {
        "id": 116,
        "name": "九江市",
        "pid": 14,
        "sort": 116
    }, {
        "id": 117,
        "name": "上饶市",
        "pid": 14,
        "sort": 117
    }, {
        "id": 118,
        "name": "抚州市",
        "pid": 14,
        "sort": 118
    }, {
        "id": 119,
        "name": "宜春市",
        "pid": 14,
        "sort": 119
    }, {
        "id": 120,
        "name": "吉安市",
        "pid": 14,
        "sort": 120
    }, {
        "id": 121,
        "name": "赣州市",
        "pid": 14,
        "sort": 121
    }, {
        "id": 122,
        "name": "景德镇市",
        "pid": 14,
        "sort": 122
    }, {
        "id": 123,
        "name": "萍乡市",
        "pid": 14,
        "sort": 123
    }, {
        "id": 124,
        "name": "菏泽市",
        "pid": 15,
        "sort": 124
    }, {
        "id": 125,
        "name": "济南市",
        "pid": 15,
        "sort": 125
    }, {
        "id": 126,
        "name": "青岛市",
        "pid": 15,
        "sort": 126
    }, {
        "id": 127,
        "name": "淄博市",
        "pid": 15,
        "sort": 127
    }, {
        "id": 128,
        "name": "德州市",
        "pid": 15,
        "sort": 128
    }, {
        "id": 129,
        "name": "烟台市",
        "pid": 15,
        "sort": 129
    }, {
        "id": 130,
        "name": "潍坊市",
        "pid": 15,
        "sort": 130
    }, {
        "id": 131,
        "name": "济宁市",
        "pid": 15,
        "sort": 131
    }, {
        "id": 132,
        "name": "泰安市",
        "pid": 15,
        "sort": 132
    }, {
        "id": 133,
        "name": "临沂市",
        "pid": 15,
        "sort": 133
    }, {
        "id": 134,
        "name": "滨州市",
        "pid": 15,
        "sort": 134
    }, {
        "id": 135,
        "name": "东营市",
        "pid": 15,
        "sort": 135
    }, {
        "id": 136,
        "name": "威海市",
        "pid": 15,
        "sort": 136
    }, {
        "id": 137,
        "name": "枣庄市",
        "pid": 15,
        "sort": 137
    }, {
        "id": 138,
        "name": "日照市",
        "pid": 15,
        "sort": 138
    }, {
        "id": 139,
        "name": "莱芜市",
        "pid": 15,
        "sort": 139
    }, {
        "id": 140,
        "name": "聊城市",
        "pid": 15,
        "sort": 140
    }, {
        "id": 141,
        "name": "商丘市",
        "pid": 16,
        "sort": 141
    }, {
        "id": 142,
        "name": "郑州市",
        "pid": 16,
        "sort": 142
    }, {
        "id": 143,
        "name": "安阳市",
        "pid": 16,
        "sort": 143
    }, {
        "id": 144,
        "name": "新乡市",
        "pid": 16,
        "sort": 144
    }, {
        "id": 145,
        "name": "许昌市",
        "pid": 16,
        "sort": 145
    }, {
        "id": 146,
        "name": "平顶山市",
        "pid": 16,
        "sort": 146
    }, {
        "id": 147,
        "name": "信阳市",
        "pid": 16,
        "sort": 147
    }, {
        "id": 148,
        "name": "南阳市",
        "pid": 16,
        "sort": 148
    }, {
        "id": 149,
        "name": "开封市",
        "pid": 16,
        "sort": 149
    }, {
        "id": 150,
        "name": "洛阳市",
        "pid": 16,
        "sort": 150
    }, {
        "id": 151,
        "name": "济源市",
        "pid": 16,
        "sort": 151
    }, {
        "id": 152,
        "name": "焦作市",
        "pid": 16,
        "sort": 152
    }, {
        "id": 153,
        "name": "鹤壁市",
        "pid": 16,
        "sort": 153
    }, {
        "id": 154,
        "name": "濮阳市",
        "pid": 16,
        "sort": 154
    }, {
        "id": 155,
        "name": "周口市",
        "pid": 16,
        "sort": 155
    }, {
        "id": 156,
        "name": "漯河市",
        "pid": 16,
        "sort": 156
    }, {
        "id": 157,
        "name": "驻马店市",
        "pid": 16,
        "sort": 157
    }, {
        "id": 158,
        "name": "三门峡市",
        "pid": 16,
        "sort": 158
    }, {
        "id": 159,
        "name": "武汉市",
        "pid": 17,
        "sort": 159
    }, {
        "id": 160,
        "name": "襄樊市",
        "pid": 17,
        "sort": 160
    }, {
        "id": 161,
        "name": "鄂州市",
        "pid": 17,
        "sort": 161
    }, {
        "id": 162,
        "name": "孝感市",
        "pid": 17,
        "sort": 162
    }, {
        "id": 163,
        "name": "黄冈市",
        "pid": 17,
        "sort": 163
    }, {
        "id": 164,
        "name": "黄石市",
        "pid": 17,
        "sort": 164
    }, {
        "id": 165,
        "name": "咸宁市",
        "pid": 17,
        "sort": 165
    }, {
        "id": 166,
        "name": "荆州市",
        "pid": 17,
        "sort": 166
    }, {
        "id": 167,
        "name": "宜昌市",
        "pid": 17,
        "sort": 167
    }, {
        "id": 168,
        "name": "恩施土家族苗族自治州",
        "pid": 17,
        "sort": 168
    }, {
        "id": 169,
        "name": "神农架林区",
        "pid": 17,
        "sort": 169
    }, {
        "id": 170,
        "name": "十堰市",
        "pid": 17,
        "sort": 170
    }, {
        "id": 171,
        "name": "随州市",
        "pid": 17,
        "sort": 171
    }, {
        "id": 172,
        "name": "荆门市",
        "pid": 17,
        "sort": 172
    }, {
        "id": 173,
        "name": "仙桃市",
        "pid": 17,
        "sort": 173
    }, {
        "id": 174,
        "name": "天门市",
        "pid": 17,
        "sort": 174
    }, {
        "id": 175,
        "name": "潜江市",
        "pid": 17,
        "sort": 175
    }, {
        "id": 176,
        "name": "岳阳市",
        "pid": 18,
        "sort": 176
    }, {
        "id": 177,
        "name": "长沙市",
        "pid": 18,
        "sort": 177
    }, {
        "id": 178,
        "name": "湘潭市",
        "pid": 18,
        "sort": 178
    }, {
        "id": 179,
        "name": "株洲市",
        "pid": 18,
        "sort": 179
    }, {
        "id": 180,
        "name": "衡阳市",
        "pid": 18,
        "sort": 180
    }, {
        "id": 181,
        "name": "郴州市",
        "pid": 18,
        "sort": 181
    }, {
        "id": 182,
        "name": "常德市",
        "pid": 18,
        "sort": 182
    }, {
        "id": 183,
        "name": "益阳市",
        "pid": 18,
        "sort": 183
    }, {
        "id": 184,
        "name": "娄底市",
        "pid": 18,
        "sort": 184
    }, {
        "id": 185,
        "name": "邵阳市",
        "pid": 18,
        "sort": 185
    }, {
        "id": 186,
        "name": "湘西土家族苗族自治州",
        "pid": 18,
        "sort": 186
    }, {
        "id": 187,
        "name": "张家界市",
        "pid": 18,
        "sort": 187
    }, {
        "id": 188,
        "name": "怀化市",
        "pid": 18,
        "sort": 188
    }, {
        "id": 189,
        "name": "永州市",
        "pid": 18,
        "sort": 189
    }, {
        "id": 190,
        "name": "广州市",
        "pid": 19,
        "sort": 190
    }, {
        "id": 191,
        "name": "汕尾市",
        "pid": 19,
        "sort": 191
    }, {
        "id": 192,
        "name": "阳江市",
        "pid": 19,
        "sort": 192
    }, {
        "id": 193,
        "name": "揭阳市",
        "pid": 19,
        "sort": 193
    }, {
        "id": 194,
        "name": "茂名市",
        "pid": 19,
        "sort": 194
    }, {
        "id": 195,
        "name": "惠州市",
        "pid": 19,
        "sort": 195
    }, {
        "id": 196,
        "name": "江门市",
        "pid": 19,
        "sort": 196
    }, {
        "id": 197,
        "name": "韶关市",
        "pid": 19,
        "sort": 197
    }, {
        "id": 198,
        "name": "梅州市",
        "pid": 19,
        "sort": 198
    }, {
        "id": 199,
        "name": "汕头市",
        "pid": 19,
        "sort": 199
    }, {
        "id": 200,
        "name": "深圳市",
        "pid": 19,
        "sort": 200
    }, {
        "id": 201,
        "name": "珠海市",
        "pid": 19,
        "sort": 201
    }, {
        "id": 202,
        "name": "佛山市",
        "pid": 19,
        "sort": 202
    }, {
        "id": 203,
        "name": "肇庆市",
        "pid": 19,
        "sort": 203
    }, {
        "id": 204,
        "name": "湛江市",
        "pid": 19,
        "sort": 204
    }, {
        "id": 205,
        "name": "中山市",
        "pid": 19,
        "sort": 205
    }, {
        "id": 206,
        "name": "河源市",
        "pid": 19,
        "sort": 206
    }, {
        "id": 207,
        "name": "清远市",
        "pid": 19,
        "sort": 207
    }, {
        "id": 208,
        "name": "云浮市",
        "pid": 19,
        "sort": 208
    }, {
        "id": 209,
        "name": "潮州市",
        "pid": 19,
        "sort": 209
    }, {
        "id": 210,
        "name": "东莞市",
        "pid": 19,
        "sort": 210
    }, {
        "id": 211,
        "name": "兰州市",
        "pid": 22,
        "sort": 211
    }, {
        "id": 212,
        "name": "金昌市",
        "pid": 22,
        "sort": 212
    }, {
        "id": 213,
        "name": "白银市",
        "pid": 22,
        "sort": 213
    }, {
        "id": 214,
        "name": "天水市",
        "pid": 22,
        "sort": 214
    }, {
        "id": 215,
        "name": "嘉峪关市",
        "pid": 22,
        "sort": 215
    }, {
        "id": 216,
        "name": "武威市",
        "pid": 22,
        "sort": 216
    }, {
        "id": 217,
        "name": "张掖市",
        "pid": 22,
        "sort": 217
    }, {
        "id": 218,
        "name": "平凉市",
        "pid": 22,
        "sort": 218
    }, {
        "id": 219,
        "name": "酒泉市",
        "pid": 22,
        "sort": 219
    }, {
        "id": 220,
        "name": "庆阳市",
        "pid": 22,
        "sort": 220
    }, {
        "id": 221,
        "name": "定西市",
        "pid": 22,
        "sort": 221
    }, {
        "id": 222,
        "name": "陇南市",
        "pid": 22,
        "sort": 222
    }, {
        "id": 223,
        "name": "临夏回族自治州",
        "pid": 22,
        "sort": 223
    }, {
        "id": 224,
        "name": "甘南藏族自治州",
        "pid": 22,
        "sort": 224
    }, {
        "id": 225,
        "name": "成都市",
        "pid": 28,
        "sort": 225
    }, {
        "id": 226,
        "name": "攀枝花市",
        "pid": 28,
        "sort": 226
    }, {
        "id": 227,
        "name": "自贡市",
        "pid": 28,
        "sort": 227
    }, {
        "id": 228,
        "name": "绵阳市",
        "pid": 28,
        "sort": 228
    }, {
        "id": 229,
        "name": "南充市",
        "pid": 28,
        "sort": 229
    }, {
        "id": 230,
        "name": "达州市",
        "pid": 28,
        "sort": 230
    }, {
        "id": 231,
        "name": "遂宁市",
        "pid": 28,
        "sort": 231
    }, {
        "id": 232,
        "name": "广安市",
        "pid": 28,
        "sort": 232
    }, {
        "id": 233,
        "name": "巴中市",
        "pid": 28,
        "sort": 233
    }, {
        "id": 234,
        "name": "泸州市",
        "pid": 28,
        "sort": 234
    }, {
        "id": 235,
        "name": "宜宾市",
        "pid": 28,
        "sort": 235
    }, {
        "id": 236,
        "name": "资阳市",
        "pid": 28,
        "sort": 236
    }, {
        "id": 237,
        "name": "内江市",
        "pid": 28,
        "sort": 237
    }, {
        "id": 238,
        "name": "乐山市",
        "pid": 28,
        "sort": 238
    }, {
        "id": 239,
        "name": "眉山市",
        "pid": 28,
        "sort": 239
    }, {
        "id": 240,
        "name": "凉山彝族自治州",
        "pid": 28,
        "sort": 240
    }, {
        "id": 241,
        "name": "雅安市",
        "pid": 28,
        "sort": 241
    }, {
        "id": 242,
        "name": "甘孜藏族自治州",
        "pid": 28,
        "sort": 242
    }, {
        "id": 243,
        "name": "阿坝藏族羌族自治州",
        "pid": 28,
        "sort": 243
    }, {
        "id": 244,
        "name": "德阳市",
        "pid": 28,
        "sort": 244
    }, {
        "id": 245,
        "name": "广元市",
        "pid": 28,
        "sort": 245
    }, {
        "id": 246,
        "name": "贵阳市",
        "pid": 29,
        "sort": 246
    }, {
        "id": 247,
        "name": "遵义市",
        "pid": 29,
        "sort": 247
    }, {
        "id": 248,
        "name": "安顺市",
        "pid": 29,
        "sort": 248
    }, {
        "id": 249,
        "name": "黔南布依族苗族自治州",
        "pid": 29,
        "sort": 249
    }, {
        "id": 250,
        "name": "黔东南苗族侗族自治州",
        "pid": 29,
        "sort": 250
    }, {
        "id": 251,
        "name": "铜仁地区",
        "pid": 29,
        "sort": 251
    }, {
        "id": 252,
        "name": "毕节地区",
        "pid": 29,
        "sort": 252
    }, {
        "id": 253,
        "name": "六盘水市",
        "pid": 29,
        "sort": 253
    }, {
        "id": 254,
        "name": "黔西南布依族苗族自治州",
        "pid": 29,
        "sort": 254
    }, {
        "id": 255,
        "name": "海口市",
        "pid": 20,
        "sort": 255
    }, {
        "id": 256,
        "name": "三亚市",
        "pid": 20,
        "sort": 256
    }, {
        "id": 257,
        "name": "五指山市",
        "pid": 20,
        "sort": 257
    }, {
        "id": 258,
        "name": "琼海市",
        "pid": 20,
        "sort": 258
    }, {
        "id": 259,
        "name": "儋州市",
        "pid": 20,
        "sort": 259
    }, {
        "id": 260,
        "name": "文昌市",
        "pid": 20,
        "sort": 260
    }, {
        "id": 261,
        "name": "万宁市",
        "pid": 20,
        "sort": 261
    }, {
        "id": 262,
        "name": "东方市",
        "pid": 20,
        "sort": 262
    }, {
        "id": 263,
        "name": "澄迈县",
        "pid": 20,
        "sort": 263
    }, {
        "id": 264,
        "name": "定安县",
        "pid": 20,
        "sort": 264
    }, {
        "id": 265,
        "name": "屯昌县",
        "pid": 20,
        "sort": 265
    }, {
        "id": 266,
        "name": "临高县",
        "pid": 20,
        "sort": 266
    }, {
        "id": 267,
        "name": "白沙黎族自治县",
        "pid": 20,
        "sort": 267
    }, {
        "id": 268,
        "name": "昌江黎族自治县",
        "pid": 20,
        "sort": 268
    }, {
        "id": 269,
        "name": "乐东黎族自治县",
        "pid": 20,
        "sort": 269
    }, {
        "id": 270,
        "name": "陵水黎族自治县",
        "pid": 20,
        "sort": 270
    }, {
        "id": 271,
        "name": "保亭黎族苗族自治县",
        "pid": 20,
        "sort": 271
    }, {
        "id": 272,
        "name": "琼中黎族苗族自治县",
        "pid": 20,
        "sort": 272
    }, {
        "id": 273,
        "name": "西双版纳傣族自治州",
        "pid": 30,
        "sort": 273
    }, {
        "id": 274,
        "name": "德宏傣族景颇族自治州",
        "pid": 30,
        "sort": 274
    }, {
        "id": 275,
        "name": "昭通市",
        "pid": 30,
        "sort": 275
    }, {
        "id": 276,
        "name": "昆明市",
        "pid": 30,
        "sort": 276
    }, {
        "id": 277,
        "name": "大理白族自治州",
        "pid": 30,
        "sort": 277
    }, {
        "id": 278,
        "name": "红河哈尼族彝族自治州",
        "pid": 30,
        "sort": 278
    }, {
        "id": 279,
        "name": "曲靖市",
        "pid": 30,
        "sort": 279
    }, {
        "id": 280,
        "name": "保山市",
        "pid": 30,
        "sort": 280
    }, {
        "id": 281,
        "name": "文山壮族苗族自治州",
        "pid": 30,
        "sort": 281
    }, {
        "id": 282,
        "name": "玉溪市",
        "pid": 30,
        "sort": 282
    }, {
        "id": 283,
        "name": "楚雄彝族自治州",
        "pid": 30,
        "sort": 283
    }, {
        "id": 284,
        "name": "普洱市",
        "pid": 30,
        "sort": 284
    }, {
        "id": 285,
        "name": "临沧市",
        "pid": 30,
        "sort": 285
    }, {
        "id": 286,
        "name": "怒江傈傈族自治州",
        "pid": 30,
        "sort": 286
    }, {
        "id": 287,
        "name": "迪庆藏族自治州",
        "pid": 30,
        "sort": 287
    }, {
        "id": 288,
        "name": "丽江市",
        "pid": 30,
        "sort": 288
    }, {
        "id": 289,
        "name": "海北藏族自治州",
        "pid": 25,
        "sort": 289
    }, {
        "id": 290,
        "name": "西宁市",
        "pid": 25,
        "sort": 290
    }, {
        "id": 291,
        "name": "海东地区",
        "pid": 25,
        "sort": 291
    }, {
        "id": 292,
        "name": "黄南藏族自治州",
        "pid": 25,
        "sort": 292
    }, {
        "id": 293,
        "name": "海南藏族自治州",
        "pid": 25,
        "sort": 293
    }, {
        "id": 294,
        "name": "果洛藏族自治州",
        "pid": 25,
        "sort": 294
    }, {
        "id": 295,
        "name": "玉树藏族自治州",
        "pid": 25,
        "sort": 295
    }, {
        "id": 296,
        "name": "海西蒙古族藏族自治州",
        "pid": 25,
        "sort": 296
    }, {
        "id": 297,
        "name": "西安市",
        "pid": 23,
        "sort": 297
    }, {
        "id": 298,
        "name": "咸阳市",
        "pid": 23,
        "sort": 298
    }, {
        "id": 299,
        "name": "延安市",
        "pid": 23,
        "sort": 299
    }, {
        "id": 300,
        "name": "榆林市",
        "pid": 23,
        "sort": 300
    }, {
        "id": 301,
        "name": "渭南市",
        "pid": 23,
        "sort": 301
    }, {
        "id": 302,
        "name": "商洛市",
        "pid": 23,
        "sort": 302
    }, {
        "id": 303,
        "name": "安康市",
        "pid": 23,
        "sort": 303
    }, {
        "id": 304,
        "name": "汉中市",
        "pid": 23,
        "sort": 304
    }, {
        "id": 305,
        "name": "宝鸡市",
        "pid": 23,
        "sort": 305
    }, {
        "id": 306,
        "name": "铜川市",
        "pid": 23,
        "sort": 306
    }, {
        "id": 307,
        "name": "防城港市",
        "pid": 21,
        "sort": 307
    }, {
        "id": 308,
        "name": "南宁市",
        "pid": 21,
        "sort": 308
    }, {
        "id": 309,
        "name": "崇左市",
        "pid": 21,
        "sort": 309
    }, {
        "id": 310,
        "name": "来宾市",
        "pid": 21,
        "sort": 310
    }, {
        "id": 311,
        "name": "柳州市",
        "pid": 21,
        "sort": 311
    }, {
        "id": 312,
        "name": "桂林市",
        "pid": 21,
        "sort": 312
    }, {
        "id": 313,
        "name": "梧州市",
        "pid": 21,
        "sort": 313
    }, {
        "id": 314,
        "name": "贺州市",
        "pid": 21,
        "sort": 314
    }, {
        "id": 315,
        "name": "贵港市",
        "pid": 21,
        "sort": 315
    }, {
        "id": 316,
        "name": "玉林市",
        "pid": 21,
        "sort": 316
    }, {
        "id": 317,
        "name": "百色市",
        "pid": 21,
        "sort": 317
    }, {
        "id": 318,
        "name": "钦州市",
        "pid": 21,
        "sort": 318
    }, {
        "id": 319,
        "name": "河池市",
        "pid": 21,
        "sort": 319
    }, {
        "id": 320,
        "name": "北海市",
        "pid": 21,
        "sort": 320
    }, {
        "id": 321,
        "name": "拉萨市",
        "pid": 31,
        "sort": 321
    }, {
        "id": 322,
        "name": "日喀则地区",
        "pid": 31,
        "sort": 322
    }, {
        "id": 323,
        "name": "山南地区",
        "pid": 31,
        "sort": 323
    }, {
        "id": 324,
        "name": "林芝地区",
        "pid": 31,
        "sort": 324
    }, {
        "id": 325,
        "name": "昌都地区",
        "pid": 31,
        "sort": 325
    }, {
        "id": 326,
        "name": "那曲地区",
        "pid": 31,
        "sort": 326
    }, {
        "id": 327,
        "name": "阿里地区",
        "pid": 31,
        "sort": 327
    }, {
        "id": 328,
        "name": "银川市",
        "pid": 26,
        "sort": 328
    }, {
        "id": 329,
        "name": "石嘴山市",
        "pid": 26,
        "sort": 329
    }, {
        "id": 330,
        "name": "吴忠市",
        "pid": 26,
        "sort": 330
    }, {
        "id": 331,
        "name": "固原市",
        "pid": 26,
        "sort": 331
    }, {
        "id": 332,
        "name": "中卫市",
        "pid": 26,
        "sort": 332
    }, {
        "id": 333,
        "name": "塔城地区",
        "pid": 24,
        "sort": 333
    }, {
        "id": 334,
        "name": "哈密地区",
        "pid": 24,
        "sort": 334
    }, {
        "id": 335,
        "name": "和田地区",
        "pid": 24,
        "sort": 335
    }, {
        "id": 336,
        "name": "阿勒泰地区",
        "pid": 24,
        "sort": 336
    }, {
        "id": 337,
        "name": "克孜勒苏柯尔克孜自治州",
        "pid": 24,
        "sort": 337
    }, {
        "id": 338,
        "name": "博尔塔拉蒙古自治州",
        "pid": 24,
        "sort": 338
    }, {
        "id": 339,
        "name": "克拉玛依市",
        "pid": 24,
        "sort": 339
    }, {
        "id": 340,
        "name": "乌鲁木齐市",
        "pid": 24,
        "sort": 340
    }, {
        "id": 341,
        "name": "石河子市",
        "pid": 24,
        "sort": 341
    }, {
        "id": 342,
        "name": "昌吉回族自治州",
        "pid": 24,
        "sort": 342
    }, {
        "id": 343,
        "name": "五家渠市",
        "pid": 24,
        "sort": 343
    }, {
        "id": 344,
        "name": "吐鲁番地区",
        "pid": 24,
        "sort": 344
    }, {
        "id": 345,
        "name": "巴音郭楞蒙古自治州",
        "pid": 24,
        "sort": 345
    }, {
        "id": 346,
        "name": "阿克苏地区",
        "pid": 24,
        "sort": 346
    }, {
        "id": 347,
        "name": "阿拉尔市",
        "pid": 24,
        "sort": 347
    }, {
        "id": 348,
        "name": "喀什地区",
        "pid": 24,
        "sort": 348
    }, {
        "id": 349,
        "name": "图木舒克市",
        "pid": 24,
        "sort": 349
    }, {
        "id": 350,
        "name": "伊犁哈萨克自治州",
        "pid": 24,
        "sort": 350
    }, {
        "id": 351,
        "name": "呼伦贝尔市",
        "pid": 5,
        "sort": 351
    }, {
        "id": 352,
        "name": "呼和浩特市",
        "pid": 5,
        "sort": 352
    }, {
        "id": 353,
        "name": "包头市",
        "pid": 5,
        "sort": 353
    }, {
        "id": 354,
        "name": "乌海市",
        "pid": 5,
        "sort": 354
    }, {
        "id": 355,
        "name": "乌兰察布市",
        "pid": 5,
        "sort": 355
    }, {
        "id": 356,
        "name": "通辽市",
        "pid": 5,
        "sort": 356
    }, {
        "id": 357,
        "name": "赤峰市",
        "pid": 5,
        "sort": 357
    }, {
        "id": 358,
        "name": "鄂尔多斯市",
        "pid": 5,
        "sort": 358
    }, {
        "id": 359,
        "name": "巴彦淖尔市",
        "pid": 5,
        "sort": 359
    }, {
        "id": 360,
        "name": "锡林郭勒盟",
        "pid": 5,
        "sort": 360
    }, {
        "id": 361,
        "name": "兴安盟",
        "pid": 5,
        "sort": 361
    }, {
        "id": 362,
        "name": "阿拉善盟",
        "pid": 5,
        "sort": 362
    }, {
        "id": 363,
        "name": "台北市",
        "pid": 32,
        "sort": 363
    }, {
        "id": 364,
        "name": "高雄市",
        "pid": 32,
        "sort": 364
    }, {
        "id": 365,
        "name": "基隆市",
        "pid": 32,
        "sort": 365
    }, {
        "id": 366,
        "name": "台中市",
        "pid": 32,
        "sort": 366
    }, {
        "id": 367,
        "name": "台南市",
        "pid": 32,
        "sort": 367
    }, {
        "id": 368,
        "name": "新竹市",
        "pid": 32,
        "sort": 368
    }, {
        "id": 369,
        "name": "嘉义市",
        "pid": 32,
        "sort": 369
    }, {
        "id": 370,
        "name": "澳门特别行政区",
        "pid": 33,
        "sort": 370
    }, {
        "id": 371,
        "name": "香港特别行政区",
        "pid": 34,
        "sort": 371
    }]
export const counties = [
    {
        "id": 1,
        "name": "东城区",
        "pid": 1,
        "sort": null
    }, {
        "id": 2,
        "name": "西城区",
        "pid": 1,
        "sort": null
    }, {
        "id": 3,
        "name": "崇文区",
        "pid": 1,
        "sort": null
    }, {
        "id": 4,
        "name": "宣武区",
        "pid": 1,
        "sort": null
    }, {
        "id": 5,
        "name": "朝阳区",
        "pid": 1,
        "sort": null
    }, {
        "id": 6,
        "name": "丰台区",
        "pid": 1,
        "sort": null
    }, {
        "id": 7,
        "name": "石景山区",
        "pid": 1,
        "sort": null
    }, {
        "id": 8,
        "name": "海淀区",
        "pid": 1,
        "sort": null
    }, {
        "id": 9,
        "name": "门头沟区",
        "pid": 1,
        "sort": null
    }, {
        "id": 10,
        "name": "房山区",
        "pid": 1,
        "sort": null
    }, {
        "id": 11,
        "name": "通州区",
        "pid": 1,
        "sort": null
    }, {
        "id": 12,
        "name": "顺义区",
        "pid": 1,
        "sort": null
    }, {
        "id": 13,
        "name": "昌平区",
        "pid": 1,
        "sort": null
    }, {
        "id": 14,
        "name": "大兴区",
        "pid": 1,
        "sort": null
    }, {
        "id": 15,
        "name": "怀柔区",
        "pid": 1,
        "sort": null
    }, {
        "id": 16,
        "name": "平谷区",
        "pid": 1,
        "sort": null
    }, {
        "id": 17,
        "name": "密云县",
        "pid": 1,
        "sort": null
    }, {
        "id": 18,
        "name": "延庆县",
        "pid": 1,
        "sort": null
    }, {
        "id": 19,
        "name": "和平区",
        "pid": 2,
        "sort": null
    }, {
        "id": 20,
        "name": "河东区",
        "pid": 2,
        "sort": null
    }, {
        "id": 21,
        "name": "河西区",
        "pid": 2,
        "sort": null
    }, {
        "id": 22,
        "name": "南开区",
        "pid": 2,
        "sort": null
    }, {
        "id": 23,
        "name": "河北区",
        "pid": 2,
        "sort": null
    }, {
        "id": 24,
        "name": "红桥区",
        "pid": 2,
        "sort": null
    }, {
        "id": 25,
        "name": "塘沽区",
        "pid": 2,
        "sort": null
    }, {
        "id": 26,
        "name": "汉沽区",
        "pid": 2,
        "sort": null
    }, {
        "id": 27,
        "name": "大港区",
        "pid": 2,
        "sort": null
    }, {
        "id": 28,
        "name": "东丽区",
        "pid": 2,
        "sort": null
    }, {
        "id": 29,
        "name": "西青区",
        "pid": 2,
        "sort": null
    }, {
        "id": 30,
        "name": "津南区",
        "pid": 2,
        "sort": null
    }, {
        "id": 31,
        "name": "北辰区",
        "pid": 2,
        "sort": null
    }, {
        "id": 32,
        "name": "武清区",
        "pid": 2,
        "sort": null
    }, {
        "id": 33,
        "name": "宝坻区",
        "pid": 2,
        "sort": null
    }, {
        "id": 34,
        "name": "宁河县",
        "pid": 2,
        "sort": null
    }, {
        "id": 35,
        "name": "静海县",
        "pid": 2,
        "sort": null
    }, {
        "id": 36,
        "name": "蓟县",
        "pid": 2,
        "sort": null
    }, {
        "id": 37,
        "name": "黄浦区",
        "pid": 3,
        "sort": null
    }, {
        "id": 38,
        "name": "卢湾区",
        "pid": 3,
        "sort": null
    }, {
        "id": 39,
        "name": "徐汇区",
        "pid": 3,
        "sort": null
    }, {
        "id": 40,
        "name": "长宁区",
        "pid": 3,
        "sort": null
    }, {
        "id": 41,
        "name": "静安区",
        "pid": 3,
        "sort": null
    }, {
        "id": 42,
        "name": "普陀区",
        "pid": 3,
        "sort": null
    }, {
        "id": 43,
        "name": "闸北区",
        "pid": 3,
        "sort": null
    }, {
        "id": 44,
        "name": "虹口区",
        "pid": 3,
        "sort": null
    }, {
        "id": 45,
        "name": "杨浦区",
        "pid": 3,
        "sort": null
    }, {
        "id": 46,
        "name": "闵行区",
        "pid": 3,
        "sort": null
    }, {
        "id": 47,
        "name": "宝山区",
        "pid": 3,
        "sort": null
    }, {
        "id": 48,
        "name": "嘉定区",
        "pid": 3,
        "sort": null
    }, {
        "id": 49,
        "name": "浦东新区",
        "pid": 3,
        "sort": null
    }, {
        "id": 50,
        "name": "金山区",
        "pid": 3,
        "sort": null
    }, {
        "id": 51,
        "name": "松江区",
        "pid": 3,
        "sort": null
    }, {
        "id": 52,
        "name": "青浦区",
        "pid": 3,
        "sort": null
    }, {
        "id": 53,
        "name": "南汇区",
        "pid": 3,
        "sort": null
    }, {
        "id": 54,
        "name": "奉贤区",
        "pid": 3,
        "sort": null
    }, {
        "id": 55,
        "name": "崇明县",
        "pid": 3,
        "sort": null
    }, {
        "id": 56,
        "name": "万州区",
        "pid": 4,
        "sort": null
    }, {
        "id": 57,
        "name": "涪陵区",
        "pid": 4,
        "sort": null
    }, {
        "id": 58,
        "name": "渝中区",
        "pid": 4,
        "sort": null
    }, {
        "id": 59,
        "name": "大渡口区",
        "pid": 4,
        "sort": null
    }, {
        "id": 60,
        "name": "江北区",
        "pid": 4,
        "sort": null
    }, {
        "id": 61,
        "name": "沙坪坝区",
        "pid": 4,
        "sort": null
    }, {
        "id": 62,
        "name": "九龙坡区",
        "pid": 4,
        "sort": null
    }, {
        "id": 63,
        "name": "南岸区",
        "pid": 4,
        "sort": null
    }, {
        "id": 64,
        "name": "北碚区",
        "pid": 4,
        "sort": null
    }, {
        "id": 65,
        "name": "万盛区",
        "pid": 4,
        "sort": null
    }, {
        "id": 66,
        "name": "双桥区",
        "pid": 4,
        "sort": null
    }, {
        "id": 67,
        "name": "渝北区",
        "pid": 4,
        "sort": null
    }, {
        "id": 68,
        "name": "巴南区",
        "pid": 4,
        "sort": null
    }, {
        "id": 69,
        "name": "黔江区",
        "pid": 4,
        "sort": null
    }, {
        "id": 70,
        "name": "长寿区",
        "pid": 4,
        "sort": null
    }, {
        "id": 71,
        "name": "江津区",
        "pid": 4,
        "sort": null
    }, {
        "id": 72,
        "name": "合川区",
        "pid": 4,
        "sort": null
    }, {
        "id": 73,
        "name": "永川区",
        "pid": 4,
        "sort": null
    }, {
        "id": 74,
        "name": "南川区",
        "pid": 4,
        "sort": null
    }, {
        "id": 75,
        "name": "綦江县",
        "pid": 4,
        "sort": null
    }, {
        "id": 76,
        "name": "潼南县",
        "pid": 4,
        "sort": null
    }, {
        "id": 77,
        "name": "铜梁县",
        "pid": 4,
        "sort": null
    }, {
        "id": 78,
        "name": "大足县",
        "pid": 4,
        "sort": null
    }, {
        "id": 79,
        "name": "荣昌县",
        "pid": 4,
        "sort": null
    }, {
        "id": 80,
        "name": "璧山县",
        "pid": 4,
        "sort": null
    }, {
        "id": 81,
        "name": "梁平县",
        "pid": 4,
        "sort": null
    }, {
        "id": 82,
        "name": "城口县",
        "pid": 4,
        "sort": null
    }, {
        "id": 83,
        "name": "丰都县",
        "pid": 4,
        "sort": null
    }, {
        "id": 84,
        "name": "垫江县",
        "pid": 4,
        "sort": null
    }, {
        "id": 85,
        "name": "武隆县",
        "pid": 4,
        "sort": null
    }, {
        "id": 86,
        "name": "忠县",
        "pid": 4,
        "sort": null
    }, {
        "id": 87,
        "name": "开县",
        "pid": 4,
        "sort": null
    }, {
        "id": 88,
        "name": "云阳县",
        "pid": 4,
        "sort": null
    }, {
        "id": 89,
        "name": "奉节县",
        "pid": 4,
        "sort": null
    }, {
        "id": 90,
        "name": "巫山县",
        "pid": 4,
        "sort": null
    }, {
        "id": 91,
        "name": "巫溪县",
        "pid": 4,
        "sort": null
    }, {
        "id": 92,
        "name": "石柱土家族自治县",
        "pid": 4,
        "sort": null
    }, {
        "id": 93,
        "name": "秀山土家族苗族自治县",
        "pid": 4,
        "sort": null
    }, {
        "id": 94,
        "name": "酉阳土家族苗族自治县",
        "pid": 4,
        "sort": null
    }, {
        "id": 95,
        "name": "彭水苗族土家族自治县",
        "pid": 4,
        "sort": null
    }, {
        "id": 96,
        "name": "邯山区",
        "pid": 5,
        "sort": null
    }, {
        "id": 97,
        "name": "丛台区",
        "pid": 5,
        "sort": null
    }, {
        "id": 98,
        "name": "复兴区",
        "pid": 5,
        "sort": null
    }, {
        "id": 99,
        "name": "峰峰矿区",
        "pid": 5,
        "sort": null
    }, {
        "id": 100,
        "name": "邯郸县",
        "pid": 5,
        "sort": null
    }, {
        "id": 101,
        "name": "临漳县",
        "pid": 5,
        "sort": null
    }, {
        "id": 102,
        "name": "成安县",
        "pid": 5,
        "sort": null
    }, {
        "id": 103,
        "name": "大名县",
        "pid": 5,
        "sort": null
    }, {
        "id": 104,
        "name": "涉县",
        "pid": 5,
        "sort": null
    }, {
        "id": 105,
        "name": "磁县",
        "pid": 5,
        "sort": null
    }, {
        "id": 106,
        "name": "肥乡县",
        "pid": 5,
        "sort": null
    }, {
        "id": 107,
        "name": "永年县",
        "pid": 5,
        "sort": null
    }, {
        "id": 108,
        "name": "邱县",
        "pid": 5,
        "sort": null
    }, {
        "id": 109,
        "name": "鸡泽县",
        "pid": 5,
        "sort": null
    }, {
        "id": 110,
        "name": "广平县",
        "pid": 5,
        "sort": null
    }, {
        "id": 111,
        "name": "馆陶县",
        "pid": 5,
        "sort": null
    }, {
        "id": 112,
        "name": "魏县",
        "pid": 5,
        "sort": null
    }, {
        "id": 113,
        "name": "曲周县",
        "pid": 5,
        "sort": null
    }, {
        "id": 114,
        "name": "武安市",
        "pid": 5,
        "sort": null
    }, {
        "id": 115,
        "name": "长安区",
        "pid": 6,
        "sort": null
    }, {
        "id": 116,
        "name": "桥东区",
        "pid": 6,
        "sort": null
    }, {
        "id": 117,
        "name": "桥西区",
        "pid": 6,
        "sort": null
    }, {
        "id": 118,
        "name": "新华区",
        "pid": 6,
        "sort": null
    }, {
        "id": 119,
        "name": "井陉矿区",
        "pid": 6,
        "sort": null
    }, {
        "id": 120,
        "name": "裕华区",
        "pid": 6,
        "sort": null
    }, {
        "id": 121,
        "name": "井陉县",
        "pid": 6,
        "sort": null
    }, {
        "id": 122,
        "name": "正定县",
        "pid": 6,
        "sort": null
    }, {
        "id": 123,
        "name": "栾城县",
        "pid": 6,
        "sort": null
    }, {
        "id": 124,
        "name": "行唐县",
        "pid": 6,
        "sort": null
    }, {
        "id": 125,
        "name": "灵寿县",
        "pid": 6,
        "sort": null
    }, {
        "id": 126,
        "name": "高邑县",
        "pid": 6,
        "sort": null
    }, {
        "id": 127,
        "name": "深泽县",
        "pid": 6,
        "sort": null
    }, {
        "id": 128,
        "name": "赞皇县",
        "pid": 6,
        "sort": null
    }, {
        "id": 129,
        "name": "无极县",
        "pid": 6,
        "sort": null
    }, {
        "id": 130,
        "name": "平山县",
        "pid": 6,
        "sort": null
    }, {
        "id": 131,
        "name": "元氏县",
        "pid": 6,
        "sort": null
    }, {
        "id": 132,
        "name": "赵县",
        "pid": 6,
        "sort": null
    }, {
        "id": 133,
        "name": "辛集市",
        "pid": 6,
        "sort": null
    }, {
        "id": 134,
        "name": "藁城市",
        "pid": 6,
        "sort": null
    }, {
        "id": 135,
        "name": "晋州市",
        "pid": 6,
        "sort": null
    }, {
        "id": 136,
        "name": "新乐市",
        "pid": 6,
        "sort": null
    }, {
        "id": 137,
        "name": "鹿泉市",
        "pid": 6,
        "sort": null
    }, {
        "id": 138,
        "name": "新市区",
        "pid": 7,
        "sort": null
    }, {
        "id": 139,
        "name": "北市区",
        "pid": 7,
        "sort": null
    }, {
        "id": 140,
        "name": "南市区",
        "pid": 7,
        "sort": null
    }, {
        "id": 141,
        "name": "满城县",
        "pid": 7,
        "sort": null
    }, {
        "id": 142,
        "name": "清苑县",
        "pid": 7,
        "sort": null
    }, {
        "id": 143,
        "name": "涞水县",
        "pid": 7,
        "sort": null
    }, {
        "id": 144,
        "name": "阜平县",
        "pid": 7,
        "sort": null
    }, {
        "id": 145,
        "name": "徐水县",
        "pid": 7,
        "sort": null
    }, {
        "id": 146,
        "name": "定兴县",
        "pid": 7,
        "sort": null
    }, {
        "id": 147,
        "name": "唐县",
        "pid": 7,
        "sort": null
    }, {
        "id": 148,
        "name": "高阳县",
        "pid": 7,
        "sort": null
    }, {
        "id": 149,
        "name": "容城县",
        "pid": 7,
        "sort": null
    }, {
        "id": 150,
        "name": "涞源县",
        "pid": 7,
        "sort": null
    }, {
        "id": 151,
        "name": "望都县",
        "pid": 7,
        "sort": null
    }, {
        "id": 152,
        "name": "安新县",
        "pid": 7,
        "sort": null
    }, {
        "id": 153,
        "name": "易县",
        "pid": 7,
        "sort": null
    }, {
        "id": 154,
        "name": "曲阳县",
        "pid": 7,
        "sort": null
    }, {
        "id": 155,
        "name": "蠡县",
        "pid": 7,
        "sort": null
    }, {
        "id": 156,
        "name": "顺平县",
        "pid": 7,
        "sort": null
    }, {
        "id": 157,
        "name": "博野县",
        "pid": 7,
        "sort": null
    }, {
        "id": 158,
        "name": "雄县",
        "pid": 7,
        "sort": null
    }, {
        "id": 159,
        "name": "涿州市",
        "pid": 7,
        "sort": null
    }, {
        "id": 160,
        "name": "定州市",
        "pid": 7,
        "sort": null
    }, {
        "id": 161,
        "name": "安国市",
        "pid": 7,
        "sort": null
    }, {
        "id": 162,
        "name": "高碑店市",
        "pid": 7,
        "sort": null
    }, {
        "id": 163,
        "name": "桥东区",
        "pid": 8,
        "sort": null
    }, {
        "id": 164,
        "name": "桥西区",
        "pid": 8,
        "sort": null
    }, {
        "id": 165,
        "name": "宣化区",
        "pid": 8,
        "sort": null
    }, {
        "id": 166,
        "name": "下花园区",
        "pid": 8,
        "sort": null
    }, {
        "id": 167,
        "name": "宣化县",
        "pid": 8,
        "sort": null
    }, {
        "id": 168,
        "name": "张北县",
        "pid": 8,
        "sort": null
    }, {
        "id": 169,
        "name": "康保县",
        "pid": 8,
        "sort": null
    }, {
        "id": 170,
        "name": "沽源县",
        "pid": 8,
        "sort": null
    }, {
        "id": 171,
        "name": "尚义县",
        "pid": 8,
        "sort": null
    }, {
        "id": 172,
        "name": "蔚县",
        "pid": 8,
        "sort": null
    }, {
        "id": 173,
        "name": "阳原县",
        "pid": 8,
        "sort": null
    }, {
        "id": 174,
        "name": "怀安县",
        "pid": 8,
        "sort": null
    }, {
        "id": 175,
        "name": "万全县",
        "pid": 8,
        "sort": null
    }, {
        "id": 176,
        "name": "怀来县",
        "pid": 8,
        "sort": null
    }, {
        "id": 177,
        "name": "涿鹿县",
        "pid": 8,
        "sort": null
    }, {
        "id": 178,
        "name": "赤城县",
        "pid": 8,
        "sort": null
    }, {
        "id": 179,
        "name": "崇礼县",
        "pid": 8,
        "sort": null
    }, {
        "id": 180,
        "name": "双桥区",
        "pid": 9,
        "sort": null
    }, {
        "id": 181,
        "name": "双滦区",
        "pid": 9,
        "sort": null
    }, {
        "id": 182,
        "name": "鹰手营子矿区",
        "pid": 9,
        "sort": null
    }, {
        "id": 183,
        "name": "承德县",
        "pid": 9,
        "sort": null
    }, {
        "id": 184,
        "name": "兴隆县",
        "pid": 9,
        "sort": null
    }, {
        "id": 185,
        "name": "平泉县",
        "pid": 9,
        "sort": null
    }, {
        "id": 186,
        "name": "滦平县",
        "pid": 9,
        "sort": null
    }, {
        "id": 187,
        "name": "隆化县",
        "pid": 9,
        "sort": null
    }, {
        "id": 188,
        "name": "丰宁满族自治县",
        "pid": 9,
        "sort": null
    }, {
        "id": 189,
        "name": "宽城满族自治县",
        "pid": 9,
        "sort": null
    }, {
        "id": 190,
        "name": "围场满族蒙古族自治县",
        "pid": 9,
        "sort": null
    }, {
        "id": 191,
        "name": "路南区",
        "pid": 10,
        "sort": null
    }, {
        "id": 192,
        "name": "路北区",
        "pid": 10,
        "sort": null
    }, {
        "id": 193,
        "name": "古冶区",
        "pid": 10,
        "sort": null
    }, {
        "id": 194,
        "name": "开平区",
        "pid": 10,
        "sort": null
    }, {
        "id": 195,
        "name": "丰南区",
        "pid": 10,
        "sort": null
    }, {
        "id": 196,
        "name": "丰润区",
        "pid": 10,
        "sort": null
    }, {
        "id": 197,
        "name": "滦县",
        "pid": 10,
        "sort": null
    }, {
        "id": 198,
        "name": "滦南县",
        "pid": 10,
        "sort": null
    }, {
        "id": 199,
        "name": "乐亭县",
        "pid": 10,
        "sort": null
    }, {
        "id": 200,
        "name": "迁西县",
        "pid": 10,
        "sort": null
    }, {
        "id": 201,
        "name": "玉田县",
        "pid": 10,
        "sort": null
    }, {
        "id": 202,
        "name": "唐海县",
        "pid": 10,
        "sort": null
    }, {
        "id": 203,
        "name": "遵化市",
        "pid": 10,
        "sort": null
    }, {
        "id": 204,
        "name": "迁安市",
        "pid": 10,
        "sort": null
    }, {
        "id": 205,
        "name": "安次区",
        "pid": 11,
        "sort": null
    }, {
        "id": 206,
        "name": "广阳区",
        "pid": 11,
        "sort": null
    }, {
        "id": 207,
        "name": "固安县",
        "pid": 11,
        "sort": null
    }, {
        "id": 208,
        "name": "永清县",
        "pid": 11,
        "sort": null
    }, {
        "id": 209,
        "name": "香河县",
        "pid": 11,
        "sort": null
    }, {
        "id": 210,
        "name": "大城县",
        "pid": 11,
        "sort": null
    }, {
        "id": 211,
        "name": "文安县",
        "pid": 11,
        "sort": null
    }, {
        "id": 212,
        "name": "大厂回族自治县",
        "pid": 11,
        "sort": null
    }, {
        "id": 213,
        "name": "霸州市",
        "pid": 11,
        "sort": null
    }, {
        "id": 214,
        "name": "三河市",
        "pid": 11,
        "sort": null
    }, {
        "id": 215,
        "name": "新华区",
        "pid": 12,
        "sort": null
    }, {
        "id": 216,
        "name": "运河区",
        "pid": 12,
        "sort": null
    }, {
        "id": 217,
        "name": "沧县",
        "pid": 12,
        "sort": null
    }, {
        "id": 218,
        "name": "青县",
        "pid": 12,
        "sort": null
    }, {
        "id": 219,
        "name": "东光县",
        "pid": 12,
        "sort": null
    }, {
        "id": 220,
        "name": "海兴县",
        "pid": 12,
        "sort": null
    }, {
        "id": 221,
        "name": "盐山县",
        "pid": 12,
        "sort": null
    }, {
        "id": 222,
        "name": "肃宁县",
        "pid": 12,
        "sort": null
    }, {
        "id": 223,
        "name": "南皮县",
        "pid": 12,
        "sort": null
    }, {
        "id": 224,
        "name": "吴桥县",
        "pid": 12,
        "sort": null
    }, {
        "id": 225,
        "name": "献县",
        "pid": 12,
        "sort": null
    }, {
        "id": 226,
        "name": "孟村回族自治县",
        "pid": 12,
        "sort": null
    }, {
        "id": 227,
        "name": "泊头市",
        "pid": 12,
        "sort": null
    }, {
        "id": 228,
        "name": "任丘市",
        "pid": 12,
        "sort": null
    }, {
        "id": 229,
        "name": "黄骅市",
        "pid": 12,
        "sort": null
    }, {
        "id": 230,
        "name": "河间市",
        "pid": 12,
        "sort": null
    }, {
        "id": 231,
        "name": "桃城区",
        "pid": 13,
        "sort": null
    }, {
        "id": 232,
        "name": "枣强县",
        "pid": 13,
        "sort": null
    }, {
        "id": 233,
        "name": "武邑县",
        "pid": 13,
        "sort": null
    }, {
        "id": 234,
        "name": "武强县",
        "pid": 13,
        "sort": null
    }, {
        "id": 235,
        "name": "饶阳县",
        "pid": 13,
        "sort": null
    }, {
        "id": 236,
        "name": "安平县",
        "pid": 13,
        "sort": null
    }, {
        "id": 237,
        "name": "故城县",
        "pid": 13,
        "sort": null
    }, {
        "id": 238,
        "name": "景县",
        "pid": 13,
        "sort": null
    }, {
        "id": 239,
        "name": "阜城县",
        "pid": 13,
        "sort": null
    }, {
        "id": 240,
        "name": "冀州市",
        "pid": 13,
        "sort": null
    }, {
        "id": 241,
        "name": "深州市",
        "pid": 13,
        "sort": null
    }, {
        "id": 242,
        "name": "桥东区",
        "pid": 14,
        "sort": null
    }, {
        "id": 243,
        "name": "桥西区",
        "pid": 14,
        "sort": null
    }, {
        "id": 244,
        "name": "邢台县",
        "pid": 14,
        "sort": null
    }, {
        "id": 245,
        "name": "临城县",
        "pid": 14,
        "sort": null
    }, {
        "id": 246,
        "name": "内丘县",
        "pid": 14,
        "sort": null
    }, {
        "id": 247,
        "name": "柏乡县",
        "pid": 14,
        "sort": null
    }, {
        "id": 248,
        "name": "隆尧县",
        "pid": 14,
        "sort": null
    }, {
        "id": 249,
        "name": "任县",
        "pid": 14,
        "sort": null
    }, {
        "id": 250,
        "name": "南和县",
        "pid": 14,
        "sort": null
    }, {
        "id": 251,
        "name": "宁晋县",
        "pid": 14,
        "sort": null
    }, {
        "id": 252,
        "name": "巨鹿县",
        "pid": 14,
        "sort": null
    }, {
        "id": 253,
        "name": "新河县",
        "pid": 14,
        "sort": null
    }, {
        "id": 254,
        "name": "广宗县",
        "pid": 14,
        "sort": null
    }, {
        "id": 255,
        "name": "平乡县",
        "pid": 14,
        "sort": null
    }, {
        "id": 256,
        "name": "威县",
        "pid": 14,
        "sort": null
    }, {
        "id": 257,
        "name": "清河县",
        "pid": 14,
        "sort": null
    }, {
        "id": 258,
        "name": "临西县",
        "pid": 14,
        "sort": null
    }, {
        "id": 259,
        "name": "南宫市",
        "pid": 14,
        "sort": null
    }, {
        "id": 260,
        "name": "沙河市",
        "pid": 14,
        "sort": null
    }, {
        "id": 261,
        "name": "海港区",
        "pid": 15,
        "sort": null
    }, {
        "id": 262,
        "name": "山海关区",
        "pid": 15,
        "sort": null
    }, {
        "id": 263,
        "name": "北戴河区",
        "pid": 15,
        "sort": null
    }, {
        "id": 264,
        "name": "青龙满族自治县",
        "pid": 15,
        "sort": null
    }, {
        "id": 265,
        "name": "昌黎县",
        "pid": 15,
        "sort": null
    }, {
        "id": 266,
        "name": "抚宁县",
        "pid": 15,
        "sort": null
    }, {
        "id": 267,
        "name": "卢龙县",
        "pid": 15,
        "sort": null
    }, {
        "id": 268,
        "name": "朔城区",
        "pid": 16,
        "sort": null
    }, {
        "id": 269,
        "name": "平鲁区",
        "pid": 16,
        "sort": null
    }, {
        "id": 270,
        "name": "山阴县",
        "pid": 16,
        "sort": null
    }, {
        "id": 271,
        "name": "应县",
        "pid": 16,
        "sort": null
    }, {
        "id": 272,
        "name": "右玉县",
        "pid": 16,
        "sort": null
    }, {
        "id": 273,
        "name": "怀仁县",
        "pid": 16,
        "sort": null
    }, {
        "id": 274,
        "name": "忻府区",
        "pid": 17,
        "sort": null
    }, {
        "id": 275,
        "name": "定襄县",
        "pid": 17,
        "sort": null
    }, {
        "id": 276,
        "name": "五台县",
        "pid": 17,
        "sort": null
    }, {
        "id": 277,
        "name": "代县",
        "pid": 17,
        "sort": null
    }, {
        "id": 278,
        "name": "繁峙县",
        "pid": 17,
        "sort": null
    }, {
        "id": 279,
        "name": "宁武县",
        "pid": 17,
        "sort": null
    }, {
        "id": 280,
        "name": "静乐县",
        "pid": 17,
        "sort": null
    }, {
        "id": 281,
        "name": "神池县",
        "pid": 17,
        "sort": null
    }, {
        "id": 282,
        "name": "五寨县",
        "pid": 17,
        "sort": null
    }, {
        "id": 283,
        "name": "岢岚县",
        "pid": 17,
        "sort": null
    }, {
        "id": 284,
        "name": "河曲县",
        "pid": 17,
        "sort": null
    }, {
        "id": 285,
        "name": "保德县",
        "pid": 17,
        "sort": null
    }, {
        "id": 286,
        "name": "偏关县",
        "pid": 17,
        "sort": null
    }, {
        "id": 287,
        "name": "原平市",
        "pid": 17,
        "sort": null
    }, {
        "id": 288,
        "name": "小店区",
        "pid": 18,
        "sort": null
    }, {
        "id": 289,
        "name": "迎泽区",
        "pid": 18,
        "sort": null
    }, {
        "id": 290,
        "name": "杏花岭区",
        "pid": 18,
        "sort": null
    }, {
        "id": 291,
        "name": "尖草坪区",
        "pid": 18,
        "sort": null
    }, {
        "id": 292,
        "name": "万柏林区",
        "pid": 18,
        "sort": null
    }, {
        "id": 293,
        "name": "晋源区",
        "pid": 18,
        "sort": null
    }, {
        "id": 294,
        "name": "清徐县",
        "pid": 18,
        "sort": null
    }, {
        "id": 295,
        "name": "阳曲县",
        "pid": 18,
        "sort": null
    }, {
        "id": 296,
        "name": "娄烦县",
        "pid": 18,
        "sort": null
    }, {
        "id": 297,
        "name": "古交市",
        "pid": 18,
        "sort": null
    }, {
        "id": 298,
        "name": "矿区",
        "pid": 19,
        "sort": null
    }, {
        "id": 299,
        "name": "南郊区",
        "pid": 19,
        "sort": null
    }, {
        "id": 300,
        "name": "新荣区",
        "pid": 19,
        "sort": null
    }, {
        "id": 301,
        "name": "阳高县",
        "pid": 19,
        "sort": null
    }, {
        "id": 302,
        "name": "天镇县",
        "pid": 19,
        "sort": null
    }, {
        "id": 303,
        "name": "广灵县",
        "pid": 19,
        "sort": null
    }, {
        "id": 304,
        "name": "灵丘县",
        "pid": 19,
        "sort": null
    }, {
        "id": 305,
        "name": "浑源县",
        "pid": 19,
        "sort": null
    }, {
        "id": 306,
        "name": "左云县",
        "pid": 19,
        "sort": null
    }, {
        "id": 307,
        "name": "大同县",
        "pid": 19,
        "sort": null
    }, {
        "id": 308,
        "name": "矿区",
        "pid": 20,
        "sort": null
    }, {
        "id": 309,
        "name": "平定县",
        "pid": 20,
        "sort": null
    }, {
        "id": 310,
        "name": "盂县",
        "pid": 20,
        "sort": null
    }, {
        "id": 311,
        "name": "榆次区",
        "pid": 21,
        "sort": null
    }, {
        "id": 312,
        "name": "榆社县",
        "pid": 21,
        "sort": null
    }, {
        "id": 313,
        "name": "左权县",
        "pid": 21,
        "sort": null
    }, {
        "id": 314,
        "name": "和顺县",
        "pid": 21,
        "sort": null
    }, {
        "id": 315,
        "name": "昔阳县",
        "pid": 21,
        "sort": null
    }, {
        "id": 316,
        "name": "寿阳县",
        "pid": 21,
        "sort": null
    }, {
        "id": 317,
        "name": "太谷县",
        "pid": 21,
        "sort": null
    }, {
        "id": 318,
        "name": "祁县",
        "pid": 21,
        "sort": null
    }, {
        "id": 319,
        "name": "平遥县",
        "pid": 21,
        "sort": null
    }, {
        "id": 320,
        "name": "灵石县",
        "pid": 21,
        "sort": null
    }, {
        "id": 321,
        "name": "介休市",
        "pid": 21,
        "sort": null
    }, {
        "id": 322,
        "name": "长治县",
        "pid": 22,
        "sort": null
    }, {
        "id": 323,
        "name": "襄垣县",
        "pid": 22,
        "sort": null
    }, {
        "id": 324,
        "name": "屯留县",
        "pid": 22,
        "sort": null
    }, {
        "id": 325,
        "name": "平顺县",
        "pid": 22,
        "sort": null
    }, {
        "id": 326,
        "name": "黎城县",
        "pid": 22,
        "sort": null
    }, {
        "id": 327,
        "name": "壶关县",
        "pid": 22,
        "sort": null
    }, {
        "id": 328,
        "name": "长子县",
        "pid": 22,
        "sort": null
    }, {
        "id": 329,
        "name": "武乡县",
        "pid": 22,
        "sort": null
    }, {
        "id": 330,
        "name": "沁县",
        "pid": 22,
        "sort": null
    }, {
        "id": 331,
        "name": "沁源县",
        "pid": 22,
        "sort": null
    }, {
        "id": 332,
        "name": "潞城市",
        "pid": 22,
        "sort": null
    }, {
        "id": 333,
        "name": "沁水县",
        "pid": 23,
        "sort": null
    }, {
        "id": 334,
        "name": "阳城县",
        "pid": 23,
        "sort": null
    }, {
        "id": 335,
        "name": "陵川县",
        "pid": 23,
        "sort": null
    }, {
        "id": 336,
        "name": "泽州县",
        "pid": 23,
        "sort": null
    }, {
        "id": 337,
        "name": "高平市",
        "pid": 23,
        "sort": null
    }, {
        "id": 338,
        "name": "尧都区",
        "pid": 24,
        "sort": null
    }, {
        "id": 339,
        "name": "曲沃县",
        "pid": 24,
        "sort": null
    }, {
        "id": 340,
        "name": "翼城县",
        "pid": 24,
        "sort": null
    }, {
        "id": 341,
        "name": "襄汾县",
        "pid": 24,
        "sort": null
    }, {
        "id": 342,
        "name": "洪洞县",
        "pid": 24,
        "sort": null
    }, {
        "id": 343,
        "name": "古县",
        "pid": 24,
        "sort": null
    }, {
        "id": 344,
        "name": "安泽县",
        "pid": 24,
        "sort": null
    }, {
        "id": 345,
        "name": "浮山县",
        "pid": 24,
        "sort": null
    }, {
        "id": 346,
        "name": "吉县",
        "pid": 24,
        "sort": null
    }, {
        "id": 347,
        "name": "乡宁县",
        "pid": 24,
        "sort": null
    }, {
        "id": 348,
        "name": "大宁县",
        "pid": 24,
        "sort": null
    }, {
        "id": 349,
        "name": "隰县",
        "pid": 24,
        "sort": null
    }, {
        "id": 350,
        "name": "永和县",
        "pid": 24,
        "sort": null
    }, {
        "id": 351,
        "name": "蒲县",
        "pid": 24,
        "sort": null
    }, {
        "id": 352,
        "name": "汾西县",
        "pid": 24,
        "sort": null
    }, {
        "id": 353,
        "name": "侯马市",
        "pid": 24,
        "sort": null
    }, {
        "id": 354,
        "name": "霍州市",
        "pid": 24,
        "sort": null
    }, {
        "id": 355,
        "name": "离石区",
        "pid": 25,
        "sort": null
    }, {
        "id": 356,
        "name": "文水县",
        "pid": 25,
        "sort": null
    }, {
        "id": 357,
        "name": "交城县",
        "pid": 25,
        "sort": null
    }, {
        "id": 358,
        "name": "兴县",
        "pid": 25,
        "sort": null
    }, {
        "id": 359,
        "name": "临县",
        "pid": 25,
        "sort": null
    }, {
        "id": 360,
        "name": "柳林县",
        "pid": 25,
        "sort": null
    }, {
        "id": 361,
        "name": "石楼县",
        "pid": 25,
        "sort": null
    }, {
        "id": 362,
        "name": "岚县",
        "pid": 25,
        "sort": null
    }, {
        "id": 363,
        "name": "方山县",
        "pid": 25,
        "sort": null
    }, {
        "id": 364,
        "name": "中阳县",
        "pid": 25,
        "sort": null
    }, {
        "id": 365,
        "name": "交口县",
        "pid": 25,
        "sort": null
    }, {
        "id": 366,
        "name": "孝义市",
        "pid": 25,
        "sort": null
    }, {
        "id": 367,
        "name": "汾阳市",
        "pid": 25,
        "sort": null
    }, {
        "id": 368,
        "name": "盐湖区",
        "pid": 26,
        "sort": null
    }, {
        "id": 369,
        "name": "临猗县",
        "pid": 26,
        "sort": null
    }, {
        "id": 370,
        "name": "万荣县",
        "pid": 26,
        "sort": null
    }, {
        "id": 371,
        "name": "闻喜县",
        "pid": 26,
        "sort": null
    }, {
        "id": 372,
        "name": "稷山县",
        "pid": 26,
        "sort": null
    }, {
        "id": 373,
        "name": "新绛县",
        "pid": 26,
        "sort": null
    }, {
        "id": 374,
        "name": "绛县",
        "pid": 26,
        "sort": null
    }, {
        "id": 375,
        "name": "垣曲县",
        "pid": 26,
        "sort": null
    }, {
        "id": 376,
        "name": "夏县",
        "pid": 26,
        "sort": null
    }, {
        "id": 377,
        "name": "平陆县",
        "pid": 26,
        "sort": null
    }, {
        "id": 378,
        "name": "芮城县",
        "pid": 26,
        "sort": null
    }, {
        "id": 379,
        "name": "永济市",
        "pid": 26,
        "sort": null
    }, {
        "id": 380,
        "name": "河津市",
        "pid": 26,
        "sort": null
    }, {
        "id": 381,
        "name": "和平区",
        "pid": 27,
        "sort": null
    }, {
        "id": 382,
        "name": "沈河区",
        "pid": 27,
        "sort": null
    }, {
        "id": 383,
        "name": "大东区",
        "pid": 27,
        "sort": null
    }, {
        "id": 384,
        "name": "皇姑区",
        "pid": 27,
        "sort": null
    }, {
        "id": 385,
        "name": "铁西区",
        "pid": 27,
        "sort": null
    }, {
        "id": 386,
        "name": "苏家屯区",
        "pid": 27,
        "sort": null
    }, {
        "id": 387,
        "name": "东陵区",
        "pid": 27,
        "sort": null
    }, {
        "id": 388,
        "name": "沈北新区",
        "pid": 27,
        "sort": null
    }, {
        "id": 389,
        "name": "于洪区",
        "pid": 27,
        "sort": null
    }, {
        "id": 390,
        "name": "辽中县",
        "pid": 27,
        "sort": null
    }, {
        "id": 391,
        "name": "康平县",
        "pid": 27,
        "sort": null
    }, {
        "id": 392,
        "name": "法库县",
        "pid": 27,
        "sort": null
    }, {
        "id": 393,
        "name": "新民市",
        "pid": 27,
        "sort": null
    }, {
        "id": 394,
        "name": "银州区",
        "pid": 28,
        "sort": null
    }, {
        "id": 395,
        "name": "清河区",
        "pid": 28,
        "sort": null
    }, {
        "id": 396,
        "name": "铁岭县",
        "pid": 28,
        "sort": null
    }, {
        "id": 397,
        "name": "西丰县",
        "pid": 28,
        "sort": null
    }, {
        "id": 398,
        "name": "昌图县",
        "pid": 28,
        "sort": null
    }, {
        "id": 399,
        "name": "调兵山市",
        "pid": 28,
        "sort": null
    }, {
        "id": 400,
        "name": "开原市",
        "pid": 28,
        "sort": null
    }, {
        "id": 401,
        "name": "长海县",
        "pid": 29,
        "sort": null
    }, {
        "id": 402,
        "name": "旅顺口区",
        "pid": 29,
        "sort": null
    }, {
        "id": 403,
        "name": "中山区",
        "pid": 29,
        "sort": null
    }, {
        "id": 404,
        "name": "西岗区",
        "pid": 29,
        "sort": null
    }, {
        "id": 405,
        "name": "沙河口区",
        "pid": 29,
        "sort": null
    }, {
        "id": 406,
        "name": "甘井子区",
        "pid": 29,
        "sort": null
    }, {
        "id": 407,
        "name": "金州区",
        "pid": 29,
        "sort": null
    }, {
        "id": 408,
        "name": "普兰店市",
        "pid": 29,
        "sort": null
    }, {
        "id": 409,
        "name": "瓦房店市",
        "pid": 29,
        "sort": null
    }, {
        "id": 410,
        "name": "庄河市",
        "pid": 29,
        "sort": null
    }, {
        "id": 411,
        "name": "铁东区",
        "pid": 30,
        "sort": null
    }, {
        "id": 412,
        "name": "铁西区",
        "pid": 30,
        "sort": null
    }, {
        "id": 413,
        "name": "立山区",
        "pid": 30,
        "sort": null
    }, {
        "id": 414,
        "name": "千山区",
        "pid": 30,
        "sort": null
    }, {
        "id": 415,
        "name": "台安县",
        "pid": 30,
        "sort": null
    }, {
        "id": 416,
        "name": "岫岩满族自治县",
        "pid": 30,
        "sort": null
    }, {
        "id": 417,
        "name": "海城市",
        "pid": 30,
        "sort": null
    }, {
        "id": 418,
        "name": "新抚区",
        "pid": 31,
        "sort": null
    }, {
        "id": 419,
        "name": "东洲区",
        "pid": 31,
        "sort": null
    }, {
        "id": 420,
        "name": "望花区",
        "pid": 31,
        "sort": null
    }, {
        "id": 421,
        "name": "顺城区",
        "pid": 31,
        "sort": null
    }, {
        "id": 422,
        "name": "抚顺县",
        "pid": 31,
        "sort": null
    }, {
        "id": 423,
        "name": "新宾满族自治县",
        "pid": 31,
        "sort": null
    }, {
        "id": 424,
        "name": "清原满族自治县",
        "pid": 31,
        "sort": null
    }, {
        "id": 425,
        "name": "平山区",
        "pid": 32,
        "sort": null
    }, {
        "id": 426,
        "name": "溪湖区",
        "pid": 32,
        "sort": null
    }, {
        "id": 427,
        "name": "明山区",
        "pid": 32,
        "sort": null
    }, {
        "id": 428,
        "name": "南芬区",
        "pid": 32,
        "sort": null
    }, {
        "id": 429,
        "name": "本溪满族自治县",
        "pid": 32,
        "sort": null
    }, {
        "id": 430,
        "name": "桓仁满族自治县",
        "pid": 32,
        "sort": null
    }, {
        "id": 431,
        "name": "元宝区",
        "pid": 33,
        "sort": null
    }, {
        "id": 432,
        "name": "振兴区",
        "pid": 33,
        "sort": null
    }, {
        "id": 433,
        "name": "振安区",
        "pid": 33,
        "sort": null
    }, {
        "id": 434,
        "name": "宽甸满族自治县",
        "pid": 33,
        "sort": null
    }, {
        "id": 435,
        "name": "东港市",
        "pid": 33,
        "sort": null
    }, {
        "id": 436,
        "name": "凤城市",
        "pid": 33,
        "sort": null
    }, {
        "id": 437,
        "name": "古塔区",
        "pid": 34,
        "sort": null
    }, {
        "id": 438,
        "name": "凌河区",
        "pid": 34,
        "sort": null
    }, {
        "id": 439,
        "name": "太和区",
        "pid": 34,
        "sort": null
    }, {
        "id": 440,
        "name": "黑山县",
        "pid": 34,
        "sort": null
    }, {
        "id": 441,
        "name": "义县",
        "pid": 34,
        "sort": null
    }, {
        "id": 442,
        "name": "凌海市",
        "pid": 34,
        "sort": null
    }, {
        "id": 443,
        "name": "北镇市",
        "pid": 34,
        "sort": null
    }, {
        "id": 444,
        "name": "站前区",
        "pid": 35,
        "sort": null
    }, {
        "id": 445,
        "name": "西市区",
        "pid": 35,
        "sort": null
    }, {
        "id": 446,
        "name": "鮁鱼圈区",
        "pid": 35,
        "sort": null
    }, {
        "id": 447,
        "name": "老边区",
        "pid": 35,
        "sort": null
    }, {
        "id": 448,
        "name": "盖州市",
        "pid": 35,
        "sort": null
    }, {
        "id": 449,
        "name": "大石桥市",
        "pid": 35,
        "sort": null
    }, {
        "id": 450,
        "name": "海州区",
        "pid": 36,
        "sort": null
    }, {
        "id": 451,
        "name": "新邱区",
        "pid": 36,
        "sort": null
    }, {
        "id": 452,
        "name": "太平区",
        "pid": 36,
        "sort": null
    }, {
        "id": 453,
        "name": "清河门区",
        "pid": 36,
        "sort": null
    }, {
        "id": 454,
        "name": "细河区",
        "pid": 36,
        "sort": null
    }, {
        "id": 455,
        "name": "阜新蒙古族自治县",
        "pid": 36,
        "sort": null
    }, {
        "id": 456,
        "name": "彰武县",
        "pid": 36,
        "sort": null
    }, {
        "id": 457,
        "name": "白塔区",
        "pid": 37,
        "sort": null
    }, {
        "id": 458,
        "name": "文圣区",
        "pid": 37,
        "sort": null
    }, {
        "id": 459,
        "name": "宏伟区",
        "pid": 37,
        "sort": null
    }, {
        "id": 460,
        "name": "弓长岭区",
        "pid": 37,
        "sort": null
    }, {
        "id": 461,
        "name": "太子河区",
        "pid": 37,
        "sort": null
    }, {
        "id": 462,
        "name": "辽阳县",
        "pid": 37,
        "sort": null
    }, {
        "id": 463,
        "name": "灯塔市",
        "pid": 37,
        "sort": null
    }, {
        "id": 464,
        "name": "双塔区",
        "pid": 38,
        "sort": null
    }, {
        "id": 465,
        "name": "龙城区",
        "pid": 38,
        "sort": null
    }, {
        "id": 466,
        "name": "朝阳县",
        "pid": 38,
        "sort": null
    }, {
        "id": 467,
        "name": "建平县",
        "pid": 38,
        "sort": null
    }, {
        "id": 468,
        "name": "喀喇沁左翼蒙古族自治县",
        "pid": 38,
        "sort": null
    }, {
        "id": 469,
        "name": "北票市",
        "pid": 38,
        "sort": null
    }, {
        "id": 470,
        "name": "凌源市",
        "pid": 38,
        "sort": null
    }, {
        "id": 471,
        "name": "双台子区",
        "pid": 39,
        "sort": null
    }, {
        "id": 472,
        "name": "兴隆台区",
        "pid": 39,
        "sort": null
    }, {
        "id": 473,
        "name": "大洼县",
        "pid": 39,
        "sort": null
    }, {
        "id": 474,
        "name": "盘山县",
        "pid": 39,
        "sort": null
    }, {
        "id": 475,
        "name": "连山区",
        "pid": 40,
        "sort": null
    }, {
        "id": 476,
        "name": "龙港区",
        "pid": 40,
        "sort": null
    }, {
        "id": 477,
        "name": "南票区",
        "pid": 40,
        "sort": null
    }, {
        "id": 478,
        "name": "绥中县",
        "pid": 40,
        "sort": null
    }, {
        "id": 479,
        "name": "建昌县",
        "pid": 40,
        "sort": null
    }, {
        "id": 480,
        "name": "兴城市",
        "pid": 40,
        "sort": null
    }, {
        "id": 481,
        "name": "南关区",
        "pid": 41,
        "sort": null
    }, {
        "id": 482,
        "name": "宽城区",
        "pid": 41,
        "sort": null
    }, {
        "id": 483,
        "name": "朝阳区",
        "pid": 41,
        "sort": null
    }, {
        "id": 484,
        "name": "二道区",
        "pid": 41,
        "sort": null
    }, {
        "id": 485,
        "name": "绿园区",
        "pid": 41,
        "sort": null
    }, {
        "id": 486,
        "name": "双阳区",
        "pid": 41,
        "sort": null
    }, {
        "id": 487,
        "name": "农安县",
        "pid": 41,
        "sort": null
    }, {
        "id": 488,
        "name": "九台市",
        "pid": 41,
        "sort": null
    }, {
        "id": 489,
        "name": "榆树市",
        "pid": 41,
        "sort": null
    }, {
        "id": 490,
        "name": "德惠市",
        "pid": 41,
        "sort": null
    }, {
        "id": 491,
        "name": "昌邑区",
        "pid": 42,
        "sort": null
    }, {
        "id": 492,
        "name": "龙潭区",
        "pid": 42,
        "sort": null
    }, {
        "id": 493,
        "name": "船营区",
        "pid": 42,
        "sort": null
    }, {
        "id": 494,
        "name": "丰满区",
        "pid": 42,
        "sort": null
    }, {
        "id": 495,
        "name": "永吉县",
        "pid": 42,
        "sort": null
    }, {
        "id": 496,
        "name": "蛟河市",
        "pid": 42,
        "sort": null
    }, {
        "id": 497,
        "name": "桦甸市",
        "pid": 42,
        "sort": null
    }, {
        "id": 498,
        "name": "舒兰市",
        "pid": 42,
        "sort": null
    }, {
        "id": 499,
        "name": "磐石市",
        "pid": 42,
        "sort": null
    }, {
        "id": 500,
        "name": "延吉市",
        "pid": 43,
        "sort": null
    }, {
        "id": 501,
        "name": "图们市",
        "pid": 43,
        "sort": null
    }, {
        "id": 502,
        "name": "敦化市",
        "pid": 43,
        "sort": null
    }, {
        "id": 503,
        "name": "珲春市",
        "pid": 43,
        "sort": null
    }, {
        "id": 504,
        "name": "龙井市",
        "pid": 43,
        "sort": null
    }, {
        "id": 505,
        "name": "和龙市",
        "pid": 43,
        "sort": null
    }, {
        "id": 506,
        "name": "汪清县",
        "pid": 43,
        "sort": null
    }, {
        "id": 507,
        "name": "安图县",
        "pid": 43,
        "sort": null
    }, {
        "id": 508,
        "name": "铁西区",
        "pid": 44,
        "sort": null
    }, {
        "id": 509,
        "name": "铁东区",
        "pid": 44,
        "sort": null
    }, {
        "id": 510,
        "name": "梨树县",
        "pid": 44,
        "sort": null
    }, {
        "id": 511,
        "name": "伊通满族自治县",
        "pid": 44,
        "sort": null
    }, {
        "id": 512,
        "name": "公主岭市",
        "pid": 44,
        "sort": null
    }, {
        "id": 513,
        "name": "双辽市",
        "pid": 44,
        "sort": null
    }, {
        "id": 514,
        "name": "东昌区",
        "pid": 45,
        "sort": null
    }, {
        "id": 515,
        "name": "二道江区",
        "pid": 45,
        "sort": null
    }, {
        "id": 516,
        "name": "通化县",
        "pid": 45,
        "sort": null
    }, {
        "id": 517,
        "name": "辉南县",
        "pid": 45,
        "sort": null
    }, {
        "id": 518,
        "name": "柳河县",
        "pid": 45,
        "sort": null
    }, {
        "id": 519,
        "name": "梅河口市",
        "pid": 45,
        "sort": null
    }, {
        "id": 520,
        "name": "集安市",
        "pid": 45,
        "sort": null
    }, {
        "id": 521,
        "name": "洮北区",
        "pid": 46,
        "sort": null
    }, {
        "id": 522,
        "name": "镇赉县",
        "pid": 46,
        "sort": null
    }, {
        "id": 523,
        "name": "通榆县",
        "pid": 46,
        "sort": null
    }, {
        "id": 524,
        "name": "洮南市",
        "pid": 46,
        "sort": null
    }, {
        "id": 525,
        "name": "大安市",
        "pid": 46,
        "sort": null
    }, {
        "id": 526,
        "name": "龙山区",
        "pid": 47,
        "sort": null
    }, {
        "id": 527,
        "name": "西安区",
        "pid": 47,
        "sort": null
    }, {
        "id": 528,
        "name": "东丰县",
        "pid": 47,
        "sort": null
    }, {
        "id": 529,
        "name": "东辽县",
        "pid": 47,
        "sort": null
    }, {
        "id": 530,
        "name": "宁江区",
        "pid": 48,
        "sort": null
    }, {
        "id": 531,
        "name": "前郭尔罗斯蒙古族自治县",
        "pid": 48,
        "sort": null
    }, {
        "id": 532,
        "name": "长岭县",
        "pid": 48,
        "sort": null
    }, {
        "id": 533,
        "name": "乾安县",
        "pid": 48,
        "sort": null
    }, {
        "id": 534,
        "name": "扶余县",
        "pid": 48,
        "sort": null
    }, {
        "id": 535,
        "name": "八道江区",
        "pid": 49,
        "sort": null
    }, {
        "id": 536,
        "name": "江源区",
        "pid": 49,
        "sort": null
    }, {
        "id": 537,
        "name": "抚松县",
        "pid": 49,
        "sort": null
    }, {
        "id": 538,
        "name": "靖宇县",
        "pid": 49,
        "sort": null
    }, {
        "id": 539,
        "name": "长白朝鲜族自治县",
        "pid": 49,
        "sort": null
    }, {
        "id": 540,
        "name": "临江市",
        "pid": 49,
        "sort": null
    }, {
        "id": 541,
        "name": "道里区",
        "pid": 50,
        "sort": null
    }, {
        "id": 542,
        "name": "南岗区",
        "pid": 50,
        "sort": null
    }, {
        "id": 543,
        "name": "道外区",
        "pid": 50,
        "sort": null
    }, {
        "id": 544,
        "name": "平房区",
        "pid": 50,
        "sort": null
    }, {
        "id": 545,
        "name": "松北区",
        "pid": 50,
        "sort": null
    }, {
        "id": 546,
        "name": "香坊区",
        "pid": 50,
        "sort": null
    }, {
        "id": 547,
        "name": "呼兰区",
        "pid": 50,
        "sort": null
    }, {
        "id": 548,
        "name": "阿城区",
        "pid": 50,
        "sort": null
    }, {
        "id": 549,
        "name": "依兰县",
        "pid": 50,
        "sort": null
    }, {
        "id": 550,
        "name": "方正县",
        "pid": 50,
        "sort": null
    }, {
        "id": 551,
        "name": "宾县",
        "pid": 50,
        "sort": null
    }, {
        "id": 552,
        "name": "巴彦县",
        "pid": 50,
        "sort": null
    }, {
        "id": 553,
        "name": "木兰县",
        "pid": 50,
        "sort": null
    }, {
        "id": 554,
        "name": "通河县",
        "pid": 50,
        "sort": null
    }, {
        "id": 555,
        "name": "延寿县",
        "pid": 50,
        "sort": null
    }, {
        "id": 556,
        "name": "双城市",
        "pid": 50,
        "sort": null
    }, {
        "id": 557,
        "name": "尚志市",
        "pid": 50,
        "sort": null
    }, {
        "id": 558,
        "name": "五常市",
        "pid": 50,
        "sort": null
    }, {
        "id": 559,
        "name": "龙沙区",
        "pid": 51,
        "sort": null
    }, {
        "id": 560,
        "name": "建华区",
        "pid": 51,
        "sort": null
    }, {
        "id": 561,
        "name": "铁锋区",
        "pid": 51,
        "sort": null
    }, {
        "id": 562,
        "name": "昂昂溪区",
        "pid": 51,
        "sort": null
    }, {
        "id": 563,
        "name": "富拉尔基区",
        "pid": 51,
        "sort": null
    }, {
        "id": 564,
        "name": "碾子山区",
        "pid": 51,
        "sort": null
    }, {
        "id": 565,
        "name": "梅里斯达翰尔族区",
        "pid": 51,
        "sort": null
    }, {
        "id": 566,
        "name": "龙江县",
        "pid": 51,
        "sort": null
    }, {
        "id": 567,
        "name": "依安县",
        "pid": 51,
        "sort": null
    }, {
        "id": 568,
        "name": "泰来县",
        "pid": 51,
        "sort": null
    }, {
        "id": 569,
        "name": "甘南县",
        "pid": 51,
        "sort": null
    }, {
        "id": 570,
        "name": "富裕县",
        "pid": 51,
        "sort": null
    }, {
        "id": 571,
        "name": "克山县",
        "pid": 51,
        "sort": null
    }, {
        "id": 572,
        "name": "克东县",
        "pid": 51,
        "sort": null
    }, {
        "id": 573,
        "name": "拜泉县",
        "pid": 51,
        "sort": null
    }, {
        "id": 574,
        "name": "讷河市",
        "pid": 51,
        "sort": null
    }, {
        "id": 575,
        "name": "鸡冠区",
        "pid": 52,
        "sort": null
    }, {
        "id": 576,
        "name": "恒山区",
        "pid": 52,
        "sort": null
    }, {
        "id": 577,
        "name": "滴道区",
        "pid": 52,
        "sort": null
    }, {
        "id": 578,
        "name": "梨树区",
        "pid": 52,
        "sort": null
    }, {
        "id": 579,
        "name": "城子河区",
        "pid": 52,
        "sort": null
    }, {
        "id": 580,
        "name": "麻山区",
        "pid": 52,
        "sort": null
    }, {
        "id": 581,
        "name": "鸡东县",
        "pid": 52,
        "sort": null
    }, {
        "id": 582,
        "name": "虎林市",
        "pid": 52,
        "sort": null
    }, {
        "id": 583,
        "name": "密山市",
        "pid": 52,
        "sort": null
    }, {
        "id": 584,
        "name": "东安区",
        "pid": 53,
        "sort": null
    }, {
        "id": 585,
        "name": "阳明区",
        "pid": 53,
        "sort": null
    }, {
        "id": 586,
        "name": "爱民区",
        "pid": 53,
        "sort": null
    }, {
        "id": 587,
        "name": "西安区",
        "pid": 53,
        "sort": null
    }, {
        "id": 588,
        "name": "东宁县",
        "pid": 53,
        "sort": null
    }, {
        "id": 589,
        "name": "林口县",
        "pid": 53,
        "sort": null
    }, {
        "id": 590,
        "name": "绥芬河市",
        "pid": 53,
        "sort": null
    }, {
        "id": 591,
        "name": "海林市",
        "pid": 53,
        "sort": null
    }, {
        "id": 592,
        "name": "宁安市",
        "pid": 53,
        "sort": null
    }, {
        "id": 593,
        "name": "穆棱市",
        "pid": 53,
        "sort": null
    }, {
        "id": 594,
        "name": "新兴区",
        "pid": 54,
        "sort": null
    }, {
        "id": 595,
        "name": "桃山区",
        "pid": 54,
        "sort": null
    }, {
        "id": 596,
        "name": "茄子河区",
        "pid": 54,
        "sort": null
    }, {
        "id": 597,
        "name": "勃利县",
        "pid": 54,
        "sort": null
    }, {
        "id": 598,
        "name": "向阳区",
        "pid": 55,
        "sort": null
    }, {
        "id": 599,
        "name": "前进区",
        "pid": 55,
        "sort": null
    }, {
        "id": 600,
        "name": "东风区",
        "pid": 55,
        "sort": null
    }, {
        "id": 601,
        "name": "桦南县",
        "pid": 55,
        "sort": null
    }, {
        "id": 602,
        "name": "桦川县",
        "pid": 55,
        "sort": null
    }, {
        "id": 603,
        "name": "汤原县",
        "pid": 55,
        "sort": null
    }, {
        "id": 604,
        "name": "抚远县",
        "pid": 55,
        "sort": null
    }, {
        "id": 605,
        "name": "同江市",
        "pid": 55,
        "sort": null
    }, {
        "id": 606,
        "name": "富锦市",
        "pid": 55,
        "sort": null
    }, {
        "id": 607,
        "name": "向阳区",
        "pid": 56,
        "sort": null
    }, {
        "id": 608,
        "name": "工农区",
        "pid": 56,
        "sort": null
    }, {
        "id": 609,
        "name": "南山区",
        "pid": 56,
        "sort": null
    }, {
        "id": 610,
        "name": "兴安区",
        "pid": 56,
        "sort": null
    }, {
        "id": 611,
        "name": "东山区",
        "pid": 56,
        "sort": null
    }, {
        "id": 612,
        "name": "兴山区",
        "pid": 56,
        "sort": null
    }, {
        "id": 613,
        "name": "萝北县",
        "pid": 56,
        "sort": null
    }, {
        "id": 614,
        "name": "绥滨县",
        "pid": 56,
        "sort": null
    }, {
        "id": 615,
        "name": "尖山区",
        "pid": 57,
        "sort": null
    }, {
        "id": 616,
        "name": "岭东区",
        "pid": 57,
        "sort": null
    }, {
        "id": 617,
        "name": "四方台区",
        "pid": 57,
        "sort": null
    }, {
        "id": 618,
        "name": "宝山区",
        "pid": 57,
        "sort": null
    }, {
        "id": 619,
        "name": "集贤县",
        "pid": 57,
        "sort": null
    }, {
        "id": 620,
        "name": "友谊县",
        "pid": 57,
        "sort": null
    }, {
        "id": 621,
        "name": "宝清县",
        "pid": 57,
        "sort": null
    }, {
        "id": 622,
        "name": "饶河县",
        "pid": 57,
        "sort": null
    }, {
        "id": 623,
        "name": "北林区",
        "pid": 58,
        "sort": null
    }, {
        "id": 624,
        "name": "望奎县",
        "pid": 58,
        "sort": null
    }, {
        "id": 625,
        "name": "兰西县",
        "pid": 58,
        "sort": null
    }, {
        "id": 626,
        "name": "青冈县",
        "pid": 58,
        "sort": null
    }, {
        "id": 627,
        "name": "庆安县",
        "pid": 58,
        "sort": null
    }, {
        "id": 628,
        "name": "明水县",
        "pid": 58,
        "sort": null
    }, {
        "id": 629,
        "name": "绥棱县",
        "pid": 58,
        "sort": null
    }, {
        "id": 630,
        "name": "安达市",
        "pid": 58,
        "sort": null
    }, {
        "id": 631,
        "name": "肇东市",
        "pid": 58,
        "sort": null
    }, {
        "id": 632,
        "name": "海伦市",
        "pid": 58,
        "sort": null
    }, {
        "id": 633,
        "name": "爱辉区",
        "pid": 59,
        "sort": null
    }, {
        "id": 634,
        "name": "嫩江县",
        "pid": 59,
        "sort": null
    }, {
        "id": 635,
        "name": "逊克县",
        "pid": 59,
        "sort": null
    }, {
        "id": 636,
        "name": "孙吴县",
        "pid": 59,
        "sort": null
    }, {
        "id": 637,
        "name": "北安市",
        "pid": 59,
        "sort": null
    }, {
        "id": 638,
        "name": "五大连池市",
        "pid": 59,
        "sort": null
    }, {
        "id": 639,
        "name": "呼玛县",
        "pid": 60,
        "sort": null
    }, {
        "id": 640,
        "name": "塔河县",
        "pid": 60,
        "sort": null
    }, {
        "id": 641,
        "name": "漠河县",
        "pid": 60,
        "sort": null
    }, {
        "id": 642,
        "name": "伊春区",
        "pid": 61,
        "sort": null
    }, {
        "id": 643,
        "name": "南岔区",
        "pid": 61,
        "sort": null
    }, {
        "id": 644,
        "name": "友好区",
        "pid": 61,
        "sort": null
    }, {
        "id": 645,
        "name": "西林区",
        "pid": 61,
        "sort": null
    }, {
        "id": 646,
        "name": "翠峦区",
        "pid": 61,
        "sort": null
    }, {
        "id": 647,
        "name": "新青区",
        "pid": 61,
        "sort": null
    }, {
        "id": 648,
        "name": "美溪区",
        "pid": 61,
        "sort": null
    }, {
        "id": 649,
        "name": "金山屯区",
        "pid": 61,
        "sort": null
    }, {
        "id": 650,
        "name": "五营区",
        "pid": 61,
        "sort": null
    }, {
        "id": 651,
        "name": "乌马河区",
        "pid": 61,
        "sort": null
    }, {
        "id": 652,
        "name": "汤旺河区",
        "pid": 61,
        "sort": null
    }, {
        "id": 653,
        "name": "带岭区",
        "pid": 61,
        "sort": null
    }, {
        "id": 654,
        "name": "乌伊岭区",
        "pid": 61,
        "sort": null
    }, {
        "id": 655,
        "name": "红星区",
        "pid": 61,
        "sort": null
    }, {
        "id": 656,
        "name": "上甘岭区",
        "pid": 61,
        "sort": null
    }, {
        "id": 657,
        "name": "嘉荫县",
        "pid": 61,
        "sort": null
    }, {
        "id": 658,
        "name": "铁力市",
        "pid": 61,
        "sort": null
    }, {
        "id": 659,
        "name": "萨尔图区",
        "pid": 62,
        "sort": null
    }, {
        "id": 660,
        "name": "龙凤区",
        "pid": 62,
        "sort": null
    }, {
        "id": 661,
        "name": "让胡路区",
        "pid": 62,
        "sort": null
    }, {
        "id": 662,
        "name": "红岗区",
        "pid": 62,
        "sort": null
    }, {
        "id": 663,
        "name": "大同区",
        "pid": 62,
        "sort": null
    }, {
        "id": 664,
        "name": "肇州县",
        "pid": 62,
        "sort": null
    }, {
        "id": 665,
        "name": "肇源县",
        "pid": 62,
        "sort": null
    }, {
        "id": 666,
        "name": "林甸县",
        "pid": 62,
        "sort": null
    }, {
        "id": 667,
        "name": "杜尔伯特蒙古族自治县",
        "pid": 62,
        "sort": null
    }, {
        "id": 668,
        "name": "江宁区",
        "pid": 63,
        "sort": null
    }, {
        "id": 669,
        "name": "浦口区",
        "pid": 63,
        "sort": null
    }, {
        "id": 670,
        "name": "玄武区",
        "pid": 63,
        "sort": null
    }, {
        "id": 671,
        "name": "白下区",
        "pid": 63,
        "sort": null
    }, {
        "id": 672,
        "name": "秦淮区",
        "pid": 63,
        "sort": null
    }, {
        "id": 673,
        "name": "建邺区",
        "pid": 63,
        "sort": null
    }, {
        "id": 674,
        "name": "鼓楼区",
        "pid": 63,
        "sort": null
    }, {
        "id": 675,
        "name": "下关区",
        "pid": 63,
        "sort": null
    }, {
        "id": 676,
        "name": "栖霞区",
        "pid": 63,
        "sort": null
    }, {
        "id": 677,
        "name": "雨花台区",
        "pid": 63,
        "sort": null
    }, {
        "id": 678,
        "name": "六合区",
        "pid": 63,
        "sort": null
    }, {
        "id": 679,
        "name": "溧水县",
        "pid": 63,
        "sort": null
    }, {
        "id": 680,
        "name": "高淳县",
        "pid": 63,
        "sort": null
    }, {
        "id": 681,
        "name": "崇安区",
        "pid": 64,
        "sort": null
    }, {
        "id": 682,
        "name": "南长区",
        "pid": 64,
        "sort": null
    }, {
        "id": 683,
        "name": "北塘区",
        "pid": 64,
        "sort": null
    }, {
        "id": 684,
        "name": "锡山区",
        "pid": 64,
        "sort": null
    }, {
        "id": 685,
        "name": "惠山区",
        "pid": 64,
        "sort": null
    }, {
        "id": 686,
        "name": "滨湖区",
        "pid": 64,
        "sort": null
    }, {
        "id": 687,
        "name": "江阴市",
        "pid": 64,
        "sort": null
    }, {
        "id": 688,
        "name": "宜兴市",
        "pid": 64,
        "sort": null
    }, {
        "id": 689,
        "name": "京口区",
        "pid": 65,
        "sort": null
    }, {
        "id": 690,
        "name": "润州区",
        "pid": 65,
        "sort": null
    }, {
        "id": 691,
        "name": "丹徒区",
        "pid": 65,
        "sort": null
    }, {
        "id": 692,
        "name": "丹阳市",
        "pid": 65,
        "sort": null
    }, {
        "id": 693,
        "name": "扬中市",
        "pid": 65,
        "sort": null
    }, {
        "id": 694,
        "name": "句容市",
        "pid": 65,
        "sort": null
    }, {
        "id": 695,
        "name": "沧浪区",
        "pid": 66,
        "sort": null
    }, {
        "id": 696,
        "name": "常熟市",
        "pid": 66,
        "sort": null
    }, {
        "id": 697,
        "name": "平江区",
        "pid": 66,
        "sort": null
    }, {
        "id": 698,
        "name": "金阊区",
        "pid": 66,
        "sort": null
    }, {
        "id": 699,
        "name": "虎丘区",
        "pid": 66,
        "sort": null
    }, {
        "id": 700,
        "name": "昆山市",
        "pid": 66,
        "sort": null
    }, {
        "id": 701,
        "name": "太仓市",
        "pid": 66,
        "sort": null
    }, {
        "id": 702,
        "name": "吴江市",
        "pid": 66,
        "sort": null
    }, {
        "id": 703,
        "name": "吴中区",
        "pid": 66,
        "sort": null
    }, {
        "id": 704,
        "name": "相城区",
        "pid": 66,
        "sort": null
    }, {
        "id": 705,
        "name": "张家港市",
        "pid": 66,
        "sort": null
    }, {
        "id": 706,
        "name": "崇川区",
        "pid": 67,
        "sort": null
    }, {
        "id": 707,
        "name": "港闸区",
        "pid": 67,
        "sort": null
    }, {
        "id": 708,
        "name": "海安县",
        "pid": 67,
        "sort": null
    }, {
        "id": 709,
        "name": "如东县",
        "pid": 67,
        "sort": null
    }, {
        "id": 710,
        "name": "启东市",
        "pid": 67,
        "sort": null
    }, {
        "id": 711,
        "name": "如皋市",
        "pid": 67,
        "sort": null
    }, {
        "id": 712,
        "name": "通州市",
        "pid": 67,
        "sort": null
    }, {
        "id": 713,
        "name": "海门市",
        "pid": 67,
        "sort": null
    }, {
        "id": 714,
        "name": "高邮市",
        "pid": 68,
        "sort": null
    }, {
        "id": 715,
        "name": "广陵区",
        "pid": 68,
        "sort": null
    }, {
        "id": 716,
        "name": "邗江区",
        "pid": 68,
        "sort": null
    }, {
        "id": 717,
        "name": "维扬区",
        "pid": 68,
        "sort": null
    }, {
        "id": 718,
        "name": "宝应县",
        "pid": 68,
        "sort": null
    }, {
        "id": 719,
        "name": "江都市",
        "pid": 68,
        "sort": null
    }, {
        "id": 720,
        "name": "仪征市",
        "pid": 68,
        "sort": null
    }, {
        "id": 721,
        "name": "亭湖区",
        "pid": 69,
        "sort": null
    }, {
        "id": 722,
        "name": "盐都区",
        "pid": 69,
        "sort": null
    }, {
        "id": 723,
        "name": "响水县",
        "pid": 69,
        "sort": null
    }, {
        "id": 724,
        "name": "滨海县",
        "pid": 69,
        "sort": null
    }, {
        "id": 725,
        "name": "阜宁县",
        "pid": 69,
        "sort": null
    }, {
        "id": 726,
        "name": "射阳县",
        "pid": 69,
        "sort": null
    }, {
        "id": 727,
        "name": "建湖县",
        "pid": 69,
        "sort": null
    }, {
        "id": 728,
        "name": "东台市",
        "pid": 69,
        "sort": null
    }, {
        "id": 729,
        "name": "大丰市",
        "pid": 69,
        "sort": null
    }, {
        "id": 730,
        "name": "鼓楼区",
        "pid": 70,
        "sort": null
    }, {
        "id": 731,
        "name": "云龙区",
        "pid": 70,
        "sort": null
    }, {
        "id": 732,
        "name": "九里区",
        "pid": 70,
        "sort": null
    }, {
        "id": 733,
        "name": "贾汪区",
        "pid": 70,
        "sort": null
    }, {
        "id": 734,
        "name": "泉山区",
        "pid": 70,
        "sort": null
    }, {
        "id": 735,
        "name": "丰县",
        "pid": 70,
        "sort": null
    }, {
        "id": 736,
        "name": "沛县",
        "pid": 70,
        "sort": null
    }, {
        "id": 737,
        "name": "铜山县",
        "pid": 70,
        "sort": null
    }, {
        "id": 738,
        "name": "睢宁县",
        "pid": 70,
        "sort": null
    }, {
        "id": 739,
        "name": "新沂市",
        "pid": 70,
        "sort": null
    }, {
        "id": 740,
        "name": "邳州市",
        "pid": 70,
        "sort": null
    }, {
        "id": 741,
        "name": "清河区",
        "pid": 71,
        "sort": null
    }, {
        "id": 742,
        "name": "楚州区",
        "pid": 71,
        "sort": null
    }, {
        "id": 743,
        "name": "淮阴区",
        "pid": 71,
        "sort": null
    }, {
        "id": 744,
        "name": "清浦区",
        "pid": 71,
        "sort": null
    }, {
        "id": 745,
        "name": "涟水县",
        "pid": 71,
        "sort": null
    }, {
        "id": 746,
        "name": "洪泽县",
        "pid": 71,
        "sort": null
    }, {
        "id": 747,
        "name": "盱眙县",
        "pid": 71,
        "sort": null
    }, {
        "id": 748,
        "name": "金湖县",
        "pid": 71,
        "sort": null
    }, {
        "id": 749,
        "name": "连云区",
        "pid": 72,
        "sort": null
    }, {
        "id": 750,
        "name": "新浦区",
        "pid": 72,
        "sort": null
    }, {
        "id": 751,
        "name": "海州区",
        "pid": 72,
        "sort": null
    }, {
        "id": 752,
        "name": "赣榆县",
        "pid": 72,
        "sort": null
    }, {
        "id": 753,
        "name": "东海县",
        "pid": 72,
        "sort": null
    }, {
        "id": 754,
        "name": "灌云县",
        "pid": 72,
        "sort": null
    }, {
        "id": 755,
        "name": "灌南县",
        "pid": 72,
        "sort": null
    }, {
        "id": 756,
        "name": "天宁区",
        "pid": 73,
        "sort": null
    }, {
        "id": 757,
        "name": "钟楼区",
        "pid": 73,
        "sort": null
    }, {
        "id": 758,
        "name": "戚墅堰区",
        "pid": 73,
        "sort": null
    }, {
        "id": 759,
        "name": "新北区",
        "pid": 73,
        "sort": null
    }, {
        "id": 760,
        "name": "武进区",
        "pid": 73,
        "sort": null
    }, {
        "id": 761,
        "name": "溧阳市",
        "pid": 73,
        "sort": null
    }, {
        "id": 762,
        "name": "金坛市",
        "pid": 73,
        "sort": null
    }, {
        "id": 763,
        "name": "海陵区",
        "pid": 74,
        "sort": null
    }, {
        "id": 764,
        "name": "高港区",
        "pid": 74,
        "sort": null
    }, {
        "id": 765,
        "name": "兴化市",
        "pid": 74,
        "sort": null
    }, {
        "id": 766,
        "name": "靖江市",
        "pid": 74,
        "sort": null
    }, {
        "id": 767,
        "name": "泰兴市",
        "pid": 74,
        "sort": null
    }, {
        "id": 768,
        "name": "姜堰市",
        "pid": 74,
        "sort": null
    }, {
        "id": 769,
        "name": "宿城区",
        "pid": 75,
        "sort": null
    }, {
        "id": 770,
        "name": "宿豫区",
        "pid": 75,
        "sort": null
    }, {
        "id": 771,
        "name": "沭阳县",
        "pid": 75,
        "sort": null
    }, {
        "id": 772,
        "name": "泗阳县",
        "pid": 75,
        "sort": null
    }, {
        "id": 773,
        "name": "泗洪县",
        "pid": 75,
        "sort": null
    }, {
        "id": 774,
        "name": "定海区",
        "pid": 76,
        "sort": null
    }, {
        "id": 775,
        "name": "普陀区",
        "pid": 76,
        "sort": null
    }, {
        "id": 776,
        "name": "岱山县",
        "pid": 76,
        "sort": null
    }, {
        "id": 777,
        "name": "嵊泗县",
        "pid": 76,
        "sort": null
    }, {
        "id": 778,
        "name": "柯城区",
        "pid": 77,
        "sort": null
    }, {
        "id": 779,
        "name": "衢江区",
        "pid": 77,
        "sort": null
    }, {
        "id": 780,
        "name": "常山县",
        "pid": 77,
        "sort": null
    }, {
        "id": 781,
        "name": "开化县",
        "pid": 77,
        "sort": null
    }, {
        "id": 782,
        "name": "龙游县",
        "pid": 77,
        "sort": null
    }, {
        "id": 783,
        "name": "江山市",
        "pid": 77,
        "sort": null
    }, {
        "id": 784,
        "name": "上城区",
        "pid": 78,
        "sort": null
    }, {
        "id": 785,
        "name": "下城区",
        "pid": 78,
        "sort": null
    }, {
        "id": 786,
        "name": "江干区",
        "pid": 78,
        "sort": null
    }, {
        "id": 787,
        "name": "拱墅区",
        "pid": 78,
        "sort": null
    }, {
        "id": 788,
        "name": "西湖区",
        "pid": 78,
        "sort": null
    }, {
        "id": 789,
        "name": "滨江区",
        "pid": 78,
        "sort": null
    }, {
        "id": 790,
        "name": "余杭区",
        "pid": 78,
        "sort": null
    }, {
        "id": 791,
        "name": "桐庐县",
        "pid": 78,
        "sort": null
    }, {
        "id": 792,
        "name": "淳安县",
        "pid": 78,
        "sort": null
    }, {
        "id": 793,
        "name": "建德市",
        "pid": 78,
        "sort": null
    }, {
        "id": 794,
        "name": "富阳市",
        "pid": 78,
        "sort": null
    }, {
        "id": 795,
        "name": "临安市",
        "pid": 78,
        "sort": null
    }, {
        "id": 796,
        "name": "萧山区",
        "pid": 78,
        "sort": null
    }, {
        "id": 797,
        "name": "吴兴区",
        "pid": 79,
        "sort": null
    }, {
        "id": 798,
        "name": "南浔区",
        "pid": 79,
        "sort": null
    }, {
        "id": 799,
        "name": "德清县",
        "pid": 79,
        "sort": null
    }, {
        "id": 800,
        "name": "长兴县",
        "pid": 79,
        "sort": null
    }, {
        "id": 801,
        "name": "安吉县",
        "pid": 79,
        "sort": null
    }, {
        "id": 802,
        "name": " 南湖区",
        "pid": 80,
        "sort": null
    }, {
        "id": 803,
        "name": " 秀洲区",
        "pid": 80,
        "sort": null
    }, {
        "id": 804,
        "name": " 嘉善县",
        "pid": 80,
        "sort": null
    }, {
        "id": 805,
        "name": " 海盐县",
        "pid": 80,
        "sort": null
    }, {
        "id": 806,
        "name": " 海宁市",
        "pid": 80,
        "sort": null
    }, {
        "id": 807,
        "name": " 平湖市",
        "pid": 80,
        "sort": null
    }, {
        "id": 808,
        "name": " 桐乡市 ",
        "pid": 80,
        "sort": null
    }, {
        "id": 809,
        "name": "海曙区",
        "pid": 81,
        "sort": null
    }, {
        "id": 810,
        "name": "江东区",
        "pid": 81,
        "sort": null
    }, {
        "id": 811,
        "name": "江北区",
        "pid": 81,
        "sort": null
    }, {
        "id": 812,
        "name": "北仑区",
        "pid": 81,
        "sort": null
    }, {
        "id": 813,
        "name": "镇海区",
        "pid": 81,
        "sort": null
    }, {
        "id": 814,
        "name": "鄞州区",
        "pid": 81,
        "sort": null
    }, {
        "id": 815,
        "name": "象山县",
        "pid": 81,
        "sort": null
    }, {
        "id": 816,
        "name": "宁海县",
        "pid": 81,
        "sort": null
    }, {
        "id": 817,
        "name": "余姚市",
        "pid": 81,
        "sort": null
    }, {
        "id": 818,
        "name": "慈溪市",
        "pid": 81,
        "sort": null
    }, {
        "id": 819,
        "name": "奉化市",
        "pid": 81,
        "sort": null
    }, {
        "id": 820,
        "name": "越城区",
        "pid": 82,
        "sort": null
    }, {
        "id": 821,
        "name": "绍兴县",
        "pid": 82,
        "sort": null
    }, {
        "id": 822,
        "name": "新昌县",
        "pid": 82,
        "sort": null
    }, {
        "id": 823,
        "name": "诸暨市",
        "pid": 82,
        "sort": null
    }, {
        "id": 824,
        "name": "上虞市",
        "pid": 82,
        "sort": null
    }, {
        "id": 825,
        "name": "嵊州市",
        "pid": 82,
        "sort": null
    }, {
        "id": 826,
        "name": "鹿城区",
        "pid": 83,
        "sort": null
    }, {
        "id": 827,
        "name": "龙湾区",
        "pid": 83,
        "sort": null
    }, {
        "id": 828,
        "name": "瓯海区",
        "pid": 83,
        "sort": null
    }, {
        "id": 829,
        "name": "洞头县",
        "pid": 83,
        "sort": null
    }, {
        "id": 830,
        "name": "永嘉县",
        "pid": 83,
        "sort": null
    }, {
        "id": 831,
        "name": "平阳县",
        "pid": 83,
        "sort": null
    }, {
        "id": 832,
        "name": "苍南县",
        "pid": 83,
        "sort": null
    }, {
        "id": 833,
        "name": "文成县",
        "pid": 83,
        "sort": null
    }, {
        "id": 834,
        "name": "泰顺县",
        "pid": 83,
        "sort": null
    }, {
        "id": 835,
        "name": "瑞安市",
        "pid": 83,
        "sort": null
    }, {
        "id": 836,
        "name": "乐清市",
        "pid": 83,
        "sort": null
    }, {
        "id": 837,
        "name": "莲都区",
        "pid": 84,
        "sort": null
    }, {
        "id": 838,
        "name": "青田县",
        "pid": 84,
        "sort": null
    }, {
        "id": 839,
        "name": "缙云县",
        "pid": 84,
        "sort": null
    }, {
        "id": 840,
        "name": "遂昌县",
        "pid": 84,
        "sort": null
    }, {
        "id": 841,
        "name": "松阳县",
        "pid": 84,
        "sort": null
    }, {
        "id": 842,
        "name": "云和县",
        "pid": 84,
        "sort": null
    }, {
        "id": 843,
        "name": "庆元县",
        "pid": 84,
        "sort": null
    }, {
        "id": 844,
        "name": "景宁畲族自治县",
        "pid": 84,
        "sort": null
    }, {
        "id": 845,
        "name": "龙泉市",
        "pid": 84,
        "sort": null
    }, {
        "id": 846,
        "name": "婺城区",
        "pid": 85,
        "sort": null
    }, {
        "id": 847,
        "name": "金东区",
        "pid": 85,
        "sort": null
    }, {
        "id": 848,
        "name": "武义县",
        "pid": 85,
        "sort": null
    }, {
        "id": 849,
        "name": "浦江县",
        "pid": 85,
        "sort": null
    }, {
        "id": 850,
        "name": "磐安县",
        "pid": 85,
        "sort": null
    }, {
        "id": 851,
        "name": "兰溪市",
        "pid": 85,
        "sort": null
    }, {
        "id": 852,
        "name": "义乌市",
        "pid": 85,
        "sort": null
    }, {
        "id": 853,
        "name": "东阳市",
        "pid": 85,
        "sort": null
    }, {
        "id": 854,
        "name": "永康市",
        "pid": 85,
        "sort": null
    }, {
        "id": 855,
        "name": "椒江区",
        "pid": 86,
        "sort": null
    }, {
        "id": 856,
        "name": "黄岩区",
        "pid": 86,
        "sort": null
    }, {
        "id": 857,
        "name": "路桥区",
        "pid": 86,
        "sort": null
    }, {
        "id": 858,
        "name": "玉环县",
        "pid": 86,
        "sort": null
    }, {
        "id": 859,
        "name": "三门县",
        "pid": 86,
        "sort": null
    }, {
        "id": 860,
        "name": "天台县",
        "pid": 86,
        "sort": null
    }, {
        "id": 861,
        "name": "仙居县",
        "pid": 86,
        "sort": null
    }, {
        "id": 862,
        "name": "温岭市",
        "pid": 86,
        "sort": null
    }, {
        "id": 863,
        "name": "临海市",
        "pid": 86,
        "sort": null
    }, {
        "id": 864,
        "name": "瑶海区",
        "pid": 87,
        "sort": null
    }, {
        "id": 865,
        "name": "庐阳区",
        "pid": 87,
        "sort": null
    }, {
        "id": 866,
        "name": "蜀山区",
        "pid": 87,
        "sort": null
    }, {
        "id": 867,
        "name": "包河区",
        "pid": 87,
        "sort": null
    }, {
        "id": 868,
        "name": "长丰县",
        "pid": 87,
        "sort": null
    }, {
        "id": 869,
        "name": "肥东县",
        "pid": 87,
        "sort": null
    }, {
        "id": 870,
        "name": "肥西县",
        "pid": 87,
        "sort": null
    }, {
        "id": 871,
        "name": "镜湖区",
        "pid": 88,
        "sort": null
    }, {
        "id": 872,
        "name": "弋江区",
        "pid": 88,
        "sort": null
    }, {
        "id": 873,
        "name": "鸠江区",
        "pid": 88,
        "sort": null
    }, {
        "id": 874,
        "name": "三山区",
        "pid": 88,
        "sort": null
    }, {
        "id": 875,
        "name": "芜湖县",
        "pid": 88,
        "sort": null
    }, {
        "id": 876,
        "name": "繁昌县",
        "pid": 88,
        "sort": null
    }, {
        "id": 877,
        "name": "南陵县",
        "pid": 88,
        "sort": null
    }, {
        "id": 878,
        "name": "龙子湖区",
        "pid": 89,
        "sort": null
    }, {
        "id": 879,
        "name": "蚌山区",
        "pid": 89,
        "sort": null
    }, {
        "id": 880,
        "name": "禹会区",
        "pid": 89,
        "sort": null
    }, {
        "id": 881,
        "name": "淮上区",
        "pid": 89,
        "sort": null
    }, {
        "id": 882,
        "name": "怀远县",
        "pid": 89,
        "sort": null
    }, {
        "id": 883,
        "name": "五河县",
        "pid": 89,
        "sort": null
    }, {
        "id": 884,
        "name": "固镇县",
        "pid": 89,
        "sort": null
    }, {
        "id": 885,
        "name": "大通区",
        "pid": 90,
        "sort": null
    }, {
        "id": 886,
        "name": "田家庵区",
        "pid": 90,
        "sort": null
    }, {
        "id": 887,
        "name": "谢家集区",
        "pid": 90,
        "sort": null
    }, {
        "id": 888,
        "name": "八公山区",
        "pid": 90,
        "sort": null
    }, {
        "id": 889,
        "name": "潘集区",
        "pid": 90,
        "sort": null
    }, {
        "id": 890,
        "name": "凤台县",
        "pid": 90,
        "sort": null
    }, {
        "id": 891,
        "name": "金家庄区",
        "pid": 91,
        "sort": null
    }, {
        "id": 892,
        "name": "花山区",
        "pid": 91,
        "sort": null
    }, {
        "id": 893,
        "name": "雨山区",
        "pid": 91,
        "sort": null
    }, {
        "id": 894,
        "name": "当涂县",
        "pid": 91,
        "sort": null
    }, {
        "id": 895,
        "name": "杜集区",
        "pid": 92,
        "sort": null
    }, {
        "id": 896,
        "name": "相山区",
        "pid": 92,
        "sort": null
    }, {
        "id": 897,
        "name": "烈山区",
        "pid": 92,
        "sort": null
    }, {
        "id": 898,
        "name": "濉溪县 ",
        "pid": 92,
        "sort": null
    }, {
        "id": 899,
        "name": "铜官山区",
        "pid": 93,
        "sort": null
    }, {
        "id": 900,
        "name": "狮子山区",
        "pid": 93,
        "sort": null
    }, {
        "id": 901,
        "name": "铜陵县",
        "pid": 93,
        "sort": null
    }, {
        "id": 902,
        "name": "迎江区",
        "pid": 94,
        "sort": null
    }, {
        "id": 903,
        "name": "大观区",
        "pid": 94,
        "sort": null
    }, {
        "id": 904,
        "name": "宜秀区",
        "pid": 94,
        "sort": null
    }, {
        "id": 905,
        "name": "怀宁县",
        "pid": 94,
        "sort": null
    }, {
        "id": 906,
        "name": "枞阳县",
        "pid": 94,
        "sort": null
    }, {
        "id": 907,
        "name": "潜山县",
        "pid": 94,
        "sort": null
    }, {
        "id": 908,
        "name": "太湖县",
        "pid": 94,
        "sort": null
    }, {
        "id": 909,
        "name": "宿松县",
        "pid": 94,
        "sort": null
    }, {
        "id": 910,
        "name": "望江县",
        "pid": 94,
        "sort": null
    }, {
        "id": 911,
        "name": "岳西县",
        "pid": 94,
        "sort": null
    }, {
        "id": 912,
        "name": "桐城市",
        "pid": 94,
        "sort": null
    }, {
        "id": 913,
        "name": "屯溪区",
        "pid": 95,
        "sort": null
    }, {
        "id": 914,
        "name": "黄山区",
        "pid": 95,
        "sort": null
    }, {
        "id": 915,
        "name": "徽州区",
        "pid": 95,
        "sort": null
    }, {
        "id": 916,
        "name": "歙县",
        "pid": 95,
        "sort": null
    }, {
        "id": 917,
        "name": "休宁县",
        "pid": 95,
        "sort": null
    }, {
        "id": 918,
        "name": "黟县",
        "pid": 95,
        "sort": null
    }, {
        "id": 919,
        "name": "祁门县",
        "pid": 95,
        "sort": null
    }, {
        "id": 920,
        "name": "琅琊区",
        "pid": 96,
        "sort": null
    }, {
        "id": 921,
        "name": "南谯区",
        "pid": 96,
        "sort": null
    }, {
        "id": 922,
        "name": "来安县",
        "pid": 96,
        "sort": null
    }, {
        "id": 923,
        "name": "全椒县",
        "pid": 96,
        "sort": null
    }, {
        "id": 924,
        "name": "定远县",
        "pid": 96,
        "sort": null
    }, {
        "id": 925,
        "name": "凤阳县",
        "pid": 96,
        "sort": null
    }, {
        "id": 926,
        "name": "天长市",
        "pid": 96,
        "sort": null
    }, {
        "id": 927,
        "name": "明光市",
        "pid": 96,
        "sort": null
    }, {
        "id": 928,
        "name": "颍州区",
        "pid": 97,
        "sort": null
    }, {
        "id": 929,
        "name": "颍东区",
        "pid": 97,
        "sort": null
    }, {
        "id": 930,
        "name": "颍泉区",
        "pid": 97,
        "sort": null
    }, {
        "id": 931,
        "name": "临泉县",
        "pid": 97,
        "sort": null
    }, {
        "id": 932,
        "name": "太和县",
        "pid": 97,
        "sort": null
    }, {
        "id": 933,
        "name": "阜南县",
        "pid": 97,
        "sort": null
    }, {
        "id": 934,
        "name": "颍上县",
        "pid": 97,
        "sort": null
    }, {
        "id": 935,
        "name": "界首市",
        "pid": 97,
        "sort": null
    }, {
        "id": 936,
        "name": "埇桥区",
        "pid": 98,
        "sort": null
    }, {
        "id": 937,
        "name": "砀山县",
        "pid": 98,
        "sort": null
    }, {
        "id": 938,
        "name": "萧县",
        "pid": 98,
        "sort": null
    }, {
        "id": 939,
        "name": "灵璧县",
        "pid": 98,
        "sort": null
    }, {
        "id": 940,
        "name": "泗县 ",
        "pid": 98,
        "sort": null
    }, {
        "id": 941,
        "name": "居巢区",
        "pid": 99,
        "sort": null
    }, {
        "id": 942,
        "name": "庐江县",
        "pid": 99,
        "sort": null
    }, {
        "id": 943,
        "name": "无为县",
        "pid": 99,
        "sort": null
    }, {
        "id": 944,
        "name": "含山县",
        "pid": 99,
        "sort": null
    }, {
        "id": 945,
        "name": "和县 ",
        "pid": 99,
        "sort": null
    }, {
        "id": 946,
        "name": "金安区",
        "pid": 100,
        "sort": null
    }, {
        "id": 947,
        "name": "裕安区",
        "pid": 100,
        "sort": null
    }, {
        "id": 948,
        "name": "寿县",
        "pid": 100,
        "sort": null
    }, {
        "id": 949,
        "name": "霍邱县",
        "pid": 100,
        "sort": null
    }, {
        "id": 950,
        "name": "舒城县",
        "pid": 100,
        "sort": null
    }, {
        "id": 951,
        "name": "金寨县",
        "pid": 100,
        "sort": null
    }, {
        "id": 952,
        "name": "霍山县",
        "pid": 100,
        "sort": null
    }, {
        "id": 953,
        "name": "谯城区",
        "pid": 101,
        "sort": null
    }, {
        "id": 954,
        "name": "涡阳县",
        "pid": 101,
        "sort": null
    }, {
        "id": 955,
        "name": "蒙城县",
        "pid": 101,
        "sort": null
    }, {
        "id": 956,
        "name": "利辛县",
        "pid": 101,
        "sort": null
    }, {
        "id": 957,
        "name": "贵池区",
        "pid": 102,
        "sort": null
    }, {
        "id": 958,
        "name": "东至县",
        "pid": 102,
        "sort": null
    }, {
        "id": 959,
        "name": "石台县",
        "pid": 102,
        "sort": null
    }, {
        "id": 960,
        "name": "青阳县",
        "pid": 102,
        "sort": null
    }, {
        "id": 961,
        "name": "宣州区",
        "pid": 103,
        "sort": null
    }, {
        "id": 962,
        "name": "郎溪县",
        "pid": 103,
        "sort": null
    }, {
        "id": 963,
        "name": "广德县",
        "pid": 103,
        "sort": null
    }, {
        "id": 964,
        "name": "泾县",
        "pid": 103,
        "sort": null
    }, {
        "id": 965,
        "name": "绩溪县",
        "pid": 103,
        "sort": null
    }, {
        "id": 966,
        "name": "旌德县",
        "pid": 103,
        "sort": null
    }, {
        "id": 967,
        "name": "宁国市",
        "pid": 103,
        "sort": null
    }, {
        "id": 968,
        "name": "鼓楼区",
        "pid": 104,
        "sort": null
    }, {
        "id": 969,
        "name": "台江区",
        "pid": 104,
        "sort": null
    }, {
        "id": 970,
        "name": "仓山区",
        "pid": 104,
        "sort": null
    }, {
        "id": 971,
        "name": "马尾区",
        "pid": 104,
        "sort": null
    }, {
        "id": 972,
        "name": "晋安区",
        "pid": 104,
        "sort": null
    }, {
        "id": 973,
        "name": "闽侯县",
        "pid": 104,
        "sort": null
    }, {
        "id": 974,
        "name": "连江县",
        "pid": 104,
        "sort": null
    }, {
        "id": 975,
        "name": "罗源县",
        "pid": 104,
        "sort": null
    }, {
        "id": 976,
        "name": "闽清县",
        "pid": 104,
        "sort": null
    }, {
        "id": 977,
        "name": "永泰县",
        "pid": 104,
        "sort": null
    }, {
        "id": 978,
        "name": "平潭县",
        "pid": 104,
        "sort": null
    }, {
        "id": 979,
        "name": "福清市",
        "pid": 104,
        "sort": null
    }, {
        "id": 980,
        "name": "长乐市",
        "pid": 104,
        "sort": null
    }, {
        "id": 981,
        "name": "思明区",
        "pid": 105,
        "sort": null
    }, {
        "id": 982,
        "name": "海沧区",
        "pid": 105,
        "sort": null
    }, {
        "id": 983,
        "name": "湖里区",
        "pid": 105,
        "sort": null
    }, {
        "id": 984,
        "name": "集美区",
        "pid": 105,
        "sort": null
    }, {
        "id": 985,
        "name": "同安区",
        "pid": 105,
        "sort": null
    }, {
        "id": 986,
        "name": "翔安区",
        "pid": 105,
        "sort": null
    }, {
        "id": 987,
        "name": "蕉城区",
        "pid": 106,
        "sort": null
    }, {
        "id": 988,
        "name": "霞浦县",
        "pid": 106,
        "sort": null
    }, {
        "id": 989,
        "name": "古田县",
        "pid": 106,
        "sort": null
    }, {
        "id": 990,
        "name": "屏南县",
        "pid": 106,
        "sort": null
    }, {
        "id": 991,
        "name": "寿宁县",
        "pid": 106,
        "sort": null
    }, {
        "id": 992,
        "name": "周宁县",
        "pid": 106,
        "sort": null
    }, {
        "id": 993,
        "name": "柘荣县",
        "pid": 106,
        "sort": null
    }, {
        "id": 994,
        "name": "福安市",
        "pid": 106,
        "sort": null
    }, {
        "id": 995,
        "name": "福鼎市",
        "pid": 106,
        "sort": null
    }, {
        "id": 996,
        "name": "城厢区",
        "pid": 107,
        "sort": null
    }, {
        "id": 997,
        "name": "涵江区",
        "pid": 107,
        "sort": null
    }, {
        "id": 998,
        "name": "荔城区",
        "pid": 107,
        "sort": null
    }, {
        "id": 999,
        "name": "秀屿区",
        "pid": 107,
        "sort": null
    }, {
        "id": 1000,
        "name": "仙游县",
        "pid": 107,
        "sort": null
    }, {
        "id": 1001,
        "name": "鲤城区",
        "pid": 108,
        "sort": null
    }, {
        "id": 1002,
        "name": "丰泽区",
        "pid": 108,
        "sort": null
    }, {
        "id": 1003,
        "name": "洛江区",
        "pid": 108,
        "sort": null
    }, {
        "id": 1004,
        "name": "泉港区",
        "pid": 108,
        "sort": null
    }, {
        "id": 1005,
        "name": "惠安县",
        "pid": 108,
        "sort": null
    }, {
        "id": 1006,
        "name": "安溪县",
        "pid": 108,
        "sort": null
    }, {
        "id": 1007,
        "name": "永春县",
        "pid": 108,
        "sort": null
    }, {
        "id": 1008,
        "name": "德化县",
        "pid": 108,
        "sort": null
    }, {
        "id": 1009,
        "name": "石狮市",
        "pid": 108,
        "sort": null
    }, {
        "id": 1010,
        "name": "晋江市",
        "pid": 108,
        "sort": null
    }, {
        "id": 1011,
        "name": "南安市",
        "pid": 108,
        "sort": null
    }, {
        "id": 1012,
        "name": "芗城区",
        "pid": 109,
        "sort": null
    }, {
        "id": 1013,
        "name": "龙文区",
        "pid": 109,
        "sort": null
    }, {
        "id": 1014,
        "name": "云霄县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1015,
        "name": "漳浦县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1016,
        "name": "诏安县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1017,
        "name": "长泰县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1018,
        "name": "东山县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1019,
        "name": "南靖县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1020,
        "name": "平和县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1021,
        "name": "华安县",
        "pid": 109,
        "sort": null
    }, {
        "id": 1022,
        "name": "龙海市",
        "pid": 109,
        "sort": null
    }, {
        "id": 1023,
        "name": "新罗区",
        "pid": 110,
        "sort": null
    }, {
        "id": 1024,
        "name": "长汀县",
        "pid": 110,
        "sort": null
    }, {
        "id": 1025,
        "name": "永定县",
        "pid": 110,
        "sort": null
    }, {
        "id": 1026,
        "name": "上杭县",
        "pid": 110,
        "sort": null
    }, {
        "id": 1027,
        "name": "武平县",
        "pid": 110,
        "sort": null
    }, {
        "id": 1028,
        "name": "连城县",
        "pid": 110,
        "sort": null
    }, {
        "id": 1029,
        "name": "漳平市",
        "pid": 110,
        "sort": null
    }, {
        "id": 1030,
        "name": "梅列区",
        "pid": 111,
        "sort": null
    }, {
        "id": 1031,
        "name": "三元区",
        "pid": 111,
        "sort": null
    }, {
        "id": 1032,
        "name": "明溪县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1033,
        "name": "清流县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1034,
        "name": "宁化县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1035,
        "name": "大田县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1036,
        "name": "尤溪县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1037,
        "name": "沙县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1038,
        "name": "将乐县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1039,
        "name": "泰宁县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1040,
        "name": "建宁县",
        "pid": 111,
        "sort": null
    }, {
        "id": 1041,
        "name": "永安市",
        "pid": 111,
        "sort": null
    }, {
        "id": 1042,
        "name": "延平区",
        "pid": 112,
        "sort": null
    }, {
        "id": 1043,
        "name": "顺昌县",
        "pid": 112,
        "sort": null
    }, {
        "id": 1044,
        "name": "浦城县",
        "pid": 112,
        "sort": null
    }, {
        "id": 1045,
        "name": "光泽县",
        "pid": 112,
        "sort": null
    }, {
        "id": 1046,
        "name": "松溪县",
        "pid": 112,
        "sort": null
    }, {
        "id": 1047,
        "name": "政和县",
        "pid": 112,
        "sort": null
    }, {
        "id": 1048,
        "name": "邵武市",
        "pid": 112,
        "sort": null
    }, {
        "id": 1049,
        "name": "武夷山市",
        "pid": 112,
        "sort": null
    }, {
        "id": 1050,
        "name": "建瓯市",
        "pid": 112,
        "sort": null
    }, {
        "id": 1051,
        "name": "建阳市",
        "pid": 112,
        "sort": null
    }, {
        "id": 1052,
        "name": "月湖区",
        "pid": 113,
        "sort": null
    }, {
        "id": 1053,
        "name": "余江县",
        "pid": 113,
        "sort": null
    }, {
        "id": 1054,
        "name": "贵溪市",
        "pid": 113,
        "sort": null
    }, {
        "id": 1055,
        "name": "渝水区",
        "pid": 114,
        "sort": null
    }, {
        "id": 1056,
        "name": "分宜县",
        "pid": 114,
        "sort": null
    }, {
        "id": 1057,
        "name": "东湖区",
        "pid": 115,
        "sort": null
    }, {
        "id": 1058,
        "name": "西湖区",
        "pid": 115,
        "sort": null
    }, {
        "id": 1059,
        "name": "青云谱区",
        "pid": 115,
        "sort": null
    }, {
        "id": 1060,
        "name": "湾里区",
        "pid": 115,
        "sort": null
    }, {
        "id": 1061,
        "name": "青山湖区",
        "pid": 115,
        "sort": null
    }, {
        "id": 1062,
        "name": "南昌县",
        "pid": 115,
        "sort": null
    }, {
        "id": 1063,
        "name": "新建县",
        "pid": 115,
        "sort": null
    }, {
        "id": 1064,
        "name": "安义县",
        "pid": 115,
        "sort": null
    }, {
        "id": 1065,
        "name": "进贤县",
        "pid": 115,
        "sort": null
    }, {
        "id": 1066,
        "name": "庐山区",
        "pid": 116,
        "sort": null
    }, {
        "id": 1067,
        "name": "浔阳区",
        "pid": 116,
        "sort": null
    }, {
        "id": 1068,
        "name": "九江县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1069,
        "name": "武宁县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1070,
        "name": "修水县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1071,
        "name": "永修县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1072,
        "name": "德安县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1073,
        "name": "星子县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1074,
        "name": "都昌县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1075,
        "name": "湖口县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1076,
        "name": "彭泽县",
        "pid": 116,
        "sort": null
    }, {
        "id": 1077,
        "name": "瑞昌市",
        "pid": 116,
        "sort": null
    }, {
        "id": 1078,
        "name": "信州区",
        "pid": 117,
        "sort": null
    }, {
        "id": 1079,
        "name": "上饶县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1080,
        "name": "广丰县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1081,
        "name": "玉山县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1082,
        "name": "铅山县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1083,
        "name": "横峰县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1084,
        "name": "弋阳县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1085,
        "name": "余干县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1086,
        "name": "鄱阳县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1087,
        "name": "万年县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1088,
        "name": "婺源县",
        "pid": 117,
        "sort": null
    }, {
        "id": 1089,
        "name": "德兴市",
        "pid": 117,
        "sort": null
    }, {
        "id": 1090,
        "name": "临川区",
        "pid": 118,
        "sort": null
    }, {
        "id": 1091,
        "name": "南城县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1092,
        "name": "黎川县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1093,
        "name": "南丰县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1094,
        "name": "崇仁县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1095,
        "name": "乐安县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1096,
        "name": "宜黄县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1097,
        "name": "金溪县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1098,
        "name": "资溪县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1099,
        "name": "东乡县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1100,
        "name": "广昌县",
        "pid": 118,
        "sort": null
    }, {
        "id": 1101,
        "name": "袁州区",
        "pid": 119,
        "sort": null
    }, {
        "id": 1102,
        "name": "奉新县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1103,
        "name": "万载县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1104,
        "name": "上高县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1105,
        "name": "宜丰县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1106,
        "name": "靖安县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1107,
        "name": "铜鼓县",
        "pid": 119,
        "sort": null
    }, {
        "id": 1108,
        "name": "丰城市",
        "pid": 119,
        "sort": null
    }, {
        "id": 1109,
        "name": "樟树市",
        "pid": 119,
        "sort": null
    }, {
        "id": 1110,
        "name": "高安市",
        "pid": 119,
        "sort": null
    }, {
        "id": 1111,
        "name": "吉州区",
        "pid": 120,
        "sort": null
    }, {
        "id": 1112,
        "name": "青原区",
        "pid": 120,
        "sort": null
    }, {
        "id": 1113,
        "name": "吉安县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1114,
        "name": "吉水县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1115,
        "name": "峡江县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1116,
        "name": "新干县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1117,
        "name": "永丰县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1118,
        "name": "泰和县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1119,
        "name": "遂川县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1120,
        "name": "万安县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1121,
        "name": "安福县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1122,
        "name": "永新县",
        "pid": 120,
        "sort": null
    }, {
        "id": 1123,
        "name": "井冈山市",
        "pid": 120,
        "sort": null
    }, {
        "id": 1124,
        "name": "章贡区",
        "pid": 121,
        "sort": null
    }, {
        "id": 1125,
        "name": "赣县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1126,
        "name": "信丰县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1127,
        "name": "大余县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1128,
        "name": "上犹县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1129,
        "name": "崇义县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1130,
        "name": "安远县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1131,
        "name": "龙南县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1132,
        "name": "定南县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1133,
        "name": "全南县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1134,
        "name": "宁都县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1135,
        "name": "于都县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1136,
        "name": "兴国县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1137,
        "name": "会昌县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1138,
        "name": "寻乌县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1139,
        "name": "石城县",
        "pid": 121,
        "sort": null
    }, {
        "id": 1140,
        "name": "瑞金市",
        "pid": 121,
        "sort": null
    }, {
        "id": 1141,
        "name": "南康市",
        "pid": 121,
        "sort": null
    }, {
        "id": 1142,
        "name": "昌江区",
        "pid": 122,
        "sort": null
    }, {
        "id": 1143,
        "name": "珠山区",
        "pid": 122,
        "sort": null
    }, {
        "id": 1144,
        "name": "浮梁县",
        "pid": 122,
        "sort": null
    }, {
        "id": 1145,
        "name": "乐平市",
        "pid": 122,
        "sort": null
    }, {
        "id": 1146,
        "name": "安源区",
        "pid": 123,
        "sort": null
    }, {
        "id": 1147,
        "name": "湘东区",
        "pid": 123,
        "sort": null
    }, {
        "id": 1148,
        "name": "莲花县",
        "pid": 123,
        "sort": null
    }, {
        "id": 1149,
        "name": "上栗县",
        "pid": 123,
        "sort": null
    }, {
        "id": 1150,
        "name": "芦溪县",
        "pid": 123,
        "sort": null
    }, {
        "id": 1151,
        "name": "牡丹区",
        "pid": 124,
        "sort": null
    }, {
        "id": 1152,
        "name": "曹县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1153,
        "name": "单县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1154,
        "name": "成武县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1155,
        "name": "巨野县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1156,
        "name": "郓城县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1157,
        "name": "鄄城县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1158,
        "name": "定陶县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1159,
        "name": "东明县",
        "pid": 124,
        "sort": null
    }, {
        "id": 1160,
        "name": "历下区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1161,
        "name": "市中区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1162,
        "name": "槐荫区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1163,
        "name": "天桥区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1164,
        "name": "历城区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1165,
        "name": "长清区",
        "pid": 125,
        "sort": null
    }, {
        "id": 1166,
        "name": "平阴县",
        "pid": 125,
        "sort": null
    }, {
        "id": 1167,
        "name": "济阳县",
        "pid": 125,
        "sort": null
    }, {
        "id": 1168,
        "name": "商河县",
        "pid": 125,
        "sort": null
    }, {
        "id": 1169,
        "name": "章丘市",
        "pid": 125,
        "sort": null
    }, {
        "id": 1170,
        "name": "市南区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1171,
        "name": "市北区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1172,
        "name": "四方区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1173,
        "name": "黄岛区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1174,
        "name": "崂山区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1175,
        "name": "李沧区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1176,
        "name": "城阳区",
        "pid": 126,
        "sort": null
    }, {
        "id": 1177,
        "name": "胶州市",
        "pid": 126,
        "sort": null
    }, {
        "id": 1178,
        "name": "即墨市",
        "pid": 126,
        "sort": null
    }, {
        "id": 1179,
        "name": "平度市",
        "pid": 126,
        "sort": null
    }, {
        "id": 1180,
        "name": "胶南市",
        "pid": 126,
        "sort": null
    }, {
        "id": 1181,
        "name": "莱西市",
        "pid": 126,
        "sort": null
    }, {
        "id": 1182,
        "name": "淄川区",
        "pid": 127,
        "sort": null
    }, {
        "id": 1183,
        "name": "张店区",
        "pid": 127,
        "sort": null
    }, {
        "id": 1184,
        "name": "博山区",
        "pid": 127,
        "sort": null
    }, {
        "id": 1185,
        "name": "临淄区",
        "pid": 127,
        "sort": null
    }, {
        "id": 1186,
        "name": "周村区",
        "pid": 127,
        "sort": null
    }, {
        "id": 1187,
        "name": "桓台县",
        "pid": 127,
        "sort": null
    }, {
        "id": 1188,
        "name": "高青县",
        "pid": 127,
        "sort": null
    }, {
        "id": 1189,
        "name": "沂源县",
        "pid": 127,
        "sort": null
    }, {
        "id": 1190,
        "name": "德城区",
        "pid": 128,
        "sort": null
    }, {
        "id": 1191,
        "name": "陵县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1192,
        "name": "宁津县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1193,
        "name": "庆云县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1194,
        "name": "临邑县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1195,
        "name": "齐河县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1196,
        "name": "平原县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1197,
        "name": "夏津县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1198,
        "name": "武城县",
        "pid": 128,
        "sort": null
    }, {
        "id": 1199,
        "name": "乐陵市",
        "pid": 128,
        "sort": null
    }, {
        "id": 1200,
        "name": "禹城市",
        "pid": 128,
        "sort": null
    }, {
        "id": 1201,
        "name": "芝罘区",
        "pid": 129,
        "sort": null
    }, {
        "id": 1202,
        "name": "福山区",
        "pid": 129,
        "sort": null
    }, {
        "id": 1203,
        "name": "牟平区",
        "pid": 129,
        "sort": null
    }, {
        "id": 1204,
        "name": "莱山区",
        "pid": 129,
        "sort": null
    }, {
        "id": 1205,
        "name": "长岛县",
        "pid": 129,
        "sort": null
    }, {
        "id": 1206,
        "name": "龙口市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1207,
        "name": "莱阳市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1208,
        "name": "莱州市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1209,
        "name": "蓬莱市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1210,
        "name": "招远市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1211,
        "name": "栖霞市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1212,
        "name": "海阳市",
        "pid": 129,
        "sort": null
    }, {
        "id": 1213,
        "name": "潍城区",
        "pid": 130,
        "sort": null
    }, {
        "id": 1214,
        "name": "寒亭区",
        "pid": 130,
        "sort": null
    }, {
        "id": 1215,
        "name": "坊子区",
        "pid": 130,
        "sort": null
    }, {
        "id": 1216,
        "name": "奎文区",
        "pid": 130,
        "sort": null
    }, {
        "id": 1217,
        "name": "临朐县",
        "pid": 130,
        "sort": null
    }, {
        "id": 1218,
        "name": "昌乐县",
        "pid": 130,
        "sort": null
    }, {
        "id": 1219,
        "name": "青州市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1220,
        "name": "诸城市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1221,
        "name": "寿光市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1222,
        "name": "安丘市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1223,
        "name": "高密市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1224,
        "name": "昌邑市",
        "pid": 130,
        "sort": null
    }, {
        "id": 1225,
        "name": "市中区",
        "pid": 131,
        "sort": null
    }, {
        "id": 1226,
        "name": "任城区",
        "pid": 131,
        "sort": null
    }, {
        "id": 1227,
        "name": "微山县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1228,
        "name": "鱼台县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1229,
        "name": "金乡县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1230,
        "name": "嘉祥县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1231,
        "name": "汶上县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1232,
        "name": "泗水县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1233,
        "name": "梁山县",
        "pid": 131,
        "sort": null
    }, {
        "id": 1234,
        "name": "曲阜市",
        "pid": 131,
        "sort": null
    }, {
        "id": 1235,
        "name": "兖州市",
        "pid": 131,
        "sort": null
    }, {
        "id": 1236,
        "name": "邹城市",
        "pid": 131,
        "sort": null
    }, {
        "id": 1237,
        "name": "泰山区",
        "pid": 132,
        "sort": null
    }, {
        "id": 1238,
        "name": "岱岳区",
        "pid": 132,
        "sort": null
    }, {
        "id": 1239,
        "name": "宁阳县",
        "pid": 132,
        "sort": null
    }, {
        "id": 1240,
        "name": "东平县",
        "pid": 132,
        "sort": null
    }, {
        "id": 1241,
        "name": "新泰市",
        "pid": 132,
        "sort": null
    }, {
        "id": 1242,
        "name": "肥城市",
        "pid": 132,
        "sort": null
    }, {
        "id": 1243,
        "name": "兰山区",
        "pid": 133,
        "sort": null
    }, {
        "id": 1244,
        "name": "罗庄区",
        "pid": 133,
        "sort": null
    }, {
        "id": 1245,
        "name": "河东区",
        "pid": 133,
        "sort": null
    }, {
        "id": 1246,
        "name": "沂南县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1247,
        "name": "郯城县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1248,
        "name": "沂水县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1249,
        "name": "苍山县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1250,
        "name": "费县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1251,
        "name": "平邑县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1252,
        "name": "莒南县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1253,
        "name": "蒙阴县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1254,
        "name": "临沭县",
        "pid": 133,
        "sort": null
    }, {
        "id": 1255,
        "name": "滨城区",
        "pid": 134,
        "sort": null
    }, {
        "id": 1256,
        "name": "惠民县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1257,
        "name": "阳信县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1258,
        "name": "无棣县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1259,
        "name": "沾化县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1260,
        "name": "博兴县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1261,
        "name": "邹平县",
        "pid": 134,
        "sort": null
    }, {
        "id": 1262,
        "name": "东营区",
        "pid": 135,
        "sort": null
    }, {
        "id": 1263,
        "name": "河口区",
        "pid": 135,
        "sort": null
    }, {
        "id": 1264,
        "name": "垦利县",
        "pid": 135,
        "sort": null
    }, {
        "id": 1265,
        "name": "利津县",
        "pid": 135,
        "sort": null
    }, {
        "id": 1266,
        "name": "广饶县",
        "pid": 135,
        "sort": null
    }, {
        "id": 1267,
        "name": "环翠区",
        "pid": 136,
        "sort": null
    }, {
        "id": 1268,
        "name": "文登市",
        "pid": 136,
        "sort": null
    }, {
        "id": 1269,
        "name": "荣成市",
        "pid": 136,
        "sort": null
    }, {
        "id": 1270,
        "name": "乳山市",
        "pid": 136,
        "sort": null
    }, {
        "id": 1271,
        "name": "市中区",
        "pid": 137,
        "sort": null
    }, {
        "id": 1272,
        "name": "薛城区",
        "pid": 137,
        "sort": null
    }, {
        "id": 1273,
        "name": "峄城区",
        "pid": 137,
        "sort": null
    }, {
        "id": 1274,
        "name": "台儿庄区",
        "pid": 137,
        "sort": null
    }, {
        "id": 1275,
        "name": "山亭区",
        "pid": 137,
        "sort": null
    }, {
        "id": 1276,
        "name": "滕州市",
        "pid": 137,
        "sort": null
    }, {
        "id": 1277,
        "name": "东港区",
        "pid": 138,
        "sort": null
    }, {
        "id": 1278,
        "name": "岚山区",
        "pid": 138,
        "sort": null
    }, {
        "id": 1279,
        "name": "五莲县",
        "pid": 138,
        "sort": null
    }, {
        "id": 1280,
        "name": "莒县",
        "pid": 138,
        "sort": null
    }, {
        "id": 1281,
        "name": "莱城区",
        "pid": 139,
        "sort": null
    }, {
        "id": 1282,
        "name": "钢城区",
        "pid": 139,
        "sort": null
    }, {
        "id": 1283,
        "name": "东昌府区",
        "pid": 140,
        "sort": null
    }, {
        "id": 1284,
        "name": "阳谷县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1285,
        "name": "莘县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1286,
        "name": "茌平县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1287,
        "name": "东阿县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1288,
        "name": "冠县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1289,
        "name": "高唐县",
        "pid": 140,
        "sort": null
    }, {
        "id": 1290,
        "name": "临清市",
        "pid": 140,
        "sort": null
    }, {
        "id": 1291,
        "name": "梁园区",
        "pid": 141,
        "sort": null
    }, {
        "id": 1292,
        "name": "睢阳区",
        "pid": 141,
        "sort": null
    }, {
        "id": 1293,
        "name": "民权县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1294,
        "name": "睢县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1295,
        "name": "宁陵县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1296,
        "name": "柘城县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1297,
        "name": "虞城县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1298,
        "name": "夏邑县",
        "pid": 141,
        "sort": null
    }, {
        "id": 1299,
        "name": "永城市",
        "pid": 141,
        "sort": null
    }, {
        "id": 1300,
        "name": "中原区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1301,
        "name": "二七区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1302,
        "name": "管城回族区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1303,
        "name": "金水区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1304,
        "name": "上街区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1305,
        "name": "惠济区",
        "pid": 142,
        "sort": null
    }, {
        "id": 1306,
        "name": "中牟县",
        "pid": 142,
        "sort": null
    }, {
        "id": 1307,
        "name": "巩义市",
        "pid": 142,
        "sort": null
    }, {
        "id": 1308,
        "name": "荥阳市",
        "pid": 142,
        "sort": null
    }, {
        "id": 1309,
        "name": "新密市",
        "pid": 142,
        "sort": null
    }, {
        "id": 1310,
        "name": "新郑市",
        "pid": 142,
        "sort": null
    }, {
        "id": 1311,
        "name": "登封市",
        "pid": 142,
        "sort": null
    }, {
        "id": 1312,
        "name": "文峰区",
        "pid": 143,
        "sort": null
    }, {
        "id": 1313,
        "name": "北关区",
        "pid": 143,
        "sort": null
    }, {
        "id": 1314,
        "name": "殷都区",
        "pid": 143,
        "sort": null
    }, {
        "id": 1315,
        "name": "龙安区",
        "pid": 143,
        "sort": null
    }, {
        "id": 1316,
        "name": "安阳县",
        "pid": 143,
        "sort": null
    }, {
        "id": 1317,
        "name": "汤阴县",
        "pid": 143,
        "sort": null
    }, {
        "id": 1318,
        "name": "滑县",
        "pid": 143,
        "sort": null
    }, {
        "id": 1319,
        "name": "内黄县",
        "pid": 143,
        "sort": null
    }, {
        "id": 1320,
        "name": "林州市",
        "pid": 143,
        "sort": null
    }, {
        "id": 1321,
        "name": "红旗区",
        "pid": 144,
        "sort": null
    }, {
        "id": 1322,
        "name": "卫滨区",
        "pid": 144,
        "sort": null
    }, {
        "id": 1323,
        "name": "凤泉区",
        "pid": 144,
        "sort": null
    }, {
        "id": 1324,
        "name": "牧野区",
        "pid": 144,
        "sort": null
    }, {
        "id": 1325,
        "name": "新乡县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1326,
        "name": "获嘉县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1327,
        "name": "原阳县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1328,
        "name": "延津县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1329,
        "name": "封丘县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1330,
        "name": "长垣县",
        "pid": 144,
        "sort": null
    }, {
        "id": 1331,
        "name": "卫辉市",
        "pid": 144,
        "sort": null
    }, {
        "id": 1332,
        "name": "辉县市",
        "pid": 144,
        "sort": null
    }, {
        "id": 1333,
        "name": "魏都区",
        "pid": 145,
        "sort": null
    }, {
        "id": 1334,
        "name": "许昌县",
        "pid": 145,
        "sort": null
    }, {
        "id": 1335,
        "name": "鄢陵县",
        "pid": 145,
        "sort": null
    }, {
        "id": 1336,
        "name": "襄城县",
        "pid": 145,
        "sort": null
    }, {
        "id": 1337,
        "name": "禹州市",
        "pid": 145,
        "sort": null
    }, {
        "id": 1338,
        "name": "长葛市",
        "pid": 145,
        "sort": null
    }, {
        "id": 1339,
        "name": "新华区",
        "pid": 146,
        "sort": null
    }, {
        "id": 1340,
        "name": "卫东区",
        "pid": 146,
        "sort": null
    }, {
        "id": 1341,
        "name": "石龙区",
        "pid": 146,
        "sort": null
    }, {
        "id": 1342,
        "name": "湛河区",
        "pid": 146,
        "sort": null
    }, {
        "id": 1343,
        "name": "宝丰县",
        "pid": 146,
        "sort": null
    }, {
        "id": 1344,
        "name": "叶县",
        "pid": 146,
        "sort": null
    }, {
        "id": 1345,
        "name": "鲁山县",
        "pid": 146,
        "sort": null
    }, {
        "id": 1346,
        "name": "郏县",
        "pid": 146,
        "sort": null
    }, {
        "id": 1347,
        "name": "舞钢市",
        "pid": 146,
        "sort": null
    }, {
        "id": 1348,
        "name": "汝州市",
        "pid": 146,
        "sort": null
    }, {
        "id": 1349,
        "name": "浉河区",
        "pid": 147,
        "sort": null
    }, {
        "id": 1350,
        "name": "平桥区",
        "pid": 147,
        "sort": null
    }, {
        "id": 1351,
        "name": "罗山县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1352,
        "name": "光山县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1353,
        "name": "新县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1354,
        "name": "商城县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1355,
        "name": "固始县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1356,
        "name": "潢川县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1357,
        "name": "淮滨县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1358,
        "name": "息县",
        "pid": 147,
        "sort": null
    }, {
        "id": 1359,
        "name": "宛城区",
        "pid": 148,
        "sort": null
    }, {
        "id": 1360,
        "name": "卧龙区",
        "pid": 148,
        "sort": null
    }, {
        "id": 1361,
        "name": "南召县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1362,
        "name": "方城县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1363,
        "name": "西峡县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1364,
        "name": "镇平县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1365,
        "name": "内乡县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1366,
        "name": "淅川县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1367,
        "name": "社旗县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1368,
        "name": "唐河县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1369,
        "name": "新野县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1370,
        "name": "桐柏县",
        "pid": 148,
        "sort": null
    }, {
        "id": 1371,
        "name": "邓州市",
        "pid": 148,
        "sort": null
    }, {
        "id": 1372,
        "name": "龙亭区",
        "pid": 149,
        "sort": null
    }, {
        "id": 1373,
        "name": "顺河回族区",
        "pid": 149,
        "sort": null
    }, {
        "id": 1374,
        "name": "鼓楼区",
        "pid": 149,
        "sort": null
    }, {
        "id": 1375,
        "name": "禹王台区",
        "pid": 149,
        "sort": null
    }, {
        "id": 1376,
        "name": "金明区",
        "pid": 149,
        "sort": null
    }, {
        "id": 1377,
        "name": "杞县",
        "pid": 149,
        "sort": null
    }, {
        "id": 1378,
        "name": "通许县",
        "pid": 149,
        "sort": null
    }, {
        "id": 1379,
        "name": "尉氏县",
        "pid": 149,
        "sort": null
    }, {
        "id": 1380,
        "name": "开封县",
        "pid": 149,
        "sort": null
    }, {
        "id": 1381,
        "name": "兰考县",
        "pid": 149,
        "sort": null
    }, {
        "id": 1382,
        "name": "老城区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1383,
        "name": "西工区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1384,
        "name": "瀍河回族区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1385,
        "name": "涧西区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1386,
        "name": "吉利区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1387,
        "name": "洛龙区",
        "pid": 150,
        "sort": null
    }, {
        "id": 1388,
        "name": "孟津县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1389,
        "name": "新安县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1390,
        "name": "栾川县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1391,
        "name": "嵩县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1392,
        "name": "汝阳县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1393,
        "name": "宜阳县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1394,
        "name": "洛宁县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1395,
        "name": "伊川县",
        "pid": 150,
        "sort": null
    }, {
        "id": 1396,
        "name": "偃师市",
        "pid": 150,
        "sort": null
    }, {
        "id": 1397,
        "name": "解放区",
        "pid": 152,
        "sort": null
    }, {
        "id": 1398,
        "name": "中站区",
        "pid": 152,
        "sort": null
    }, {
        "id": 1399,
        "name": "马村区",
        "pid": 152,
        "sort": null
    }, {
        "id": 1400,
        "name": "山阳区",
        "pid": 152,
        "sort": null
    }, {
        "id": 1401,
        "name": "修武县",
        "pid": 152,
        "sort": null
    }, {
        "id": 1402,
        "name": "博爱县",
        "pid": 152,
        "sort": null
    }, {
        "id": 1403,
        "name": "武陟县",
        "pid": 152,
        "sort": null
    }, {
        "id": 1404,
        "name": "温县",
        "pid": 152,
        "sort": null
    }, {
        "id": 1405,
        "name": "沁阳市",
        "pid": 152,
        "sort": null
    }, {
        "id": 1406,
        "name": "孟州市",
        "pid": 152,
        "sort": null
    }, {
        "id": 1407,
        "name": "鹤山区",
        "pid": 153,
        "sort": null
    }, {
        "id": 1408,
        "name": "山城区",
        "pid": 153,
        "sort": null
    }, {
        "id": 1409,
        "name": "淇滨区",
        "pid": 153,
        "sort": null
    }, {
        "id": 1410,
        "name": "浚县",
        "pid": 153,
        "sort": null
    }, {
        "id": 1411,
        "name": "淇县",
        "pid": 153,
        "sort": null
    }, {
        "id": 1412,
        "name": "华龙区",
        "pid": 154,
        "sort": null
    }, {
        "id": 1413,
        "name": "清丰县",
        "pid": 154,
        "sort": null
    }, {
        "id": 1414,
        "name": "南乐县",
        "pid": 154,
        "sort": null
    }, {
        "id": 1415,
        "name": "范县",
        "pid": 154,
        "sort": null
    }, {
        "id": 1416,
        "name": "台前县",
        "pid": 154,
        "sort": null
    }, {
        "id": 1417,
        "name": "濮阳县",
        "pid": 154,
        "sort": null
    }, {
        "id": 1418,
        "name": "川汇区",
        "pid": 155,
        "sort": null
    }, {
        "id": 1419,
        "name": "扶沟县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1420,
        "name": "西华县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1421,
        "name": "商水县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1422,
        "name": "沈丘县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1423,
        "name": "郸城县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1424,
        "name": "淮阳县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1425,
        "name": "太康县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1426,
        "name": "鹿邑县",
        "pid": 155,
        "sort": null
    }, {
        "id": 1427,
        "name": "项城市",
        "pid": 155,
        "sort": null
    }, {
        "id": 1428,
        "name": "源汇区",
        "pid": 156,
        "sort": null
    }, {
        "id": 1429,
        "name": "郾城区",
        "pid": 156,
        "sort": null
    }, {
        "id": 1430,
        "name": "召陵区",
        "pid": 156,
        "sort": null
    }, {
        "id": 1431,
        "name": "舞阳县",
        "pid": 156,
        "sort": null
    }, {
        "id": 1432,
        "name": "临颍县",
        "pid": 156,
        "sort": null
    }, {
        "id": 1433,
        "name": "驿城区",
        "pid": 157,
        "sort": null
    }, {
        "id": 1434,
        "name": "西平县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1435,
        "name": "上蔡县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1436,
        "name": "平舆县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1437,
        "name": "正阳县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1438,
        "name": "确山县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1439,
        "name": "泌阳县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1440,
        "name": "汝南县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1441,
        "name": "遂平县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1442,
        "name": "新蔡县",
        "pid": 157,
        "sort": null
    }, {
        "id": 1443,
        "name": "湖滨区",
        "pid": 158,
        "sort": null
    }, {
        "id": 1444,
        "name": "渑池县",
        "pid": 158,
        "sort": null
    }, {
        "id": 1445,
        "name": "陕县",
        "pid": 158,
        "sort": null
    }, {
        "id": 1446,
        "name": "卢氏县",
        "pid": 158,
        "sort": null
    }, {
        "id": 1447,
        "name": "义马市",
        "pid": 158,
        "sort": null
    }, {
        "id": 1448,
        "name": "灵宝市",
        "pid": 158,
        "sort": null
    }, {
        "id": 1449,
        "name": "江岸区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1450,
        "name": "江汉区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1451,
        "name": "硚口区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1452,
        "name": "汉阳区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1453,
        "name": "武昌区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1454,
        "name": "青山区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1455,
        "name": "洪山区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1456,
        "name": "东西湖区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1457,
        "name": "汉南区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1458,
        "name": "蔡甸区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1459,
        "name": "江夏区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1460,
        "name": "黄陂区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1461,
        "name": "新洲区",
        "pid": 159,
        "sort": null
    }, {
        "id": 1462,
        "name": "襄城区",
        "pid": 160,
        "sort": null
    }, {
        "id": 1463,
        "name": "樊城区",
        "pid": 160,
        "sort": null
    }, {
        "id": 1464,
        "name": "襄阳区",
        "pid": 160,
        "sort": null
    }, {
        "id": 1465,
        "name": "南漳县",
        "pid": 160,
        "sort": null
    }, {
        "id": 1466,
        "name": "谷城县",
        "pid": 160,
        "sort": null
    }, {
        "id": 1467,
        "name": "保康县",
        "pid": 160,
        "sort": null
    }, {
        "id": 1468,
        "name": "老河口市",
        "pid": 160,
        "sort": null
    }, {
        "id": 1469,
        "name": "枣阳市",
        "pid": 160,
        "sort": null
    }, {
        "id": 1470,
        "name": "宜城市",
        "pid": 160,
        "sort": null
    }, {
        "id": 1471,
        "name": "梁子湖区",
        "pid": 161,
        "sort": null
    }, {
        "id": 1472,
        "name": "华容区",
        "pid": 161,
        "sort": null
    }, {
        "id": 1473,
        "name": "鄂城区",
        "pid": 161,
        "sort": null
    }, {
        "id": 1474,
        "name": "孝南区",
        "pid": 162,
        "sort": null
    }, {
        "id": 1475,
        "name": "孝昌县",
        "pid": 162,
        "sort": null
    }, {
        "id": 1476,
        "name": "大悟县",
        "pid": 162,
        "sort": null
    }, {
        "id": 1477,
        "name": "云梦县",
        "pid": 162,
        "sort": null
    }, {
        "id": 1478,
        "name": "应城市",
        "pid": 162,
        "sort": null
    }, {
        "id": 1479,
        "name": "安陆市",
        "pid": 162,
        "sort": null
    }, {
        "id": 1480,
        "name": "汉川市",
        "pid": 162,
        "sort": null
    }, {
        "id": 1481,
        "name": "黄州区",
        "pid": 163,
        "sort": null
    }, {
        "id": 1482,
        "name": "团风县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1483,
        "name": "红安县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1484,
        "name": "罗田县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1485,
        "name": "英山县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1486,
        "name": "浠水县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1487,
        "name": "蕲春县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1488,
        "name": "黄梅县",
        "pid": 163,
        "sort": null
    }, {
        "id": 1489,
        "name": "麻城市",
        "pid": 163,
        "sort": null
    }, {
        "id": 1490,
        "name": "武穴市",
        "pid": 163,
        "sort": null
    }, {
        "id": 1491,
        "name": "黄石港区",
        "pid": 164,
        "sort": null
    }, {
        "id": 1492,
        "name": "西塞山区",
        "pid": 164,
        "sort": null
    }, {
        "id": 1493,
        "name": "下陆区",
        "pid": 164,
        "sort": null
    }, {
        "id": 1494,
        "name": "铁山区",
        "pid": 164,
        "sort": null
    }, {
        "id": 1495,
        "name": "阳新县",
        "pid": 164,
        "sort": null
    }, {
        "id": 1496,
        "name": "大冶市",
        "pid": 164,
        "sort": null
    }, {
        "id": 1497,
        "name": "咸安区",
        "pid": 165,
        "sort": null
    }, {
        "id": 1498,
        "name": "嘉鱼县",
        "pid": 165,
        "sort": null
    }, {
        "id": 1499,
        "name": "通城县",
        "pid": 165,
        "sort": null
    }, {
        "id": 1500,
        "name": "崇阳县",
        "pid": 165,
        "sort": null
    }, {
        "id": 1501,
        "name": "通山县",
        "pid": 165,
        "sort": null
    }, {
        "id": 1502,
        "name": "赤壁市",
        "pid": 165,
        "sort": null
    }, {
        "id": 1503,
        "name": "沙市区",
        "pid": 166,
        "sort": null
    }, {
        "id": 1504,
        "name": "荆州区",
        "pid": 166,
        "sort": null
    }, {
        "id": 1505,
        "name": "公安县",
        "pid": 166,
        "sort": null
    }, {
        "id": 1506,
        "name": "监利县",
        "pid": 166,
        "sort": null
    }, {
        "id": 1507,
        "name": "江陵县",
        "pid": 166,
        "sort": null
    }, {
        "id": 1508,
        "name": "石首市",
        "pid": 166,
        "sort": null
    }, {
        "id": 1509,
        "name": "洪湖市",
        "pid": 166,
        "sort": null
    }, {
        "id": 1510,
        "name": "松滋市",
        "pid": 166,
        "sort": null
    }, {
        "id": 1511,
        "name": "西陵区",
        "pid": 167,
        "sort": null
    }, {
        "id": 1512,
        "name": "伍家岗区",
        "pid": 167,
        "sort": null
    }, {
        "id": 1513,
        "name": "点军区",
        "pid": 167,
        "sort": null
    }, {
        "id": 1514,
        "name": "猇亭区",
        "pid": 167,
        "sort": null
    }, {
        "id": 1515,
        "name": "夷陵区",
        "pid": 167,
        "sort": null
    }, {
        "id": 1516,
        "name": "远安县",
        "pid": 167,
        "sort": null
    }, {
        "id": 1517,
        "name": "兴山县",
        "pid": 167,
        "sort": null
    }, {
        "id": 1518,
        "name": "秭归县",
        "pid": 167,
        "sort": null
    }, {
        "id": 1519,
        "name": "长阳土家族自治县",
        "pid": 167,
        "sort": null
    }, {
        "id": 1520,
        "name": "五峰土家族自治县",
        "pid": 167,
        "sort": null
    }, {
        "id": 1521,
        "name": "宜都市",
        "pid": 167,
        "sort": null
    }, {
        "id": 1522,
        "name": "当阳市",
        "pid": 167,
        "sort": null
    }, {
        "id": 1523,
        "name": "枝江市",
        "pid": 167,
        "sort": null
    }, {
        "id": 1524,
        "name": "恩施市",
        "pid": 168,
        "sort": null
    }, {
        "id": 1525,
        "name": "利川市",
        "pid": 168,
        "sort": null
    }, {
        "id": 1526,
        "name": "建始县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1527,
        "name": "巴东县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1528,
        "name": "宣恩县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1529,
        "name": "咸丰县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1530,
        "name": "来凤县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1531,
        "name": "鹤峰县",
        "pid": 168,
        "sort": null
    }, {
        "id": 1532,
        "name": "茅箭区",
        "pid": 170,
        "sort": null
    }, {
        "id": 1533,
        "name": "张湾区",
        "pid": 170,
        "sort": null
    }, {
        "id": 1534,
        "name": "郧县",
        "pid": 170,
        "sort": null
    }, {
        "id": 1535,
        "name": "郧西县",
        "pid": 170,
        "sort": null
    }, {
        "id": 1536,
        "name": "竹山县",
        "pid": 170,
        "sort": null
    }, {
        "id": 1537,
        "name": "竹溪县",
        "pid": 170,
        "sort": null
    }, {
        "id": 1538,
        "name": "房县",
        "pid": 170,
        "sort": null
    }, {
        "id": 1539,
        "name": "丹江口市",
        "pid": 170,
        "sort": null
    }, {
        "id": 1540,
        "name": "曾都区",
        "pid": 171,
        "sort": null
    }, {
        "id": 1541,
        "name": "广水市",
        "pid": 171,
        "sort": null
    }, {
        "id": 1542,
        "name": "东宝区",
        "pid": 172,
        "sort": null
    }, {
        "id": 1543,
        "name": "掇刀区",
        "pid": 172,
        "sort": null
    }, {
        "id": 1544,
        "name": "京山县",
        "pid": 172,
        "sort": null
    }, {
        "id": 1545,
        "name": "沙洋县",
        "pid": 172,
        "sort": null
    }, {
        "id": 1546,
        "name": "钟祥市",
        "pid": 172,
        "sort": null
    }, {
        "id": 1547,
        "name": "岳阳楼区",
        "pid": 176,
        "sort": null
    }, {
        "id": 1548,
        "name": "云溪区",
        "pid": 176,
        "sort": null
    }, {
        "id": 1549,
        "name": "君山区",
        "pid": 176,
        "sort": null
    }, {
        "id": 1550,
        "name": "岳阳县",
        "pid": 176,
        "sort": null
    }, {
        "id": 1551,
        "name": "华容县",
        "pid": 176,
        "sort": null
    }, {
        "id": 1552,
        "name": "湘阴县",
        "pid": 176,
        "sort": null
    }, {
        "id": 1553,
        "name": "平江县",
        "pid": 176,
        "sort": null
    }, {
        "id": 1554,
        "name": "汨罗市",
        "pid": 176,
        "sort": null
    }, {
        "id": 1555,
        "name": "临湘市",
        "pid": 176,
        "sort": null
    }, {
        "id": 1556,
        "name": "芙蓉区",
        "pid": 177,
        "sort": null
    }, {
        "id": 1557,
        "name": "天心区",
        "pid": 177,
        "sort": null
    }, {
        "id": 1558,
        "name": "岳麓区",
        "pid": 177,
        "sort": null
    }, {
        "id": 1559,
        "name": "开福区",
        "pid": 177,
        "sort": null
    }, {
        "id": 1560,
        "name": "雨花区",
        "pid": 177,
        "sort": null
    }, {
        "id": 1561,
        "name": "长沙县",
        "pid": 177,
        "sort": null
    }, {
        "id": 1562,
        "name": "望城县",
        "pid": 177,
        "sort": null
    }, {
        "id": 1563,
        "name": "宁乡县",
        "pid": 177,
        "sort": null
    }, {
        "id": 1564,
        "name": "浏阳市",
        "pid": 177,
        "sort": null
    }, {
        "id": 1565,
        "name": "雨湖区",
        "pid": 178,
        "sort": null
    }, {
        "id": 1566,
        "name": "岳塘区",
        "pid": 178,
        "sort": null
    }, {
        "id": 1567,
        "name": "湘潭县",
        "pid": 178,
        "sort": null
    }, {
        "id": 1568,
        "name": "湘乡市",
        "pid": 178,
        "sort": null
    }, {
        "id": 1569,
        "name": "韶山市",
        "pid": 178,
        "sort": null
    }, {
        "id": 1570,
        "name": "荷塘区",
        "pid": 179,
        "sort": null
    }, {
        "id": 1571,
        "name": "芦淞区",
        "pid": 179,
        "sort": null
    }, {
        "id": 1572,
        "name": "石峰区",
        "pid": 179,
        "sort": null
    }, {
        "id": 1573,
        "name": "天元区",
        "pid": 179,
        "sort": null
    }, {
        "id": 1574,
        "name": "株洲县",
        "pid": 179,
        "sort": null
    }, {
        "id": 1575,
        "name": "攸县",
        "pid": 179,
        "sort": null
    }, {
        "id": 1576,
        "name": "茶陵县",
        "pid": 179,
        "sort": null
    }, {
        "id": 1577,
        "name": "炎陵县",
        "pid": 179,
        "sort": null
    }, {
        "id": 1578,
        "name": "醴陵市",
        "pid": 179,
        "sort": null
    }, {
        "id": 1579,
        "name": "珠晖区",
        "pid": 180,
        "sort": null
    }, {
        "id": 1580,
        "name": "雁峰区",
        "pid": 180,
        "sort": null
    }, {
        "id": 1581,
        "name": "石鼓区",
        "pid": 180,
        "sort": null
    }, {
        "id": 1582,
        "name": "蒸湘区",
        "pid": 180,
        "sort": null
    }, {
        "id": 1583,
        "name": "南岳区",
        "pid": 180,
        "sort": null
    }, {
        "id": 1584,
        "name": "衡阳县",
        "pid": 180,
        "sort": null
    }, {
        "id": 1585,
        "name": "衡南县",
        "pid": 180,
        "sort": null
    }, {
        "id": 1586,
        "name": "衡山县",
        "pid": 180,
        "sort": null
    }, {
        "id": 1587,
        "name": "衡东县",
        "pid": 180,
        "sort": null
    }, {
        "id": 1588,
        "name": "祁东县",
        "pid": 180,
        "sort": null
    }, {
        "id": 1589,
        "name": "耒阳市",
        "pid": 180,
        "sort": null
    }, {
        "id": 1590,
        "name": "常宁市",
        "pid": 180,
        "sort": null
    }, {
        "id": 1591,
        "name": "北湖区",
        "pid": 181,
        "sort": null
    }, {
        "id": 1592,
        "name": "苏仙区",
        "pid": 181,
        "sort": null
    }, {
        "id": 1593,
        "name": "桂阳县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1594,
        "name": "宜章县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1595,
        "name": "永兴县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1596,
        "name": "嘉禾县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1597,
        "name": "临武县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1598,
        "name": "汝城县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1599,
        "name": "桂东县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1600,
        "name": "安仁县",
        "pid": 181,
        "sort": null
    }, {
        "id": 1601,
        "name": "资兴市",
        "pid": 181,
        "sort": null
    }, {
        "id": 1602,
        "name": "武陵区",
        "pid": 182,
        "sort": null
    }, {
        "id": 1603,
        "name": "鼎城区",
        "pid": 182,
        "sort": null
    }, {
        "id": 1604,
        "name": "安乡县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1605,
        "name": "汉寿县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1606,
        "name": "澧县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1607,
        "name": "临澧县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1608,
        "name": "桃源县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1609,
        "name": "石门县",
        "pid": 182,
        "sort": null
    }, {
        "id": 1610,
        "name": "津市市",
        "pid": 182,
        "sort": null
    }, {
        "id": 1611,
        "name": "资阳区",
        "pid": 183,
        "sort": null
    }, {
        "id": 1612,
        "name": "赫山区",
        "pid": 183,
        "sort": null
    }, {
        "id": 1613,
        "name": "南县",
        "pid": 183,
        "sort": null
    }, {
        "id": 1614,
        "name": "桃江县",
        "pid": 183,
        "sort": null
    }, {
        "id": 1615,
        "name": "安化县",
        "pid": 183,
        "sort": null
    }, {
        "id": 1616,
        "name": "沅江市",
        "pid": 183,
        "sort": null
    }, {
        "id": 1617,
        "name": "娄星区",
        "pid": 184,
        "sort": null
    }, {
        "id": 1618,
        "name": "双峰县",
        "pid": 184,
        "sort": null
    }, {
        "id": 1619,
        "name": "新化县",
        "pid": 184,
        "sort": null
    }, {
        "id": 1620,
        "name": "冷水江市",
        "pid": 184,
        "sort": null
    }, {
        "id": 1621,
        "name": "涟源市",
        "pid": 184,
        "sort": null
    }, {
        "id": 1622,
        "name": "双清区",
        "pid": 185,
        "sort": null
    }, {
        "id": 1623,
        "name": "大祥区",
        "pid": 185,
        "sort": null
    }, {
        "id": 1624,
        "name": "北塔区",
        "pid": 185,
        "sort": null
    }, {
        "id": 1625,
        "name": "邵东县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1626,
        "name": "新邵县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1627,
        "name": "邵阳县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1628,
        "name": "隆回县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1629,
        "name": "洞口县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1630,
        "name": "绥宁县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1631,
        "name": "新宁县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1632,
        "name": "城步苗族自治县",
        "pid": 185,
        "sort": null
    }, {
        "id": 1633,
        "name": "武冈市",
        "pid": 185,
        "sort": null
    }, {
        "id": 1634,
        "name": "吉首市",
        "pid": 186,
        "sort": null
    }, {
        "id": 1635,
        "name": "泸溪县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1636,
        "name": "凤凰县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1637,
        "name": "花垣县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1638,
        "name": "保靖县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1639,
        "name": "古丈县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1640,
        "name": "永顺县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1641,
        "name": "龙山县",
        "pid": 186,
        "sort": null
    }, {
        "id": 1642,
        "name": "永定区",
        "pid": 187,
        "sort": null
    }, {
        "id": 1643,
        "name": "武陵源区",
        "pid": 187,
        "sort": null
    }, {
        "id": 1644,
        "name": "慈利县",
        "pid": 187,
        "sort": null
    }, {
        "id": 1645,
        "name": "桑植县",
        "pid": 187,
        "sort": null
    }, {
        "id": 1646,
        "name": "鹤城区",
        "pid": 188,
        "sort": null
    }, {
        "id": 1647,
        "name": "中方县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1648,
        "name": "沅陵县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1649,
        "name": "辰溪县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1650,
        "name": "溆浦县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1651,
        "name": "会同县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1652,
        "name": "麻阳苗族自治县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1653,
        "name": "新晃侗族自治县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1654,
        "name": "芷江侗族自治县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1655,
        "name": "靖州苗族侗族自治县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1656,
        "name": "通道侗族自治县",
        "pid": 188,
        "sort": null
    }, {
        "id": 1657,
        "name": "洪江市",
        "pid": 188,
        "sort": null
    }, {
        "id": 1658,
        "name": "零陵区",
        "pid": 189,
        "sort": null
    }, {
        "id": 1659,
        "name": "冷水滩区",
        "pid": 189,
        "sort": null
    }, {
        "id": 1660,
        "name": "祁阳县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1661,
        "name": "东安县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1662,
        "name": "双牌县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1663,
        "name": "道县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1664,
        "name": "江永县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1665,
        "name": "宁远县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1666,
        "name": "蓝山县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1667,
        "name": "新田县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1668,
        "name": "江华瑶族自治县",
        "pid": 189,
        "sort": null
    }, {
        "id": 1669,
        "name": "从化市",
        "pid": 190,
        "sort": null
    }, {
        "id": 1670,
        "name": "荔湾区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1671,
        "name": "越秀区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1672,
        "name": "海珠区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1673,
        "name": "天河区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1674,
        "name": "白云区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1675,
        "name": "花都区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1676,
        "name": "黄埔区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1677,
        "name": "萝岗区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1678,
        "name": "南沙区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1679,
        "name": "番禺区",
        "pid": 190,
        "sort": null
    }, {
        "id": 1680,
        "name": "增城市",
        "pid": 190,
        "sort": null
    }, {
        "id": 1681,
        "name": "海丰县",
        "pid": 191,
        "sort": null
    }, {
        "id": 1682,
        "name": "陆河县",
        "pid": 191,
        "sort": null
    }, {
        "id": 1683,
        "name": "陆丰市",
        "pid": 191,
        "sort": null
    }, {
        "id": 1684,
        "name": "江城区",
        "pid": 192,
        "sort": null
    }, {
        "id": 1685,
        "name": "阳西县",
        "pid": 192,
        "sort": null
    }, {
        "id": 1686,
        "name": "阳东县",
        "pid": 192,
        "sort": null
    }, {
        "id": 1687,
        "name": "阳春市",
        "pid": 192,
        "sort": null
    }, {
        "id": 1688,
        "name": "榕城区",
        "pid": 193,
        "sort": null
    }, {
        "id": 1689,
        "name": "揭东县",
        "pid": 193,
        "sort": null
    }, {
        "id": 1690,
        "name": "揭西县",
        "pid": 193,
        "sort": null
    }, {
        "id": 1691,
        "name": "惠来县",
        "pid": 193,
        "sort": null
    }, {
        "id": 1692,
        "name": "普宁市",
        "pid": 193,
        "sort": null
    }, {
        "id": 1693,
        "name": "茂南区",
        "pid": 194,
        "sort": null
    }, {
        "id": 1694,
        "name": "茂港区",
        "pid": 194,
        "sort": null
    }, {
        "id": 1695,
        "name": "电白县",
        "pid": 194,
        "sort": null
    }, {
        "id": 1696,
        "name": "高州市",
        "pid": 194,
        "sort": null
    }, {
        "id": 1697,
        "name": "化州市",
        "pid": 194,
        "sort": null
    }, {
        "id": 1698,
        "name": "信宜市",
        "pid": 194,
        "sort": null
    }, {
        "id": 1699,
        "name": "惠城区",
        "pid": 195,
        "sort": null
    }, {
        "id": 1700,
        "name": "惠阳区",
        "pid": 195,
        "sort": null
    }, {
        "id": 1701,
        "name": "博罗县",
        "pid": 195,
        "sort": null
    }, {
        "id": 1702,
        "name": "惠东县",
        "pid": 195,
        "sort": null
    }, {
        "id": 1703,
        "name": "龙门县",
        "pid": 195,
        "sort": null
    }, {
        "id": 1704,
        "name": "蓬江区",
        "pid": 196,
        "sort": null
    }, {
        "id": 1705,
        "name": "江海区",
        "pid": 196,
        "sort": null
    }, {
        "id": 1706,
        "name": "新会区",
        "pid": 196,
        "sort": null
    }, {
        "id": 1707,
        "name": "台山市",
        "pid": 196,
        "sort": null
    }, {
        "id": 1708,
        "name": "开平市",
        "pid": 196,
        "sort": null
    }, {
        "id": 1709,
        "name": "鹤山市",
        "pid": 196,
        "sort": null
    }, {
        "id": 1710,
        "name": "恩平市",
        "pid": 196,
        "sort": null
    }, {
        "id": 1711,
        "name": "武江区",
        "pid": 197,
        "sort": null
    }, {
        "id": 1712,
        "name": "浈江区",
        "pid": 197,
        "sort": null
    }, {
        "id": 1713,
        "name": "曲江区",
        "pid": 197,
        "sort": null
    }, {
        "id": 1714,
        "name": "始兴县",
        "pid": 197,
        "sort": null
    }, {
        "id": 1715,
        "name": "仁化县",
        "pid": 197,
        "sort": null
    }, {
        "id": 1716,
        "name": "翁源县",
        "pid": 197,
        "sort": null
    }, {
        "id": 1717,
        "name": "乳源瑶族自治县",
        "pid": 197,
        "sort": null
    }, {
        "id": 1718,
        "name": "新丰县",
        "pid": 197,
        "sort": null
    }, {
        "id": 1719,
        "name": "乐昌市",
        "pid": 197,
        "sort": null
    }, {
        "id": 1720,
        "name": "南雄市",
        "pid": 197,
        "sort": null
    }, {
        "id": 1721,
        "name": "梅江区",
        "pid": 198,
        "sort": null
    }, {
        "id": 1722,
        "name": "梅县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1723,
        "name": "大埔县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1724,
        "name": "丰顺县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1725,
        "name": "五华县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1726,
        "name": "平远县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1727,
        "name": "蕉岭县",
        "pid": 198,
        "sort": null
    }, {
        "id": 1728,
        "name": "兴宁市",
        "pid": 198,
        "sort": null
    }, {
        "id": 1729,
        "name": "龙湖区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1730,
        "name": "金平区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1731,
        "name": "濠江区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1732,
        "name": "潮阳区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1733,
        "name": "潮南区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1734,
        "name": "澄海区",
        "pid": 199,
        "sort": null
    }, {
        "id": 1735,
        "name": "南澳县",
        "pid": 199,
        "sort": null
    }, {
        "id": 1736,
        "name": "罗湖区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1737,
        "name": "福田区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1738,
        "name": "南山区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1739,
        "name": "宝安区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1740,
        "name": "龙岗区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1741,
        "name": "盐田区",
        "pid": 200,
        "sort": null
    }, {
        "id": 1742,
        "name": "香洲区",
        "pid": 201,
        "sort": null
    }, {
        "id": 1743,
        "name": "斗门区",
        "pid": 201,
        "sort": null
    }, {
        "id": 1744,
        "name": "金湾区",
        "pid": 201,
        "sort": null
    }, {
        "id": 1745,
        "name": "禅城区",
        "pid": 202,
        "sort": null
    }, {
        "id": 1746,
        "name": "南海区",
        "pid": 202,
        "sort": null
    }, {
        "id": 1747,
        "name": "顺德区",
        "pid": 202,
        "sort": null
    }, {
        "id": 1748,
        "name": "三水区",
        "pid": 202,
        "sort": null
    }, {
        "id": 1749,
        "name": "高明区",
        "pid": 202,
        "sort": null
    }, {
        "id": 1750,
        "name": "端州区",
        "pid": 203,
        "sort": null
    }, {
        "id": 1751,
        "name": "鼎湖区",
        "pid": 203,
        "sort": null
    }, {
        "id": 1752,
        "name": "广宁县",
        "pid": 203,
        "sort": null
    }, {
        "id": 1753,
        "name": "怀集县",
        "pid": 203,
        "sort": null
    }, {
        "id": 1754,
        "name": "封开县",
        "pid": 203,
        "sort": null
    }, {
        "id": 1755,
        "name": "德庆县",
        "pid": 203,
        "sort": null
    }, {
        "id": 1756,
        "name": "高要市",
        "pid": 203,
        "sort": null
    }, {
        "id": 1757,
        "name": "四会市",
        "pid": 203,
        "sort": null
    }, {
        "id": 1758,
        "name": "赤坎区",
        "pid": 204,
        "sort": null
    }, {
        "id": 1759,
        "name": "霞山区",
        "pid": 204,
        "sort": null
    }, {
        "id": 1760,
        "name": "坡头区",
        "pid": 204,
        "sort": null
    }, {
        "id": 1761,
        "name": "麻章区",
        "pid": 204,
        "sort": null
    }, {
        "id": 1762,
        "name": "遂溪县",
        "pid": 204,
        "sort": null
    }, {
        "id": 1763,
        "name": "徐闻县",
        "pid": 204,
        "sort": null
    }, {
        "id": 1764,
        "name": "廉江市",
        "pid": 204,
        "sort": null
    }, {
        "id": 1765,
        "name": "雷州市",
        "pid": 204,
        "sort": null
    }, {
        "id": 1766,
        "name": "吴川市",
        "pid": 204,
        "sort": null
    }, {
        "id": 1767,
        "name": "源城区",
        "pid": 206,
        "sort": null
    }, {
        "id": 1768,
        "name": "紫金县",
        "pid": 206,
        "sort": null
    }, {
        "id": 1769,
        "name": "龙川县",
        "pid": 206,
        "sort": null
    }, {
        "id": 1770,
        "name": "连平县",
        "pid": 206,
        "sort": null
    }, {
        "id": 1771,
        "name": "和平县",
        "pid": 206,
        "sort": null
    }, {
        "id": 1772,
        "name": "东源县",
        "pid": 206,
        "sort": null
    }, {
        "id": 1773,
        "name": "清城区",
        "pid": 207,
        "sort": null
    }, {
        "id": 1774,
        "name": "佛冈县",
        "pid": 207,
        "sort": null
    }, {
        "id": 1775,
        "name": "阳山县",
        "pid": 207,
        "sort": null
    }, {
        "id": 1776,
        "name": "连山壮族瑶族自治县",
        "pid": 207,
        "sort": null
    }, {
        "id": 1777,
        "name": "连南瑶族自治县",
        "pid": 207,
        "sort": null
    }, {
        "id": 1778,
        "name": "清新县",
        "pid": 207,
        "sort": null
    }, {
        "id": 1779,
        "name": "英德市",
        "pid": 207,
        "sort": null
    }, {
        "id": 1780,
        "name": "连州市",
        "pid": 207,
        "sort": null
    }, {
        "id": 1781,
        "name": "云城区",
        "pid": 208,
        "sort": null
    }, {
        "id": 1782,
        "name": "新兴县",
        "pid": 208,
        "sort": null
    }, {
        "id": 1783,
        "name": "郁南县",
        "pid": 208,
        "sort": null
    }, {
        "id": 1784,
        "name": "云安县",
        "pid": 208,
        "sort": null
    }, {
        "id": 1785,
        "name": "罗定市",
        "pid": 208,
        "sort": null
    }, {
        "id": 1786,
        "name": "湘桥区",
        "pid": 209,
        "sort": null
    }, {
        "id": 1787,
        "name": "潮安县",
        "pid": 209,
        "sort": null
    }, {
        "id": 1788,
        "name": "饶平县",
        "pid": 209,
        "sort": null
    }, {
        "id": 1789,
        "name": "城关区",
        "pid": 211,
        "sort": null
    }, {
        "id": 1790,
        "name": "七里河区",
        "pid": 211,
        "sort": null
    }, {
        "id": 1791,
        "name": "西固区",
        "pid": 211,
        "sort": null
    }, {
        "id": 1792,
        "name": "安宁区",
        "pid": 211,
        "sort": null
    }, {
        "id": 1793,
        "name": "红古区",
        "pid": 211,
        "sort": null
    }, {
        "id": 1794,
        "name": "永登县",
        "pid": 211,
        "sort": null
    }, {
        "id": 1795,
        "name": "皋兰县",
        "pid": 211,
        "sort": null
    }, {
        "id": 1796,
        "name": "榆中县",
        "pid": 211,
        "sort": null
    }, {
        "id": 1797,
        "name": "金川区",
        "pid": 212,
        "sort": null
    }, {
        "id": 1798,
        "name": "永昌县",
        "pid": 212,
        "sort": null
    }, {
        "id": 1799,
        "name": "白银区",
        "pid": 213,
        "sort": null
    }, {
        "id": 1800,
        "name": "平川区",
        "pid": 213,
        "sort": null
    }, {
        "id": 1801,
        "name": "靖远县",
        "pid": 213,
        "sort": null
    }, {
        "id": 1802,
        "name": "会宁县",
        "pid": 213,
        "sort": null
    }, {
        "id": 1803,
        "name": "景泰县",
        "pid": 213,
        "sort": null
    }, {
        "id": 1804,
        "name": "秦州区",
        "pid": 214,
        "sort": null
    }, {
        "id": 1805,
        "name": "麦积区",
        "pid": 214,
        "sort": null
    }, {
        "id": 1806,
        "name": "清水县",
        "pid": 214,
        "sort": null
    }, {
        "id": 1807,
        "name": "秦安县",
        "pid": 214,
        "sort": null
    }, {
        "id": 1808,
        "name": "甘谷县",
        "pid": 214,
        "sort": null
    }, {
        "id": 1809,
        "name": "武山县",
        "pid": 214,
        "sort": null
    }, {
        "id": 1810,
        "name": "张家川回族自治县",
        "pid": 214,
        "sort": null
    }, {
        "id": 1811,
        "name": "凉州区",
        "pid": 216,
        "sort": null
    }, {
        "id": 1812,
        "name": "民勤县",
        "pid": 216,
        "sort": null
    }, {
        "id": 1813,
        "name": "古浪县",
        "pid": 216,
        "sort": null
    }, {
        "id": 1814,
        "name": "天祝藏族自治县",
        "pid": 216,
        "sort": null
    }, {
        "id": 1815,
        "name": "甘州区",
        "pid": 217,
        "sort": null
    }, {
        "id": 1816,
        "name": "肃南裕固族自治县",
        "pid": 217,
        "sort": null
    }, {
        "id": 1817,
        "name": "民乐县",
        "pid": 217,
        "sort": null
    }, {
        "id": 1818,
        "name": "临泽县",
        "pid": 217,
        "sort": null
    }, {
        "id": 1819,
        "name": "高台县",
        "pid": 217,
        "sort": null
    }, {
        "id": 1820,
        "name": "山丹县",
        "pid": 217,
        "sort": null
    }, {
        "id": 1821,
        "name": "崆峒区",
        "pid": 218,
        "sort": null
    }, {
        "id": 1822,
        "name": "泾川县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1823,
        "name": "灵台县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1824,
        "name": "崇信县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1825,
        "name": "华亭县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1826,
        "name": "庄浪县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1827,
        "name": "静宁县",
        "pid": 218,
        "sort": null
    }, {
        "id": 1828,
        "name": "肃州区",
        "pid": 219,
        "sort": null
    }, {
        "id": 1829,
        "name": "金塔县",
        "pid": 219,
        "sort": null
    }, {
        "id": 1830,
        "name": "瓜州县",
        "pid": 219,
        "sort": null
    }, {
        "id": 1831,
        "name": "肃北蒙古族自治县",
        "pid": 219,
        "sort": null
    }, {
        "id": 1832,
        "name": "阿克塞哈萨克族自治县",
        "pid": 219,
        "sort": null
    }, {
        "id": 1833,
        "name": "玉门市",
        "pid": 219,
        "sort": null
    }, {
        "id": 1834,
        "name": "敦煌市",
        "pid": 219,
        "sort": null
    }, {
        "id": 1835,
        "name": "西峰区",
        "pid": 220,
        "sort": null
    }, {
        "id": 1836,
        "name": "庆城县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1837,
        "name": "环县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1838,
        "name": "华池县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1839,
        "name": "合水县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1840,
        "name": "正宁县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1841,
        "name": "宁县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1842,
        "name": "镇原县",
        "pid": 220,
        "sort": null
    }, {
        "id": 1843,
        "name": "安定区",
        "pid": 221,
        "sort": null
    }, {
        "id": 1844,
        "name": "通渭县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1845,
        "name": "陇西县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1846,
        "name": "渭源县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1847,
        "name": "临洮县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1848,
        "name": "漳县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1849,
        "name": "岷县",
        "pid": 221,
        "sort": null
    }, {
        "id": 1850,
        "name": "武都区",
        "pid": 222,
        "sort": null
    }, {
        "id": 1851,
        "name": "成县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1852,
        "name": "文县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1853,
        "name": "宕昌县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1854,
        "name": "康县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1855,
        "name": "西和县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1856,
        "name": "礼县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1857,
        "name": "徽县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1858,
        "name": "两当县",
        "pid": 222,
        "sort": null
    }, {
        "id": 1859,
        "name": "临夏市",
        "pid": 223,
        "sort": null
    }, {
        "id": 1860,
        "name": "临夏县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1861,
        "name": "康乐县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1862,
        "name": "永靖县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1863,
        "name": "广河县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1864,
        "name": "和政县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1865,
        "name": "东乡族自治县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1866,
        "name": "积石山保安族东乡族撒拉族自治县",
        "pid": 223,
        "sort": null
    }, {
        "id": 1867,
        "name": "合作市",
        "pid": 224,
        "sort": null
    }, {
        "id": 1868,
        "name": "临潭县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1869,
        "name": "卓尼县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1870,
        "name": "舟曲县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1871,
        "name": "迭部县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1872,
        "name": "玛曲县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1873,
        "name": "碌曲县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1874,
        "name": "夏河县",
        "pid": 224,
        "sort": null
    }, {
        "id": 1875,
        "name": "锦江区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1876,
        "name": "青羊区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1877,
        "name": "金牛区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1878,
        "name": "武侯区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1879,
        "name": "成华区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1880,
        "name": "龙泉驿区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1881,
        "name": "青白江区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1882,
        "name": "新都区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1883,
        "name": "温江区",
        "pid": 225,
        "sort": null
    }, {
        "id": 1884,
        "name": "金堂县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1885,
        "name": "双流县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1886,
        "name": "郫县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1887,
        "name": "大邑县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1888,
        "name": "蒲江县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1889,
        "name": "新津县",
        "pid": 225,
        "sort": null
    }, {
        "id": 1890,
        "name": "都江堰市",
        "pid": 225,
        "sort": null
    }, {
        "id": 1891,
        "name": "彭州市",
        "pid": 225,
        "sort": null
    }, {
        "id": 1892,
        "name": "邛崃市",
        "pid": 225,
        "sort": null
    }, {
        "id": 1893,
        "name": "崇州市",
        "pid": 225,
        "sort": null
    }, {
        "id": 1894,
        "name": "东区",
        "pid": 226,
        "sort": null
    }, {
        "id": 1895,
        "name": "西区",
        "pid": 226,
        "sort": null
    }, {
        "id": 1896,
        "name": "仁和区",
        "pid": 226,
        "sort": null
    }, {
        "id": 1897,
        "name": "米易县",
        "pid": 226,
        "sort": null
    }, {
        "id": 1898,
        "name": "盐边县",
        "pid": 226,
        "sort": null
    }, {
        "id": 1899,
        "name": "自流井区",
        "pid": 227,
        "sort": null
    }, {
        "id": 1900,
        "name": "贡井区",
        "pid": 227,
        "sort": null
    }, {
        "id": 1901,
        "name": "大安区",
        "pid": 227,
        "sort": null
    }, {
        "id": 1902,
        "name": "沿滩区",
        "pid": 227,
        "sort": null
    }, {
        "id": 1903,
        "name": "荣县",
        "pid": 227,
        "sort": null
    }, {
        "id": 1904,
        "name": "富顺县",
        "pid": 227,
        "sort": null
    }, {
        "id": 1905,
        "name": "涪城区",
        "pid": 228,
        "sort": null
    }, {
        "id": 1906,
        "name": "游仙区",
        "pid": 228,
        "sort": null
    }, {
        "id": 1907,
        "name": "三台县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1908,
        "name": "盐亭县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1909,
        "name": "安县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1910,
        "name": "梓潼县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1911,
        "name": "北川羌族自治县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1912,
        "name": "平武县",
        "pid": 228,
        "sort": null
    }, {
        "id": 1913,
        "name": "江油市",
        "pid": 228,
        "sort": null
    }, {
        "id": 1914,
        "name": "顺庆区",
        "pid": 229,
        "sort": null
    }, {
        "id": 1915,
        "name": "高坪区",
        "pid": 229,
        "sort": null
    }, {
        "id": 1916,
        "name": "嘉陵区",
        "pid": 229,
        "sort": null
    }, {
        "id": 1917,
        "name": "南部县",
        "pid": 229,
        "sort": null
    }, {
        "id": 1918,
        "name": "营山县",
        "pid": 229,
        "sort": null
    }, {
        "id": 1919,
        "name": "蓬安县",
        "pid": 229,
        "sort": null
    }, {
        "id": 1920,
        "name": "仪陇县",
        "pid": 229,
        "sort": null
    }, {
        "id": 1921,
        "name": "西充县",
        "pid": 229,
        "sort": null
    }, {
        "id": 1922,
        "name": "阆中市",
        "pid": 229,
        "sort": null
    }, {
        "id": 1923,
        "name": "通川区",
        "pid": 230,
        "sort": null
    }, {
        "id": 1924,
        "name": "达县",
        "pid": 230,
        "sort": null
    }, {
        "id": 1925,
        "name": "宣汉县",
        "pid": 230,
        "sort": null
    }, {
        "id": 1926,
        "name": "开江县",
        "pid": 230,
        "sort": null
    }, {
        "id": 1927,
        "name": "大竹县",
        "pid": 230,
        "sort": null
    }, {
        "id": 1928,
        "name": "渠县",
        "pid": 230,
        "sort": null
    }, {
        "id": 1929,
        "name": "万源市",
        "pid": 230,
        "sort": null
    }, {
        "id": 1930,
        "name": "船山区",
        "pid": 231,
        "sort": null
    }, {
        "id": 1931,
        "name": "安居区",
        "pid": 231,
        "sort": null
    }, {
        "id": 1932,
        "name": "蓬溪县",
        "pid": 231,
        "sort": null
    }, {
        "id": 1933,
        "name": "射洪县",
        "pid": 231,
        "sort": null
    }, {
        "id": 1934,
        "name": "大英县",
        "pid": 231,
        "sort": null
    }, {
        "id": 1935,
        "name": "广安区",
        "pid": 232,
        "sort": null
    }, {
        "id": 1936,
        "name": "岳池县",
        "pid": 232,
        "sort": null
    }, {
        "id": 1937,
        "name": "武胜县",
        "pid": 232,
        "sort": null
    }, {
        "id": 1938,
        "name": "邻水县",
        "pid": 232,
        "sort": null
    }, {
        "id": 1939,
        "name": "华蓥市",
        "pid": 232,
        "sort": null
    }, {
        "id": 1940,
        "name": "巴州区",
        "pid": 233,
        "sort": null
    }, {
        "id": 1941,
        "name": "通江县",
        "pid": 233,
        "sort": null
    }, {
        "id": 1942,
        "name": "南江县",
        "pid": 233,
        "sort": null
    }, {
        "id": 1943,
        "name": "平昌县",
        "pid": 233,
        "sort": null
    }, {
        "id": 1944,
        "name": "江阳区",
        "pid": 234,
        "sort": null
    }, {
        "id": 1945,
        "name": "纳溪区",
        "pid": 234,
        "sort": null
    }, {
        "id": 1946,
        "name": "龙马潭区",
        "pid": 234,
        "sort": null
    }, {
        "id": 1947,
        "name": "泸县",
        "pid": 234,
        "sort": null
    }, {
        "id": 1948,
        "name": "合江县",
        "pid": 234,
        "sort": null
    }, {
        "id": 1949,
        "name": "叙永县",
        "pid": 234,
        "sort": null
    }, {
        "id": 1950,
        "name": "古蔺县",
        "pid": 234,
        "sort": null
    }, {
        "id": 1951,
        "name": "翠屏区",
        "pid": 235,
        "sort": null
    }, {
        "id": 1952,
        "name": "宜宾县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1953,
        "name": "南溪县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1954,
        "name": "江安县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1955,
        "name": "长宁县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1956,
        "name": "高县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1957,
        "name": "珙县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1958,
        "name": "筠连县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1959,
        "name": "兴文县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1960,
        "name": "屏山县",
        "pid": 235,
        "sort": null
    }, {
        "id": 1961,
        "name": "雁江区",
        "pid": 236,
        "sort": null
    }, {
        "id": 1962,
        "name": "安岳县",
        "pid": 236,
        "sort": null
    }, {
        "id": 1963,
        "name": "乐至县",
        "pid": 236,
        "sort": null
    }, {
        "id": 1964,
        "name": "简阳市",
        "pid": 236,
        "sort": null
    }, {
        "id": 1965,
        "name": "市中区",
        "pid": 237,
        "sort": null
    }, {
        "id": 1966,
        "name": "东兴区",
        "pid": 237,
        "sort": null
    }, {
        "id": 1967,
        "name": "威远县",
        "pid": 237,
        "sort": null
    }, {
        "id": 1968,
        "name": "资中县",
        "pid": 237,
        "sort": null
    }, {
        "id": 1969,
        "name": "隆昌县",
        "pid": 237,
        "sort": null
    }, {
        "id": 1970,
        "name": "市中区",
        "pid": 238,
        "sort": null
    }, {
        "id": 1971,
        "name": "沙湾区",
        "pid": 238,
        "sort": null
    }, {
        "id": 1972,
        "name": "五通桥区",
        "pid": 238,
        "sort": null
    }, {
        "id": 1973,
        "name": "金口河区",
        "pid": 238,
        "sort": null
    }, {
        "id": 1974,
        "name": "犍为县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1975,
        "name": "井研县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1976,
        "name": "夹江县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1977,
        "name": "沐川县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1978,
        "name": "峨边彝族自治县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1979,
        "name": "马边彝族自治县",
        "pid": 238,
        "sort": null
    }, {
        "id": 1980,
        "name": "峨眉山市",
        "pid": 238,
        "sort": null
    }, {
        "id": 1981,
        "name": "东坡区",
        "pid": 239,
        "sort": null
    }, {
        "id": 1982,
        "name": "仁寿县",
        "pid": 239,
        "sort": null
    }, {
        "id": 1983,
        "name": "彭山县",
        "pid": 239,
        "sort": null
    }, {
        "id": 1984,
        "name": "洪雅县",
        "pid": 239,
        "sort": null
    }, {
        "id": 1985,
        "name": "丹棱县",
        "pid": 239,
        "sort": null
    }, {
        "id": 1986,
        "name": "青神县",
        "pid": 239,
        "sort": null
    }, {
        "id": 1987,
        "name": "西昌市",
        "pid": 240,
        "sort": null
    }, {
        "id": 1988,
        "name": "木里藏族自治县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1989,
        "name": "盐源县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1990,
        "name": "德昌县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1991,
        "name": "会理县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1992,
        "name": "会东县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1993,
        "name": "宁南县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1994,
        "name": "普格县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1995,
        "name": "布拖县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1996,
        "name": "金阳县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1997,
        "name": "昭觉县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1998,
        "name": "喜德县",
        "pid": 240,
        "sort": null
    }, {
        "id": 1999,
        "name": "冕宁县",
        "pid": 240,
        "sort": null
    }, {
        "id": 2000,
        "name": "越西县",
        "pid": 240,
        "sort": null
    }, {
        "id": 2001,
        "name": "甘洛县",
        "pid": 240,
        "sort": null
    }, {
        "id": 2002,
        "name": "美姑县",
        "pid": 240,
        "sort": null
    }, {
        "id": 2003,
        "name": "雷波县",
        "pid": 240,
        "sort": null
    }, {
        "id": 2004,
        "name": "雨城区",
        "pid": 241,
        "sort": null
    }, {
        "id": 2005,
        "name": "名山县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2006,
        "name": "荥经县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2007,
        "name": "汉源县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2008,
        "name": "石棉县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2009,
        "name": "天全县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2010,
        "name": "芦山县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2011,
        "name": "宝兴县",
        "pid": 241,
        "sort": null
    }, {
        "id": 2012,
        "name": "康定县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2013,
        "name": "泸定县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2014,
        "name": "丹巴县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2015,
        "name": "九龙县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2016,
        "name": "雅江县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2017,
        "name": "道孚县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2018,
        "name": "炉霍县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2019,
        "name": "甘孜县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2020,
        "name": "新龙县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2021,
        "name": "德格县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2022,
        "name": "白玉县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2023,
        "name": "石渠县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2024,
        "name": "色达县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2025,
        "name": "理塘县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2026,
        "name": "巴塘县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2027,
        "name": "乡城县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2028,
        "name": "稻城县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2029,
        "name": "得荣县",
        "pid": 242,
        "sort": null
    }, {
        "id": 2030,
        "name": "汶川县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2031,
        "name": "理县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2032,
        "name": "茂县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2033,
        "name": "松潘县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2034,
        "name": "九寨沟县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2035,
        "name": "金川县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2036,
        "name": "小金县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2037,
        "name": "黑水县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2038,
        "name": "马尔康县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2039,
        "name": "壤塘县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2040,
        "name": "阿坝县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2041,
        "name": "若尔盖县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2042,
        "name": "红原县",
        "pid": 243,
        "sort": null
    }, {
        "id": 2043,
        "name": "旌阳区",
        "pid": 244,
        "sort": null
    }, {
        "id": 2044,
        "name": "中江县",
        "pid": 244,
        "sort": null
    }, {
        "id": 2045,
        "name": "罗江县",
        "pid": 244,
        "sort": null
    }, {
        "id": 2046,
        "name": "广汉市",
        "pid": 244,
        "sort": null
    }, {
        "id": 2047,
        "name": "什邡市",
        "pid": 244,
        "sort": null
    }, {
        "id": 2048,
        "name": "绵竹市",
        "pid": 244,
        "sort": null
    }, {
        "id": 2049,
        "name": "市中区",
        "pid": 245,
        "sort": null
    }, {
        "id": 2050,
        "name": "元坝区",
        "pid": 245,
        "sort": null
    }, {
        "id": 2051,
        "name": "朝天区",
        "pid": 245,
        "sort": null
    }, {
        "id": 2052,
        "name": "旺苍县",
        "pid": 245,
        "sort": null
    }, {
        "id": 2053,
        "name": "青川县",
        "pid": 245,
        "sort": null
    }, {
        "id": 2054,
        "name": "剑阁县",
        "pid": 245,
        "sort": null
    }, {
        "id": 2055,
        "name": "苍溪县",
        "pid": 245,
        "sort": null
    }, {
        "id": 2056,
        "name": "南明区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2057,
        "name": "云岩区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2058,
        "name": "花溪区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2059,
        "name": "乌当区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2060,
        "name": "白云区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2061,
        "name": "小河区",
        "pid": 246,
        "sort": null
    }, {
        "id": 2062,
        "name": "开阳县",
        "pid": 246,
        "sort": null
    }, {
        "id": 2063,
        "name": "息烽县",
        "pid": 246,
        "sort": null
    }, {
        "id": 2064,
        "name": "修文县",
        "pid": 246,
        "sort": null
    }, {
        "id": 2065,
        "name": "清镇市",
        "pid": 246,
        "sort": null
    }, {
        "id": 2066,
        "name": "红花岗区",
        "pid": 247,
        "sort": null
    }, {
        "id": 2067,
        "name": "汇川区",
        "pid": 247,
        "sort": null
    }, {
        "id": 2068,
        "name": "遵义县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2069,
        "name": "桐梓县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2070,
        "name": "绥阳县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2071,
        "name": "正安县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2072,
        "name": "道真仡佬族苗族自治县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2073,
        "name": "务川仡佬族苗族自治县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2074,
        "name": "凤冈县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2075,
        "name": "湄潭县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2076,
        "name": "余庆县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2077,
        "name": "习水县",
        "pid": 247,
        "sort": null
    }, {
        "id": 2078,
        "name": "赤水市",
        "pid": 247,
        "sort": null
    }, {
        "id": 2079,
        "name": "仁怀市",
        "pid": 247,
        "sort": null
    }, {
        "id": 2080,
        "name": "西秀区",
        "pid": 248,
        "sort": null
    }, {
        "id": 2081,
        "name": "平坝县",
        "pid": 248,
        "sort": null
    }, {
        "id": 2082,
        "name": "普定县",
        "pid": 248,
        "sort": null
    }, {
        "id": 2083,
        "name": "镇宁布依族苗族自治县",
        "pid": 248,
        "sort": null
    }, {
        "id": 2084,
        "name": "关岭布依族苗族自治县",
        "pid": 248,
        "sort": null
    }, {
        "id": 2085,
        "name": "紫云苗族布依族自治县",
        "pid": 248,
        "sort": null
    }, {
        "id": 2086,
        "name": "都匀市",
        "pid": 249,
        "sort": null
    }, {
        "id": 2087,
        "name": "福泉市",
        "pid": 249,
        "sort": null
    }, {
        "id": 2088,
        "name": "荔波县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2089,
        "name": "贵定县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2090,
        "name": "瓮安县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2091,
        "name": "独山县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2092,
        "name": "平塘县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2093,
        "name": "罗甸县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2094,
        "name": "长顺县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2095,
        "name": "龙里县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2096,
        "name": "惠水县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2097,
        "name": "三都水族自治县",
        "pid": 249,
        "sort": null
    }, {
        "id": 2098,
        "name": "凯里市",
        "pid": 250,
        "sort": null
    }, {
        "id": 2099,
        "name": "黄平县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2100,
        "name": "施秉县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2101,
        "name": "三穗县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2102,
        "name": "镇远县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2103,
        "name": "岑巩县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2104,
        "name": "天柱县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2105,
        "name": "锦屏县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2106,
        "name": "剑河县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2107,
        "name": "台江县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2108,
        "name": "黎平县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2109,
        "name": "榕江县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2110,
        "name": "从江县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2111,
        "name": "雷山县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2112,
        "name": "麻江县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2113,
        "name": "丹寨县",
        "pid": 250,
        "sort": null
    }, {
        "id": 2114,
        "name": "铜仁市",
        "pid": 251,
        "sort": null
    }, {
        "id": 2115,
        "name": "江口县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2116,
        "name": "玉屏侗族自治县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2117,
        "name": "石阡县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2118,
        "name": "思南县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2119,
        "name": "印江土家族苗族自治县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2120,
        "name": "德江县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2121,
        "name": "沿河土家族自治县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2122,
        "name": "松桃苗族自治县",
        "pid": 251,
        "sort": null
    }, {
        "id": 2123,
        "name": "万山特区",
        "pid": 251,
        "sort": null
    }, {
        "id": 2124,
        "name": "毕节市",
        "pid": 252,
        "sort": null
    }, {
        "id": 2125,
        "name": "大方县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2126,
        "name": "黔西县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2127,
        "name": "金沙县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2128,
        "name": "织金县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2129,
        "name": "纳雍县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2130,
        "name": "威宁彝族回族苗族自治县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2131,
        "name": "赫章县",
        "pid": 252,
        "sort": null
    }, {
        "id": 2132,
        "name": "钟山区",
        "pid": 253,
        "sort": null
    }, {
        "id": 2133,
        "name": "六枝特区",
        "pid": 253,
        "sort": null
    }, {
        "id": 2134,
        "name": "水城县",
        "pid": 253,
        "sort": null
    }, {
        "id": 2135,
        "name": "盘县",
        "pid": 253,
        "sort": null
    }, {
        "id": 2136,
        "name": "兴义市",
        "pid": 254,
        "sort": null
    }, {
        "id": 2137,
        "name": "兴仁县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2138,
        "name": "普安县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2139,
        "name": "晴隆县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2140,
        "name": "贞丰县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2141,
        "name": "望谟县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2142,
        "name": "册亨县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2143,
        "name": "安龙县",
        "pid": 254,
        "sort": null
    }, {
        "id": 2144,
        "name": "秀英区",
        "pid": 255,
        "sort": null
    }, {
        "id": 2145,
        "name": "龙华区",
        "pid": 255,
        "sort": null
    }, {
        "id": 2146,
        "name": "琼山区",
        "pid": 255,
        "sort": null
    }, {
        "id": 2147,
        "name": "美兰区",
        "pid": 255,
        "sort": null
    }, {
        "id": 2148,
        "name": "景洪市",
        "pid": 273,
        "sort": null
    }, {
        "id": 2149,
        "name": "勐海县",
        "pid": 273,
        "sort": null
    }, {
        "id": 2150,
        "name": "勐腊县",
        "pid": 273,
        "sort": null
    }, {
        "id": 2151,
        "name": "瑞丽市",
        "pid": 274,
        "sort": null
    }, {
        "id": 2152,
        "name": "潞西市",
        "pid": 274,
        "sort": null
    }, {
        "id": 2153,
        "name": "梁河县",
        "pid": 274,
        "sort": null
    }, {
        "id": 2154,
        "name": "盈江县",
        "pid": 274,
        "sort": null
    }, {
        "id": 2155,
        "name": "陇川县",
        "pid": 274,
        "sort": null
    }, {
        "id": 2156,
        "name": "昭阳区",
        "pid": 275,
        "sort": null
    }, {
        "id": 2157,
        "name": "鲁甸县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2158,
        "name": "巧家县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2159,
        "name": "盐津县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2160,
        "name": "大关县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2161,
        "name": "永善县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2162,
        "name": "绥江县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2163,
        "name": "镇雄县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2164,
        "name": "彝良县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2165,
        "name": "威信县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2166,
        "name": "水富县",
        "pid": 275,
        "sort": null
    }, {
        "id": 2167,
        "name": "五华区",
        "pid": 276,
        "sort": null
    }, {
        "id": 2168,
        "name": "盘龙区",
        "pid": 276,
        "sort": null
    }, {
        "id": 2169,
        "name": "官渡区",
        "pid": 276,
        "sort": null
    }, {
        "id": 2170,
        "name": "西山区",
        "pid": 276,
        "sort": null
    }, {
        "id": 2171,
        "name": "东川区",
        "pid": 276,
        "sort": null
    }, {
        "id": 2172,
        "name": "呈贡县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2173,
        "name": "晋宁县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2174,
        "name": "富民县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2175,
        "name": "宜良县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2176,
        "name": "石林彝族自治县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2177,
        "name": "嵩明县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2178,
        "name": "禄劝彝族苗族自治县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2179,
        "name": "寻甸回族彝族自治县",
        "pid": 276,
        "sort": null
    }, {
        "id": 2180,
        "name": "安宁市",
        "pid": 276,
        "sort": null
    }, {
        "id": 2181,
        "name": "大理市",
        "pid": 277,
        "sort": null
    }, {
        "id": 2182,
        "name": "漾濞彝族自治县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2183,
        "name": "祥云县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2184,
        "name": "宾川县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2185,
        "name": "弥渡县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2186,
        "name": "南涧彝族自治县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2187,
        "name": "巍山彝族回族自治县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2188,
        "name": "永平县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2189,
        "name": "云龙县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2190,
        "name": "洱源县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2191,
        "name": "剑川县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2192,
        "name": "鹤庆县",
        "pid": 277,
        "sort": null
    }, {
        "id": 2193,
        "name": "个旧市",
        "pid": 278,
        "sort": null
    }, {
        "id": 2194,
        "name": "开远市",
        "pid": 278,
        "sort": null
    }, {
        "id": 2195,
        "name": "蒙自县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2196,
        "name": "屏边苗族自治县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2197,
        "name": "建水县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2198,
        "name": "石屏县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2199,
        "name": "弥勒县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2200,
        "name": "泸西县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2201,
        "name": "元阳县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2202,
        "name": "红河县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2203,
        "name": "金平苗族瑶族傣族自治县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2204,
        "name": "绿春县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2205,
        "name": "河口瑶族自治县",
        "pid": 278,
        "sort": null
    }, {
        "id": 2206,
        "name": "麒麟区",
        "pid": 279,
        "sort": null
    }, {
        "id": 2207,
        "name": "马龙县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2208,
        "name": "陆良县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2209,
        "name": "师宗县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2210,
        "name": "罗平县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2211,
        "name": "富源县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2212,
        "name": "会泽县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2213,
        "name": "沾益县",
        "pid": 279,
        "sort": null
    }, {
        "id": 2214,
        "name": "宣威市",
        "pid": 279,
        "sort": null
    }, {
        "id": 2215,
        "name": "隆阳区",
        "pid": 280,
        "sort": null
    }, {
        "id": 2216,
        "name": "施甸县",
        "pid": 280,
        "sort": null
    }, {
        "id": 2217,
        "name": "腾冲县",
        "pid": 280,
        "sort": null
    }, {
        "id": 2218,
        "name": "龙陵县",
        "pid": 280,
        "sort": null
    }, {
        "id": 2219,
        "name": "昌宁县",
        "pid": 280,
        "sort": null
    }, {
        "id": 2220,
        "name": "文山县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2221,
        "name": "砚山县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2222,
        "name": "西畴县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2223,
        "name": "麻栗坡县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2224,
        "name": "马关县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2225,
        "name": "丘北县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2226,
        "name": "广南县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2227,
        "name": "富宁县",
        "pid": 281,
        "sort": null
    }, {
        "id": 2228,
        "name": "红塔区",
        "pid": 282,
        "sort": null
    }, {
        "id": 2229,
        "name": "江川县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2230,
        "name": "澄江县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2231,
        "name": "通海县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2232,
        "name": "华宁县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2233,
        "name": "易门县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2234,
        "name": "峨山彝族自治县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2235,
        "name": "新平彝族傣族自治县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2236,
        "name": "元江哈尼族彝族傣族自治县",
        "pid": 282,
        "sort": null
    }, {
        "id": 2237,
        "name": "楚雄市",
        "pid": 283,
        "sort": null
    }, {
        "id": 2238,
        "name": "双柏县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2239,
        "name": "牟定县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2240,
        "name": "南华县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2241,
        "name": "姚安县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2242,
        "name": "大姚县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2243,
        "name": "永仁县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2244,
        "name": "元谋县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2245,
        "name": "武定县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2246,
        "name": "禄丰县",
        "pid": 283,
        "sort": null
    }, {
        "id": 2247,
        "name": "思茅区",
        "pid": 284,
        "sort": null
    }, {
        "id": 2248,
        "name": "宁洱哈尼族彝族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2249,
        "name": "墨江哈尼族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2250,
        "name": "景东彝族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2251,
        "name": "景谷傣族彝族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2252,
        "name": "镇沅彝族哈尼族拉祜族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2253,
        "name": "江城哈尼族彝族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2254,
        "name": "孟连傣族拉祜族佤族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2255,
        "name": "澜沧拉祜族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2256,
        "name": "西盟佤族自治县",
        "pid": 284,
        "sort": null
    }, {
        "id": 2257,
        "name": "临翔区",
        "pid": 285,
        "sort": null
    }, {
        "id": 2258,
        "name": "凤庆县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2259,
        "name": "云县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2260,
        "name": "永德县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2261,
        "name": "镇康县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2262,
        "name": "双江拉祜族佤族布朗族傣族自治县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2263,
        "name": "耿马傣族佤族自治县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2264,
        "name": "沧源佤族自治县",
        "pid": 285,
        "sort": null
    }, {
        "id": 2265,
        "name": "泸水县",
        "pid": 286,
        "sort": null
    }, {
        "id": 2266,
        "name": "福贡县",
        "pid": 286,
        "sort": null
    }, {
        "id": 2267,
        "name": "贡山独龙族怒族自治县",
        "pid": 286,
        "sort": null
    }, {
        "id": 2268,
        "name": "兰坪白族普米族自治县",
        "pid": 286,
        "sort": null
    }, {
        "id": 2269,
        "name": "香格里拉县",
        "pid": 287,
        "sort": null
    }, {
        "id": 2270,
        "name": "德钦县",
        "pid": 287,
        "sort": null
    }, {
        "id": 2271,
        "name": "维西傈僳族自治县",
        "pid": 287,
        "sort": null
    }, {
        "id": 2272,
        "name": "古城区",
        "pid": 288,
        "sort": null
    }, {
        "id": 2273,
        "name": "玉龙纳西族自治县",
        "pid": 288,
        "sort": null
    }, {
        "id": 2274,
        "name": "永胜县",
        "pid": 288,
        "sort": null
    }, {
        "id": 2275,
        "name": "华坪县",
        "pid": 288,
        "sort": null
    }, {
        "id": 2276,
        "name": "宁蒗彝族自治县",
        "pid": 288,
        "sort": null
    }, {
        "id": 2277,
        "name": "门源回族自治县",
        "pid": 289,
        "sort": null
    }, {
        "id": 2278,
        "name": "祁连县",
        "pid": 289,
        "sort": null
    }, {
        "id": 2279,
        "name": "海晏县",
        "pid": 289,
        "sort": null
    }, {
        "id": 2280,
        "name": "刚察县",
        "pid": 289,
        "sort": null
    }, {
        "id": 2281,
        "name": "城东区",
        "pid": 290,
        "sort": null
    }, {
        "id": 2282,
        "name": "城中区",
        "pid": 290,
        "sort": null
    }, {
        "id": 2283,
        "name": "城西区",
        "pid": 290,
        "sort": null
    }, {
        "id": 2284,
        "name": "城北区",
        "pid": 290,
        "sort": null
    }, {
        "id": 2285,
        "name": "大通回族土族自治县",
        "pid": 290,
        "sort": null
    }, {
        "id": 2286,
        "name": "湟中县",
        "pid": 290,
        "sort": null
    }, {
        "id": 2287,
        "name": "湟源县",
        "pid": 290,
        "sort": null
    }, {
        "id": 2288,
        "name": "平安县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2289,
        "name": "民和回族土族自治县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2290,
        "name": "乐都县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2291,
        "name": "互助土族自治县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2292,
        "name": "化隆回族自治县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2293,
        "name": "循化撒拉族自治县",
        "pid": 291,
        "sort": null
    }, {
        "id": 2294,
        "name": "同仁县",
        "pid": 292,
        "sort": null
    }, {
        "id": 2295,
        "name": "尖扎县",
        "pid": 292,
        "sort": null
    }, {
        "id": 2296,
        "name": "泽库县",
        "pid": 292,
        "sort": null
    }, {
        "id": 2297,
        "name": "河南蒙古族自治县",
        "pid": 292,
        "sort": null
    }, {
        "id": 2298,
        "name": "共和县",
        "pid": 293,
        "sort": null
    }, {
        "id": 2299,
        "name": "同德县",
        "pid": 293,
        "sort": null
    }, {
        "id": 2300,
        "name": "贵德县",
        "pid": 293,
        "sort": null
    }, {
        "id": 2301,
        "name": "兴海县",
        "pid": 293,
        "sort": null
    }, {
        "id": 2302,
        "name": "贵南县",
        "pid": 293,
        "sort": null
    }, {
        "id": 2303,
        "name": "玛沁县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2304,
        "name": "班玛县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2305,
        "name": "甘德县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2306,
        "name": "达日县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2307,
        "name": "久治县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2308,
        "name": "玛多县",
        "pid": 294,
        "sort": null
    }, {
        "id": 2309,
        "name": "玉树县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2310,
        "name": "杂多县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2311,
        "name": "称多县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2312,
        "name": "治多县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2313,
        "name": "囊谦县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2314,
        "name": "曲麻莱县",
        "pid": 295,
        "sort": null
    }, {
        "id": 2315,
        "name": "格尔木市",
        "pid": 296,
        "sort": null
    }, {
        "id": 2316,
        "name": "德令哈市",
        "pid": 296,
        "sort": null
    }, {
        "id": 2317,
        "name": "乌兰县",
        "pid": 296,
        "sort": null
    }, {
        "id": 2318,
        "name": "都兰县",
        "pid": 296,
        "sort": null
    }, {
        "id": 2319,
        "name": "天峻县",
        "pid": 296,
        "sort": null
    }, {
        "id": 2320,
        "name": "新城区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2321,
        "name": "碑林区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2322,
        "name": "莲湖区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2323,
        "name": "灞桥区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2324,
        "name": "未央区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2325,
        "name": "雁塔区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2326,
        "name": "阎良区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2327,
        "name": "临潼区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2328,
        "name": "长安区",
        "pid": 297,
        "sort": null
    }, {
        "id": 2329,
        "name": "蓝田县",
        "pid": 297,
        "sort": null
    }, {
        "id": 2330,
        "name": "周至县",
        "pid": 297,
        "sort": null
    }, {
        "id": 2331,
        "name": "户县",
        "pid": 297,
        "sort": null
    }, {
        "id": 2332,
        "name": "高陵县",
        "pid": 297,
        "sort": null
    }, {
        "id": 2333,
        "name": "秦都区",
        "pid": 298,
        "sort": null
    }, {
        "id": 2334,
        "name": "杨陵区",
        "pid": 298,
        "sort": null
    }, {
        "id": 2335,
        "name": "渭城区",
        "pid": 298,
        "sort": null
    }, {
        "id": 2336,
        "name": "三原县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2337,
        "name": "泾阳县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2338,
        "name": "乾县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2339,
        "name": "礼泉县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2340,
        "name": "永寿县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2341,
        "name": "彬县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2342,
        "name": "长武县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2343,
        "name": "旬邑县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2344,
        "name": "淳化县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2345,
        "name": "武功县",
        "pid": 298,
        "sort": null
    }, {
        "id": 2346,
        "name": "兴平市",
        "pid": 298,
        "sort": null
    }, {
        "id": 2347,
        "name": "宝塔区",
        "pid": 299,
        "sort": null
    }, {
        "id": 2348,
        "name": "延长县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2349,
        "name": "延川县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2350,
        "name": "子长县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2351,
        "name": "安塞县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2352,
        "name": "志丹县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2353,
        "name": "吴起县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2354,
        "name": "甘泉县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2355,
        "name": "富县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2356,
        "name": "洛川县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2357,
        "name": "宜川县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2358,
        "name": "黄龙县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2359,
        "name": "黄陵县",
        "pid": 299,
        "sort": null
    }, {
        "id": 2360,
        "name": "榆阳区",
        "pid": 300,
        "sort": null
    }, {
        "id": 2361,
        "name": "神木县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2362,
        "name": "府谷县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2363,
        "name": "横山县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2364,
        "name": "靖边县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2365,
        "name": "定边县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2366,
        "name": "绥德县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2367,
        "name": "米脂县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2368,
        "name": "佳县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2369,
        "name": "吴堡县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2370,
        "name": "清涧县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2371,
        "name": "子洲县",
        "pid": 300,
        "sort": null
    }, {
        "id": 2372,
        "name": "临渭区",
        "pid": 301,
        "sort": null
    }, {
        "id": 2373,
        "name": "华县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2374,
        "name": "潼关县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2375,
        "name": "大荔县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2376,
        "name": "合阳县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2377,
        "name": "澄城县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2378,
        "name": "蒲城县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2379,
        "name": "白水县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2380,
        "name": "富平县",
        "pid": 301,
        "sort": null
    }, {
        "id": 2381,
        "name": "韩城市",
        "pid": 301,
        "sort": null
    }, {
        "id": 2382,
        "name": "华阴市",
        "pid": 301,
        "sort": null
    }, {
        "id": 2383,
        "name": "商州区",
        "pid": 302,
        "sort": null
    }, {
        "id": 2384,
        "name": "洛南县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2385,
        "name": "丹凤县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2386,
        "name": "商南县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2387,
        "name": "山阳县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2388,
        "name": "镇安县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2389,
        "name": "柞水县",
        "pid": 302,
        "sort": null
    }, {
        "id": 2390,
        "name": "汉滨区",
        "pid": 303,
        "sort": null
    }, {
        "id": 2391,
        "name": "汉阴县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2392,
        "name": "石泉县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2393,
        "name": "宁陕县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2394,
        "name": "紫阳县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2395,
        "name": "岚皋县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2396,
        "name": "平利县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2397,
        "name": "镇坪县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2398,
        "name": "旬阳县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2399,
        "name": "白河县",
        "pid": 303,
        "sort": null
    }, {
        "id": 2400,
        "name": "汉台区",
        "pid": 304,
        "sort": null
    }, {
        "id": 2401,
        "name": "南郑县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2402,
        "name": "城固县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2403,
        "name": "洋县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2404,
        "name": "西乡县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2405,
        "name": "勉县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2406,
        "name": "宁强县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2407,
        "name": "略阳县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2408,
        "name": "镇巴县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2409,
        "name": "留坝县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2410,
        "name": "佛坪县",
        "pid": 304,
        "sort": null
    }, {
        "id": 2411,
        "name": "渭滨区",
        "pid": 305,
        "sort": null
    }, {
        "id": 2412,
        "name": "金台区",
        "pid": 305,
        "sort": null
    }, {
        "id": 2413,
        "name": "陈仓区",
        "pid": 305,
        "sort": null
    }, {
        "id": 2414,
        "name": "凤翔县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2415,
        "name": "岐山县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2416,
        "name": "扶风县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2417,
        "name": "眉县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2418,
        "name": "陇县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2419,
        "name": "千阳县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2420,
        "name": "麟游县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2421,
        "name": "凤县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2422,
        "name": "太白县",
        "pid": 305,
        "sort": null
    }, {
        "id": 2423,
        "name": "王益区",
        "pid": 306,
        "sort": null
    }, {
        "id": 2424,
        "name": "印台区",
        "pid": 306,
        "sort": null
    }, {
        "id": 2425,
        "name": "耀州区",
        "pid": 306,
        "sort": null
    }, {
        "id": 2426,
        "name": "宜君县",
        "pid": 306,
        "sort": null
    }, {
        "id": 2427,
        "name": "港口区",
        "pid": 307,
        "sort": null
    }, {
        "id": 2428,
        "name": "防城区",
        "pid": 307,
        "sort": null
    }, {
        "id": 2429,
        "name": "上思县",
        "pid": 307,
        "sort": null
    }, {
        "id": 2430,
        "name": "东兴市",
        "pid": 307,
        "sort": null
    }, {
        "id": 2431,
        "name": "兴宁区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2432,
        "name": "青秀区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2433,
        "name": "江南区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2434,
        "name": "西乡塘区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2435,
        "name": "良庆区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2436,
        "name": "邕宁区",
        "pid": 308,
        "sort": null
    }, {
        "id": 2437,
        "name": "武鸣县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2438,
        "name": "隆安县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2439,
        "name": "马山县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2440,
        "name": "上林县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2441,
        "name": "宾阳县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2442,
        "name": "横县",
        "pid": 308,
        "sort": null
    }, {
        "id": 2443,
        "name": "江洲区",
        "pid": 309,
        "sort": null
    }, {
        "id": 2444,
        "name": "扶绥县",
        "pid": 309,
        "sort": null
    }, {
        "id": 2445,
        "name": "宁明县",
        "pid": 309,
        "sort": null
    }, {
        "id": 2446,
        "name": "龙州县",
        "pid": 309,
        "sort": null
    }, {
        "id": 2447,
        "name": "大新县",
        "pid": 309,
        "sort": null
    }, {
        "id": 2448,
        "name": "天等县",
        "pid": 309,
        "sort": null
    }, {
        "id": 2449,
        "name": "凭祥市",
        "pid": 309,
        "sort": null
    }, {
        "id": 2450,
        "name": "兴宾区",
        "pid": 310,
        "sort": null
    }, {
        "id": 2451,
        "name": "忻城县",
        "pid": 310,
        "sort": null
    }, {
        "id": 2452,
        "name": "象州县",
        "pid": 310,
        "sort": null
    }, {
        "id": 2453,
        "name": "武宣县",
        "pid": 310,
        "sort": null
    }, {
        "id": 2454,
        "name": "金秀瑶族自治县",
        "pid": 310,
        "sort": null
    }, {
        "id": 2455,
        "name": "合山市",
        "pid": 310,
        "sort": null
    }, {
        "id": 2456,
        "name": "城中区",
        "pid": 311,
        "sort": null
    }, {
        "id": 2457,
        "name": "鱼峰区",
        "pid": 311,
        "sort": null
    }, {
        "id": 2458,
        "name": "柳南区",
        "pid": 311,
        "sort": null
    }, {
        "id": 2459,
        "name": "柳北区",
        "pid": 311,
        "sort": null
    }, {
        "id": 2460,
        "name": "柳江县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2461,
        "name": "柳城县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2462,
        "name": "鹿寨县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2463,
        "name": "融安县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2464,
        "name": "融水苗族自治县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2465,
        "name": "三江侗族自治县",
        "pid": 311,
        "sort": null
    }, {
        "id": 2466,
        "name": "秀峰区",
        "pid": 312,
        "sort": null
    }, {
        "id": 2467,
        "name": "叠彩区",
        "pid": 312,
        "sort": null
    }, {
        "id": 2468,
        "name": "象山区",
        "pid": 312,
        "sort": null
    }, {
        "id": 2469,
        "name": "七星区",
        "pid": 312,
        "sort": null
    }, {
        "id": 2470,
        "name": "雁山区",
        "pid": 312,
        "sort": null
    }, {
        "id": 2471,
        "name": "阳朔县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2472,
        "name": "临桂县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2473,
        "name": "灵川县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2474,
        "name": "全州县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2475,
        "name": "兴安县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2476,
        "name": "永福县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2477,
        "name": "灌阳县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2478,
        "name": "龙胜各族自治县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2479,
        "name": "资源县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2480,
        "name": "平乐县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2481,
        "name": "荔浦县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2482,
        "name": "恭城瑶族自治县",
        "pid": 312,
        "sort": null
    }, {
        "id": 2483,
        "name": "万秀区",
        "pid": 313,
        "sort": null
    }, {
        "id": 2484,
        "name": "碟山区",
        "pid": 313,
        "sort": null
    }, {
        "id": 2485,
        "name": "长洲区",
        "pid": 313,
        "sort": null
    }, {
        "id": 2486,
        "name": "苍梧县",
        "pid": 313,
        "sort": null
    }, {
        "id": 2487,
        "name": "藤县",
        "pid": 313,
        "sort": null
    }, {
        "id": 2488,
        "name": "蒙山县",
        "pid": 313,
        "sort": null
    }, {
        "id": 2489,
        "name": "岑溪市",
        "pid": 313,
        "sort": null
    }, {
        "id": 2490,
        "name": "八步区",
        "pid": 314,
        "sort": null
    }, {
        "id": 2491,
        "name": "昭平县",
        "pid": 314,
        "sort": null
    }, {
        "id": 2492,
        "name": "钟山县",
        "pid": 314,
        "sort": null
    }, {
        "id": 2493,
        "name": "富川瑶族自治县",
        "pid": 314,
        "sort": null
    }, {
        "id": 2494,
        "name": "港北区",
        "pid": 315,
        "sort": null
    }, {
        "id": 2495,
        "name": "港南区",
        "pid": 315,
        "sort": null
    }, {
        "id": 2496,
        "name": "覃塘区",
        "pid": 315,
        "sort": null
    }, {
        "id": 2497,
        "name": "平南县",
        "pid": 315,
        "sort": null
    }, {
        "id": 2498,
        "name": "桂平市",
        "pid": 315,
        "sort": null
    }, {
        "id": 2499,
        "name": "玉州区",
        "pid": 316,
        "sort": null
    }, {
        "id": 2500,
        "name": "容县",
        "pid": 316,
        "sort": null
    }, {
        "id": 2501,
        "name": "陆川县",
        "pid": 316,
        "sort": null
    }, {
        "id": 2502,
        "name": "博白县",
        "pid": 316,
        "sort": null
    }, {
        "id": 2503,
        "name": "兴业县",
        "pid": 316,
        "sort": null
    }, {
        "id": 2504,
        "name": "北流市",
        "pid": 316,
        "sort": null
    }, {
        "id": 2505,
        "name": "右江区",
        "pid": 317,
        "sort": null
    }, {
        "id": 2506,
        "name": "田阳县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2507,
        "name": "田东县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2508,
        "name": "平果县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2509,
        "name": "德保县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2510,
        "name": "靖西县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2511,
        "name": "那坡县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2512,
        "name": "凌云县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2513,
        "name": "乐业县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2514,
        "name": "田林县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2515,
        "name": "西林县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2516,
        "name": "隆林各族自治县",
        "pid": 317,
        "sort": null
    }, {
        "id": 2517,
        "name": "钦南区",
        "pid": 318,
        "sort": null
    }, {
        "id": 2518,
        "name": "钦北区",
        "pid": 318,
        "sort": null
    }, {
        "id": 2519,
        "name": "灵山县",
        "pid": 318,
        "sort": null
    }, {
        "id": 2520,
        "name": "浦北县",
        "pid": 318,
        "sort": null
    }, {
        "id": 2521,
        "name": "金城江区",
        "pid": 319,
        "sort": null
    }, {
        "id": 2522,
        "name": "南丹县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2523,
        "name": "天峨县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2524,
        "name": "凤山县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2525,
        "name": "东兰县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2526,
        "name": "罗城仫佬族自治县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2527,
        "name": "环江毛南族自治县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2528,
        "name": "巴马瑶族自治县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2529,
        "name": "都安瑶族自治县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2530,
        "name": "大化瑶族自治县",
        "pid": 319,
        "sort": null
    }, {
        "id": 2531,
        "name": "宜州市",
        "pid": 319,
        "sort": null
    }, {
        "id": 2532,
        "name": "海城区",
        "pid": 320,
        "sort": null
    }, {
        "id": 2533,
        "name": "银海区",
        "pid": 320,
        "sort": null
    }, {
        "id": 2534,
        "name": "铁山港区",
        "pid": 320,
        "sort": null
    }, {
        "id": 2535,
        "name": "合浦县",
        "pid": 320,
        "sort": null
    }, {
        "id": 2536,
        "name": "城关区",
        "pid": 321,
        "sort": null
    }, {
        "id": 2537,
        "name": "林周县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2538,
        "name": "当雄县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2539,
        "name": "尼木县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2540,
        "name": "曲水县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2541,
        "name": "堆龙德庆县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2542,
        "name": "达孜县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2543,
        "name": "墨竹工卡县",
        "pid": 321,
        "sort": null
    }, {
        "id": 2544,
        "name": "日喀则市",
        "pid": 322,
        "sort": null
    }, {
        "id": 2545,
        "name": "南木林县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2546,
        "name": "江孜县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2547,
        "name": "定日县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2548,
        "name": "萨迦县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2549,
        "name": "拉孜县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2550,
        "name": "昂仁县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2551,
        "name": "谢通门县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2552,
        "name": "白朗县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2553,
        "name": "仁布县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2554,
        "name": "康马县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2555,
        "name": "定结县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2556,
        "name": "仲巴县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2557,
        "name": "亚东县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2558,
        "name": "吉隆县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2559,
        "name": "聂拉木县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2560,
        "name": "萨嘎县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2561,
        "name": "岗巴县",
        "pid": 322,
        "sort": null
    }, {
        "id": 2562,
        "name": "乃东县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2563,
        "name": "扎囊县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2564,
        "name": "贡嘎县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2565,
        "name": "桑日县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2566,
        "name": "琼结县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2567,
        "name": "曲松县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2568,
        "name": "措美县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2569,
        "name": "洛扎县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2570,
        "name": "加查县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2571,
        "name": "隆子县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2572,
        "name": "错那县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2573,
        "name": "浪卡子县",
        "pid": 323,
        "sort": null
    }, {
        "id": 2574,
        "name": "林芝县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2575,
        "name": "工布江达县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2576,
        "name": "米林县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2577,
        "name": "墨脱县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2578,
        "name": "波密县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2579,
        "name": "察隅县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2580,
        "name": "朗县",
        "pid": 324,
        "sort": null
    }, {
        "id": 2581,
        "name": "昌都县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2582,
        "name": "江达县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2583,
        "name": "贡觉县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2584,
        "name": "类乌齐县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2585,
        "name": "丁青县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2586,
        "name": "察雅县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2587,
        "name": "八宿县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2588,
        "name": "左贡县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2589,
        "name": "芒康县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2590,
        "name": "洛隆县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2591,
        "name": "边坝县",
        "pid": 325,
        "sort": null
    }, {
        "id": 2592,
        "name": "那曲县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2593,
        "name": "嘉黎县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2594,
        "name": "比如县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2595,
        "name": "聂荣县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2596,
        "name": "安多县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2597,
        "name": "申扎县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2598,
        "name": "索县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2599,
        "name": "班戈县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2600,
        "name": "巴青县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2601,
        "name": "尼玛县",
        "pid": 326,
        "sort": null
    }, {
        "id": 2602,
        "name": "普兰县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2603,
        "name": "札达县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2604,
        "name": "噶尔县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2605,
        "name": "日土县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2606,
        "name": "革吉县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2607,
        "name": "改则县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2608,
        "name": "措勤县",
        "pid": 327,
        "sort": null
    }, {
        "id": 2609,
        "name": "兴庆区",
        "pid": 328,
        "sort": null
    }, {
        "id": 2610,
        "name": "西夏区",
        "pid": 328,
        "sort": null
    }, {
        "id": 2611,
        "name": "金凤区",
        "pid": 328,
        "sort": null
    }, {
        "id": 2612,
        "name": "永宁县",
        "pid": 328,
        "sort": null
    }, {
        "id": 2613,
        "name": "贺兰县",
        "pid": 328,
        "sort": null
    }, {
        "id": 2614,
        "name": "灵武市",
        "pid": 328,
        "sort": null
    }, {
        "id": 2615,
        "name": "大武口区",
        "pid": 329,
        "sort": null
    }, {
        "id": 2616,
        "name": "惠农区",
        "pid": 329,
        "sort": null
    }, {
        "id": 2617,
        "name": "平罗县",
        "pid": 329,
        "sort": null
    }, {
        "id": 2618,
        "name": "利通区",
        "pid": 330,
        "sort": null
    }, {
        "id": 2619,
        "name": "盐池县",
        "pid": 330,
        "sort": null
    }, {
        "id": 2620,
        "name": "同心县",
        "pid": 330,
        "sort": null
    }, {
        "id": 2621,
        "name": "青铜峡市",
        "pid": 330,
        "sort": null
    }, {
        "id": 2622,
        "name": "原州区",
        "pid": 331,
        "sort": null
    }, {
        "id": 2623,
        "name": "西吉县",
        "pid": 331,
        "sort": null
    }, {
        "id": 2624,
        "name": "隆德县",
        "pid": 331,
        "sort": null
    }, {
        "id": 2625,
        "name": "泾源县",
        "pid": 331,
        "sort": null
    }, {
        "id": 2626,
        "name": "彭阳县",
        "pid": 331,
        "sort": null
    }, {
        "id": 2627,
        "name": "沙坡头区",
        "pid": 332,
        "sort": null
    }, {
        "id": 2628,
        "name": "中宁县",
        "pid": 332,
        "sort": null
    }, {
        "id": 2629,
        "name": "海原县",
        "pid": 332,
        "sort": null
    }, {
        "id": 2630,
        "name": "塔城市",
        "pid": 333,
        "sort": null
    }, {
        "id": 2631,
        "name": "乌苏市",
        "pid": 333,
        "sort": null
    }, {
        "id": 2632,
        "name": "额敏县",
        "pid": 333,
        "sort": null
    }, {
        "id": 2633,
        "name": "沙湾县",
        "pid": 333,
        "sort": null
    }, {
        "id": 2634,
        "name": "托里县",
        "pid": 333,
        "sort": null
    }, {
        "id": 2635,
        "name": "裕民县",
        "pid": 333,
        "sort": null
    }, {
        "id": 2636,
        "name": "和布克赛尔蒙古自治县",
        "pid": 333,
        "sort": null
    }, {
        "id": 2637,
        "name": "哈密市",
        "pid": 334,
        "sort": null
    }, {
        "id": 2638,
        "name": "巴里坤哈萨克自治县",
        "pid": 334,
        "sort": null
    }, {
        "id": 2639,
        "name": "伊吾县",
        "pid": 334,
        "sort": null
    }, {
        "id": 2640,
        "name": "和田市",
        "pid": 335,
        "sort": null
    }, {
        "id": 2641,
        "name": "和田县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2642,
        "name": "墨玉县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2643,
        "name": "皮山县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2644,
        "name": "洛浦县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2645,
        "name": "策勒县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2646,
        "name": "于田县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2647,
        "name": "民丰县",
        "pid": 335,
        "sort": null
    }, {
        "id": 2648,
        "name": "阿勒泰市",
        "pid": 336,
        "sort": null
    }, {
        "id": 2649,
        "name": "布尔津县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2650,
        "name": "富蕴县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2651,
        "name": "福海县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2652,
        "name": "哈巴河县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2653,
        "name": "青河县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2654,
        "name": "吉木乃县",
        "pid": 336,
        "sort": null
    }, {
        "id": 2655,
        "name": "阿图什市",
        "pid": 337,
        "sort": null
    }, {
        "id": 2656,
        "name": "阿克陶县",
        "pid": 337,
        "sort": null
    }, {
        "id": 2657,
        "name": "阿合奇县",
        "pid": 337,
        "sort": null
    }, {
        "id": 2658,
        "name": "乌恰县",
        "pid": 337,
        "sort": null
    }, {
        "id": 2659,
        "name": "博乐市",
        "pid": 338,
        "sort": null
    }, {
        "id": 2660,
        "name": "精河县",
        "pid": 338,
        "sort": null
    }, {
        "id": 2661,
        "name": "温泉县",
        "pid": 338,
        "sort": null
    }, {
        "id": 2662,
        "name": "独山子区",
        "pid": 339,
        "sort": null
    }, {
        "id": 2663,
        "name": "克拉玛依区",
        "pid": 339,
        "sort": null
    }, {
        "id": 2664,
        "name": "白碱滩区",
        "pid": 339,
        "sort": null
    }, {
        "id": 2665,
        "name": "乌尔禾区",
        "pid": 339,
        "sort": null
    }, {
        "id": 2666,
        "name": "天山区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2667,
        "name": "沙依巴克区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2668,
        "name": "新市区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2669,
        "name": "水磨沟区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2670,
        "name": "头屯河区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2671,
        "name": "达坂城区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2672,
        "name": "米东区",
        "pid": 340,
        "sort": null
    }, {
        "id": 2673,
        "name": "乌鲁木齐县",
        "pid": 340,
        "sort": null
    }, {
        "id": 2674,
        "name": "昌吉市",
        "pid": 342,
        "sort": null
    }, {
        "id": 2675,
        "name": "阜康市",
        "pid": 342,
        "sort": null
    }, {
        "id": 2676,
        "name": "呼图壁县",
        "pid": 342,
        "sort": null
    }, {
        "id": 2677,
        "name": "玛纳斯县",
        "pid": 342,
        "sort": null
    }, {
        "id": 2678,
        "name": "奇台县",
        "pid": 342,
        "sort": null
    }, {
        "id": 2679,
        "name": "吉木萨尔县",
        "pid": 342,
        "sort": null
    }, {
        "id": 2680,
        "name": "木垒哈萨克自治县",
        "pid": 342,
        "sort": null
    }, {
        "id": 2681,
        "name": "吐鲁番市",
        "pid": 344,
        "sort": null
    }, {
        "id": 2682,
        "name": "鄯善县",
        "pid": 344,
        "sort": null
    }, {
        "id": 2683,
        "name": "托克逊县",
        "pid": 344,
        "sort": null
    }, {
        "id": 2684,
        "name": "库尔勒市",
        "pid": 345,
        "sort": null
    }, {
        "id": 2685,
        "name": "轮台县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2686,
        "name": "尉犁县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2687,
        "name": "若羌县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2688,
        "name": "且末县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2689,
        "name": "焉耆回族自治县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2690,
        "name": "和静县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2691,
        "name": "和硕县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2692,
        "name": "博湖县",
        "pid": 345,
        "sort": null
    }, {
        "id": 2693,
        "name": "阿克苏市",
        "pid": 346,
        "sort": null
    }, {
        "id": 2694,
        "name": "温宿县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2695,
        "name": "库车县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2696,
        "name": "沙雅县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2697,
        "name": "新和县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2698,
        "name": "拜城县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2699,
        "name": "乌什县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2700,
        "name": "阿瓦提县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2701,
        "name": "柯坪县",
        "pid": 346,
        "sort": null
    }, {
        "id": 2702,
        "name": "喀什市",
        "pid": 348,
        "sort": null
    }, {
        "id": 2703,
        "name": "疏附县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2704,
        "name": "疏勒县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2705,
        "name": "英吉沙县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2706,
        "name": "泽普县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2707,
        "name": "莎车县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2708,
        "name": "叶城县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2709,
        "name": "麦盖提县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2710,
        "name": "岳普湖县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2711,
        "name": "伽师县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2712,
        "name": "巴楚县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2713,
        "name": "塔什库尔干塔吉克自治县",
        "pid": 348,
        "sort": null
    }, {
        "id": 2714,
        "name": "伊宁市",
        "pid": 350,
        "sort": null
    }, {
        "id": 2715,
        "name": "奎屯市",
        "pid": 350,
        "sort": null
    }, {
        "id": 2716,
        "name": "伊宁县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2717,
        "name": "察布查尔锡伯自治县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2718,
        "name": "霍城县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2719,
        "name": "巩留县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2720,
        "name": "新源县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2721,
        "name": "昭苏县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2722,
        "name": "特克斯县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2723,
        "name": "尼勒克县",
        "pid": 350,
        "sort": null
    }, {
        "id": 2724,
        "name": "海拉尔区",
        "pid": 351,
        "sort": null
    }, {
        "id": 2725,
        "name": "阿荣旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2726,
        "name": "莫力达瓦达斡尔族自治旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2727,
        "name": "鄂伦春自治旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2728,
        "name": "鄂温克族自治旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2729,
        "name": "陈巴尔虎旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2730,
        "name": "新巴尔虎左旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2731,
        "name": "新巴尔虎右旗",
        "pid": 351,
        "sort": null
    }, {
        "id": 2732,
        "name": "满洲里市",
        "pid": 351,
        "sort": null
    }, {
        "id": 2733,
        "name": "牙克石市",
        "pid": 351,
        "sort": null
    }, {
        "id": 2734,
        "name": "扎兰屯市",
        "pid": 351,
        "sort": null
    }, {
        "id": 2735,
        "name": "额尔古纳市",
        "pid": 351,
        "sort": null
    }, {
        "id": 2736,
        "name": "根河市",
        "pid": 351,
        "sort": null
    }, {
        "id": 2737,
        "name": "新城区",
        "pid": 352,
        "sort": null
    }, {
        "id": 2738,
        "name": "回民区",
        "pid": 352,
        "sort": null
    }, {
        "id": 2739,
        "name": "玉泉区",
        "pid": 352,
        "sort": null
    }, {
        "id": 2740,
        "name": "赛罕区",
        "pid": 352,
        "sort": null
    }, {
        "id": 2741,
        "name": "土默特左旗",
        "pid": 352,
        "sort": null
    }, {
        "id": 2742,
        "name": "托克托县",
        "pid": 352,
        "sort": null
    }, {
        "id": 2743,
        "name": "和林格尔县",
        "pid": 352,
        "sort": null
    }, {
        "id": 2744,
        "name": "清水河县",
        "pid": 352,
        "sort": null
    }, {
        "id": 2745,
        "name": "武川县",
        "pid": 352,
        "sort": null
    }, {
        "id": 2746,
        "name": "东河区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2747,
        "name": "昆都仑区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2748,
        "name": "青山区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2749,
        "name": "石拐区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2750,
        "name": "白云鄂博矿区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2751,
        "name": "九原区",
        "pid": 353,
        "sort": null
    }, {
        "id": 2752,
        "name": "土默特右旗",
        "pid": 353,
        "sort": null
    }, {
        "id": 2753,
        "name": "固阳县",
        "pid": 353,
        "sort": null
    }, {
        "id": 2754,
        "name": "达尔罕茂明安联合旗",
        "pid": 353,
        "sort": null
    }, {
        "id": 2755,
        "name": "海勃湾区",
        "pid": 354,
        "sort": null
    }, {
        "id": 2756,
        "name": "海南区",
        "pid": 354,
        "sort": null
    }, {
        "id": 2757,
        "name": "乌达区",
        "pid": 354,
        "sort": null
    }, {
        "id": 2758,
        "name": "集宁区",
        "pid": 355,
        "sort": null
    }, {
        "id": 2759,
        "name": "卓资县",
        "pid": 355,
        "sort": null
    }, {
        "id": 2760,
        "name": "化德县",
        "pid": 355,
        "sort": null
    }, {
        "id": 2761,
        "name": "商都县",
        "pid": 355,
        "sort": null
    }, {
        "id": 2762,
        "name": "兴和县",
        "pid": 355,
        "sort": null
    }, {
        "id": 2763,
        "name": "凉城县",
        "pid": 355,
        "sort": null
    }, {
        "id": 2764,
        "name": "察哈尔右翼前旗",
        "pid": 355,
        "sort": null
    }, {
        "id": 2765,
        "name": "察哈尔右翼中旗",
        "pid": 355,
        "sort": null
    }, {
        "id": 2766,
        "name": "察哈尔右翼后旗",
        "pid": 355,
        "sort": null
    }, {
        "id": 2767,
        "name": "四子王旗",
        "pid": 355,
        "sort": null
    }, {
        "id": 2768,
        "name": "丰镇市",
        "pid": 355,
        "sort": null
    }, {
        "id": 2769,
        "name": "科尔沁区",
        "pid": 356,
        "sort": null
    }, {
        "id": 2770,
        "name": "科尔沁左翼中旗",
        "pid": 356,
        "sort": null
    }, {
        "id": 2771,
        "name": "科尔沁左翼后旗",
        "pid": 356,
        "sort": null
    }, {
        "id": 2772,
        "name": "开鲁县",
        "pid": 356,
        "sort": null
    }, {
        "id": 2773,
        "name": "库伦旗",
        "pid": 356,
        "sort": null
    }, {
        "id": 2774,
        "name": "奈曼旗",
        "pid": 356,
        "sort": null
    }, {
        "id": 2775,
        "name": "扎鲁特旗",
        "pid": 356,
        "sort": null
    }, {
        "id": 2776,
        "name": "霍林郭勒市",
        "pid": 356,
        "sort": null
    }, {
        "id": 2777,
        "name": "红山区",
        "pid": 357,
        "sort": null
    }, {
        "id": 2778,
        "name": "元宝山区",
        "pid": 357,
        "sort": null
    }, {
        "id": 2779,
        "name": "松山区",
        "pid": 357,
        "sort": null
    }, {
        "id": 2780,
        "name": "阿鲁科尔沁旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2781,
        "name": "巴林左旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2782,
        "name": "巴林右旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2783,
        "name": "林西县",
        "pid": 357,
        "sort": null
    }, {
        "id": 2784,
        "name": "克什克腾旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2785,
        "name": "翁牛特旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2786,
        "name": "喀喇沁旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2787,
        "name": "宁城县",
        "pid": 357,
        "sort": null
    }, {
        "id": 2788,
        "name": "敖汉旗",
        "pid": 357,
        "sort": null
    }, {
        "id": 2789,
        "name": "东胜区",
        "pid": 358,
        "sort": null
    }, {
        "id": 2790,
        "name": "达拉特旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2791,
        "name": "准格尔旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2792,
        "name": "鄂托克前旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2793,
        "name": "鄂托克旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2794,
        "name": "杭锦旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2795,
        "name": "乌审旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2796,
        "name": "伊金霍洛旗",
        "pid": 358,
        "sort": null
    }, {
        "id": 2797,
        "name": "临河区",
        "pid": 359,
        "sort": null
    }, {
        "id": 2798,
        "name": "五原县",
        "pid": 359,
        "sort": null
    }, {
        "id": 2799,
        "name": "磴口县",
        "pid": 359,
        "sort": null
    }, {
        "id": 2800,
        "name": "乌拉特前旗",
        "pid": 359,
        "sort": null
    }, {
        "id": 2801,
        "name": "乌拉特中旗",
        "pid": 359,
        "sort": null
    }, {
        "id": 2802,
        "name": "乌拉特后旗",
        "pid": 359,
        "sort": null
    }, {
        "id": 2803,
        "name": "杭锦后旗",
        "pid": 359,
        "sort": null
    }, {
        "id": 2804,
        "name": "二连浩特市",
        "pid": 360,
        "sort": null
    }, {
        "id": 2805,
        "name": "锡林浩特市",
        "pid": 360,
        "sort": null
    }, {
        "id": 2806,
        "name": "阿巴嘎旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2807,
        "name": "苏尼特左旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2808,
        "name": "苏尼特右旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2809,
        "name": "东乌珠穆沁旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2810,
        "name": "西乌珠穆沁旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2811,
        "name": "太仆寺旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2812,
        "name": "镶黄旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2813,
        "name": "正镶白旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2814,
        "name": "正蓝旗",
        "pid": 360,
        "sort": null
    }, {
        "id": 2815,
        "name": "多伦县",
        "pid": 360,
        "sort": null
    }, {
        "id": 2816,
        "name": "乌兰浩特市",
        "pid": 361,
        "sort": null
    }, {
        "id": 2817,
        "name": "阿尔山市",
        "pid": 361,
        "sort": null
    }, {
        "id": 2818,
        "name": "科尔沁右翼前旗",
        "pid": 361,
        "sort": null
    }, {
        "id": 2819,
        "name": "科尔沁右翼中旗",
        "pid": 361,
        "sort": null
    }, {
        "id": 2820,
        "name": "扎赉特旗",
        "pid": 361,
        "sort": null
    }, {
        "id": 2821,
        "name": "突泉县",
        "pid": 361,
        "sort": null
    }, {
        "id": 2822,
        "name": "阿拉善左旗",
        "pid": 362,
        "sort": null
    }, {
        "id": 2823,
        "name": "阿拉善右旗",
        "pid": 362,
        "sort": null
    }, {
        "id": 2824,
        "name": "额济纳旗",
        "pid": 362,
        "sort": null
    }]
export const industries = ['法人/CEO/老板', '人事主管', '财务主管', '销售主管', '行政主管', 'IT主管', '运营主管', '普通员工', '其他']
export const personnelSize = ['1-9','10-20','21-50','51-100','101-200','201-500','501-2000','>2000']