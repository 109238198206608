<template>

  <div>

    <template v-if="context.parent && context.parent.tag==='subForm'">
      <el-input-number v-if="type==='fill'" v-model="data[element.id]" @change="handleChange" :controls="false"
                       style="width: 100%" placeholder="请输入"
                       :disabled="context.authentication.isDisabled(element,data)"></el-input-number>
      <div v-else-if="type==='detail'">{{ data[element.id] }}</div>
    </template>
    <el-form-item v-else :required="context.authentication.isRequired(element)">
      <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">{{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
      </template>
      <el-input-number v-if="type==='fill'" v-model="data[element.id]" @change="handleChange" :controls="false"
                       style="width: 100%" placeholder="请输入"
                       :disabled="context.authentication.isDisabled(element,data)"></el-input-number>
      <div v-else-if="type==='detail'" class="design-detail">{{ data[element.id] || "⠀" }}</div>
    </el-form-item>

  </div>
</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import {dynamicFunction} from "@/pojo/script";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);

    if (this.data[this.element.id] === undefined) {
      this.data[this.element.id] = 0;
    }


  },
  mounted() {
    this.initWatch();
  },
  methods: {
    handleChange(val) {

      if (this.isNumericString(val)) {
        this.data[this.element.id] = this.element.attrs.scale.enable ? parseFloat(parseFloat(val).toFixed(this.element.attrs.scale.value)) : parseInt(val);
      } else {
        this.data[this.element.id] = this.element.attrs.scale.enable ? 0 : parseInt(val);
      }

      this.data[this.element.id] = isNaN(this.data[this.element.id]) ? 0 : this.data[this.element.id];
    },
    isNumericString(str) {
      return /^(-?\d+(\.\d+)?)$/.test(str);
    },
    initWatch() {


      if (this.type === 'fill') {

        if (this.element.default.select === 'script' && this.element.default.script.value) {
          try {
            this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
            //console.log(this.element.label,this.element.default.script.value,this.data[this.element.id])
          } catch (e) {
            console.error(e);
          }
          if (this.context.rawData) {
            Object.keys(this.context.rawData).forEach(key => {
              if (this.element.default.script.value.includes(key)) {

                this.$watch(`context.rawData.${key}`, (newValue, oldValue) => {
                  try {
                    this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
                  } catch (e) {

                  }
                }, {
                  deep: true,
                  immediate: false
                });
              }
            });
          }
          Object.keys(this.data).forEach(key => {

            if (this.element.default.script.value.includes(key)) {

              this.$watch(`data.${key}`, (newValue, oldValue) => {
                try {
                  this.data[this.element.id] = dynamicFunction(this.element.default.script.value, {...this.data, ...this.context.rawData});
                } catch (e) {

                }
              }, {
                deep: true,
                immediate: false
              });

            }
          })
        }
      }
    },
  }
}

</script>

<style scoped lang="scss">


</style>