<template>

  <el-drawer v-model="visible" direction="rtl" :size="fullScreen?'100%': '65%'" :show-close="false"
             :append-to-body="true"
             :modal="false" :wrapperClosable="false" :destroy-on-close="true"
             style="background-color: #F5F7FA" @close="handleClose">

    <template #header="{  titleId, titleClass ,close}">
      <span :id="titleId" :class="titleClass"
            style="font-size: 16px;font-weight: bold;color: rgb(48,66,101)">{{ form?.name || '' }}</span>
      <span>
        <el-button v-prevent-re-click v-if="form && editAuthentication?.isShowEditButton()" icon="Edit" link type="info"
                   style="color: #8A94A5"
                   @click="$refs.appFormAdd.call(form.id,deepClone(data))">编辑</el-button>

         <el-button v-prevent-re-click v-if="form" icon="Printer" link type="info"
                    style="color: #8A94A5"
                    @click="print(form_id,data_id)">打印</el-button>

         <el-button v-prevent-re-click v-if="form" icon="Delete" link type="info"
                    style="color: #8A94A5"
                    @click="formStore.deleteRow(form.id,data.id,()=>{ visible=false;  $emit('refresh'); })">删除</el-button>

        <el-button v-prevent-re-click :icon="fullScreen?'Minus':'FullScreen'" type="info" link
                   style="margin-left: 20px;font-size: 16px;color: #8A94A5"
                   @click="fullScreen=!fullScreen"></el-button>

        <el-button v-prevent-re-click icon="Close" type="info" link style="font-size: 20px;color: #8A94A5"
                   @click="close()"></el-button>
      </span>
    </template>
    <el-scrollbar height="calc(100vh - 100px)"
                  style="background-color: white;padding: 0 20px 20px 20px; border-radius: 8px;"
                  v-loading="loading"
                  element-loading-text="努力加载中..."
                  class="back-top">
      <el-form v-if="form && data" ref="form" :model="data" :label-position="form.setting.style.PC.layout"
               label-width="auto"
               style="min-height: 50vh;background-color: white;padding: 24px 20px 0 20px;border-radius: 10px">
        <template v-for="(element,index) in form.elements" :key="index">
          <component v-if="viewAuthentication.isRead(element)" v-show="viewAuthentication.isHide(element,data)"
                     :is="`design${element.tag.charAt(0).toUpperCase() + element.tag.slice(1)}Preview`"
                     :element="element"
                     :context="{ form:form, data:data, parent:form.elements,authentication:viewAuthentication}">
          </component>
        </template>
      </el-form>
      <div class="container" v-if="form_id && data_id">
        <b style="font-size: 16px;">其它</b>
        <el-divider></el-divider>
        <el-radio-group v-model="other" style="margin-bottom: 20px;">
          <el-radio-button value="评论">评论</el-radio-button>
          <el-radio-button value="变更记录">变更记录</el-radio-button>
        </el-radio-group>

        <comment-show v-if="other==='评论'" :form_id="form_id" :data_id="data_id"></comment-show>
        <form-time-line v-if="other==='变更记录'" :form_id="form_id" :data_id="data_id"></form-time-line>
      </div>
      <el-backtop :bottom="50" :right="60" target=".back-top .el-scrollbar__wrap"/>
    </el-scrollbar>


  </el-drawer>

  <app-form-edit ref="appFormAdd" @callback="handleCallback"></app-form-edit>

</template>

<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useMenuStore} from "@/stores/menu";
import {useFormAuthenticationStore} from "@/stores/form-authentication";
import commentShow from "@/components/design-form-list/commentShow.vue";
import AppFormEdit from "@/components/app-form/app-form-edit.vue";
import FormTimeLine from "@/components/common/formTimeLine.vue";

export default {
  emits: ['refresh'],
  components: {FormTimeLine, AppFormEdit, commentShow},
  data() {
    return {
      visible: false,
      form: null,
      data: null,
      columns: [],
      fields: [],
      other: '评论',
      fullScreen: false,
      loading: false,
      viewAuthentication: null,
      editAuthentication: null,
      form_id: '',
      data_id: '',
    }
  },
  computed: {
    ...mapStores(useFormStore, useMenuStore, useFormAuthenticationStore)
  },
  methods: {
    call(form_id, data_id) {
      this.form = null;
      this.data = null;
      this.form_id = form_id;
      this.data_id = data_id;
      this.query();

      this.$nextTick(() => this.visible = true)
    },
    query() {
      this.loading = true
      this.menuStore.findById(this.form_id).then(res => {
        this.form = res.data;
        this.columns = this.formStore.columns(this.form);
        this.fields = !this.form.setting.list.fields || this.form.setting.list.fields.length === 0 ? this.columns.map(mapper => mapper.id) : this.form.setting.list.fields;
        this.filters = !this.form.setting.list.filters ? [] : this.form.setting.list.filters;
        this.viewAuthentication = this.formAuthenticationStore.view(this.form);
        this.editAuthentication = this.formAuthenticationStore.edit(this.form);
        this.loading = false
        this.findDataById();
      })
    },
    findDataById() {
      this.loading = true
      this.formStore.findById(this.form_id, this.data_id).then(res => {
        this.data = res.data;
        this.loading = false;
      })
    },
    handleClose() {
      this.form = null;
      this.data = null;
      this.columns = [];
      this.fields = [];
      this.viewAuthentication = null;
      this.editAuthentication = null;
      this.form_id = '';
      this.data_id = '';
      this.$emit('refresh');
    },
    handleCallback() {
      this.data = null;
      this.$nextTick(() => {
        this.findDataById();
      })

    },
    print(form_id,data_id){
      this.go(`/app/print/${form_id}/${data_id}`,null,true)
    }
  }

}

</script>

<style lang="scss">
.el-drawer__header {
  margin-bottom: 0 !important;
}

.el-drawer__title {
  color: #344262;
}


.el-divider--horizontal {
  margin: 10px 0 20px 0;
}


.container {
  background-color: white;
  margin-right: 20px;
  margin-bottom: 20px;
  min-height: 200px;
  border-radius: 8px;
}

</style>