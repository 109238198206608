import {get, post, post2, put, put2, $delete, upload} from '@/plugins/axios.js'
import {useAppStore} from "@/stores/app";

export default {
    getSession() {
        return get('/api/user/session');
    },
    post(params) {
        return post2('/api/user', params);
    },
    switchCompany(company_id) {
        return post('/api/user/switchCompany', {company_id});
    },
    unbindCompany(params) {
        return $delete('/api/user/unbindCompany', params);
    },
    bindCompany(params) {
        return post('/api/user/bindCompany', params);
    },
    put(params) {
        return put('/api/user', params);
    },
    findById(params) {
        return get('/api/user', params);
    },
    findByIds(params) {
        return get('/api/user/findByIds', params);
    },
    list(params) {
        return get('/api/user/list', params);
    },
    listByDept(params) {
        return get('/api/user/listByDept', params);
    },
    listByRole(params) {
        return get('/api/user/listByRole', params);
    },
    listByAccountOrCellphone(params) {
        return get('/api/user/listByAccountOrCellphone', params);
    },
    validate(params) {
        return post('/api/user/validate', params);
    },
    reLogin(params) {
        return post('/api/user/reLogin', params);
    },
    uploadAvatar(data,params) {
        let appStore = useAppStore();
        let formData = new FormData();
        formData.append('file', data);
        if(params){
            Object.keys(params).forEach(key => {
                formData.append(key, params[key]);
            })

        }
        return upload(appStore.avatarUploadUrl(), formData);
    },
    logout(params) {
        return post('/api/user/logout', params);
    },

}