import {defineStore} from 'pinia'
import {useUserStore} from "@/stores/user";
import controller from "@/controller/deptController";
import roleController from "@/controller/roleController";

export const useDeptStore = defineStore('dept', {
    state: () => {

    },
    getters: {},
    actions: {
        async list(keyword) {
            if (!useUserStore().getCompanySelected)
                return new Promise((resolve, reject) => {
                    resolve({data: []})
                });

            return await controller.list({
                keyword,
                company_id: useUserStore().getCompanySelected
            })
        },
        async delete(id) {
            await controller.delete(id);
        },
        async post(obj) {
            obj.company_id = useUserStore().getCompanySelected;
            return await controller.post(obj)
        },
        async put(obj) {
            return await controller.put(obj)
        },
        mapDeptName(companyDepts, depts) {

            let company = useUserStore().getCompanySelected
            if (!depts || !depts[company])
                return [];

            return companyDepts.filter(filter => {
                return depts[company].indexOf(filter.id) !== -1
            }).map(mapper => mapper.name)
        },
        async listUser(dept_id) {
            return await controller.listUser({
                company: useUserStore().getCompanySelected,
                dept_id
            });

        },
        bindUser(dept_id, ids) {
            return controller.bindUser({dept_id, company: useUserStore().getCompanySelected, ids});
        },
        unbindUser(dept_id, ids) {
            return controller.unbindUser({dept_id, company: useUserStore().getCompanySelected, ids});
        },
        charge(dept_id, user_id) {
            return controller.charge({
                dept_id, user_id
            });
        },
        findById(dept_id) {
            return controller.findById({
                id: dept_id
            })
        },
        findByIds(dept_ids) {
            return controller.findByIds({
                ids: dept_ids.join(',')
            })
        },
    },
    persist: {
        enabled: false
    }
})