<template>

  <div>
  <template v-if="context.parent && context.parent.tag==='subForm'">
    <el-select v-if="type==='fill'" v-model="data[element.id]">
      <el-option v-for="(item,index) in element.attrs.options" :key="index" :value="item" :label="item"></el-option>
    </el-select>
    <div v-else-if="type==='detail'" >{{data[element.id]}}</div>

  </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
       <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-select v-if="type==='fill'" v-model="data[element.id]">
      <el-option v-for="(item,index) in element.attrs.options" :key="index" :value="item" :label="item"></el-option>
    </el-select>
    <div v-else-if="type==='detail'" class="design-detail">{{data[element.id] || "⠀"}}</div>
  </el-form-item>
  </div>
</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  }
}

</script>

<style scoped lang="scss">

.el-checkbox {
  font-weight: normal;
}

</style>