<template>
  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{ element.label }}
       <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-button v-prevent-re-click icon="Film" style="color: #191E25;font-weight: normal">附件上传</el-button>
  </el-form-item>
</template>

<script>


import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {
    FormElementTooltip

  },
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      data: this.element,
      list: [],
    }
  },
  created() {
    this.list = []
  },
  methods: {}
}

</script>

<style scoped lang="scss">
:deep( .el-input.is-disabled .el-input__inner) {
  cursor: default;
  background-color: white;
}

:deep(.el-input__wrapper) {
  padding: 1px 1px;
}
</style>