import {
    get,
    post,
    post2,
    put,
    put2,
    $delete
} from '@/plugins/axios.js'

export default {
    post(params) {
        return post('/api/dept', params);
    },
    delete(id, params) {
        return $delete(`/api/dept/${id}`, params);
    },
    put(params) {
        return put2('/api/dept', params);
    },
    findById(params) {
        return get('/api/dept', params);
    },
    findByIds(params) {
        return get('/api/dept/findByIds', params);
    },
    list(params) {
        return get('/api/dept/list', params);
    },
    listUser(params) {
        return get(`/api/dept/listUser`, params);
    },
    bindUser(params) {
        return post(`/api/dept/bindUser`, params);
    },
    unbindUser(params) {
        return $delete(`/api/dept/unbindUser`, params);
    },
    charge(params) {
        return post(`/api/dept/charge`, params);
    }
}
