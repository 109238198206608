<template>
  <el-form-item>
    <template #label>
      <span
          :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-input :readonly="true" placeholder="请选择">
      <template #suffix>
        <el-button v-prevent-re-click icon="Connection" style="font-size: 16px" link></el-button>
      </template>
    </el-input>
  </el-form-item>
</template>

<script>

import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: this.element
    }
  },
  created() {
  },
  mounted() {
    let element = this.element;
    let parent = this.context.parent ? this.context.parent.tag : '';
    if (parent !== element.attrs.parent) {
      element.attrs.fillRole = {
        main: [],
        subForms: [],
      }

      element.attrs.form_id = '';
    }

    element.attrs.parent = parent;
  },

}

</script>

<style scoped lang="scss">

</style>