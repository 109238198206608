import {get, post, post2, put, put2, $delete} from '@/plugins/axios.js'

export default {
    list(params) {
        return get('/api/role/list', params);
    },
    findById(params) {
        return get('/api/role/findById', params);
    },
    post(params) {
        return post('/api/role', params);
    },
    put(params) {
        return put('/api/role', params);
    },
    putGroup(params) {
        return put2('/api/role/group', params);
    },
    delete(id, params) {
        return $delete(`/api/role/${id}`, params);
    },
    unbindUser(params) {
        return $delete(`/api/role/unbindUser`, params);
    },
    bindUser(params) {
        return post(`/api/role/bindUser`, params);
    },
    listUser(params) {
        return get(`/api/role/listUser`, params);
    }

}