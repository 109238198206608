import {
    get,
    post,
    post2,
    put,
    put2,
    $delete,
    download
} from '@/plugins/axios.js'


export default {
    preview(sheet_index, params) {
        return get(`/api/form-template/preview/${sheet_index}`, params);
    },
    import(params) {
        return post(`/api/form-template/import`, params);
    },
    export(formId, params) {
        return post(`/api/form-template/export/${formId}`, params);
    },
    downloadDefaultTemplate(name, formId, subForm) {
        return download(name, `/api/form-template/downloadDefaultTemplate/${formId}`, {subForm});
    },
    downloadTemplateByFileId(name, file_id, params) {
        return download(name, `/api/form-template/downloadTemplateByFileId/${file_id}`, params);
    },
    deleteTemplate(fileId, params) {
        return $delete(`/api/form-template/deleteTemplate/${file_id}`, params);
    },
    listTemplate(formId, subForm) {
        return get(`/api/form-template/listTemplate/${formId}`, {subForm: subForm});
    }


}
