import {get, post, post2, put, put2, $delete} from '@/plugins/axios.js'

export default {
    hasWorkFlow(params) {
        return get('/api/flow/hasWorkFlow', params);
    },
    countMessage(params) {
        return post('/api/flow/countMessage', params);
    },
    listMessage(params) {
        return post('/api/flow/listMessage', params);
    },
    timeline(formId,dataId, params) {
        return get(`/api/flow/timeline/${formId}/${dataId}`, params);
    },
    approval(form_id, data_id, node_id, data, result, opinion) {

        return post(`/api/flow/approval/${form_id}/${data_id}/${node_id}`, {
            dataJson: data ? JSON.stringify(data) : data,
            result,
            opinion
        });
    },
    back(form_id, data_id, node_id, target_id, opinion) {
        return post(`/api/flow/back/${form_id}/${data_id}/${node_id}`, {
            target_id,
            opinion
        });
    },
    submit(form_id, data_id, node_id, data, result, opinion) {

        return post(`/api/flow/submit/${form_id}/${data_id}/${node_id}`, {
            dataJson: data ? JSON.stringify(data) : data,
            opinion
        });
    },
    sponsor(form_id, data_id, node_id,operation, data) {

        return post(`/api/flow/sponsor/${form_id}/${data_id}/${node_id}`, {
            operation,
            dataJson: data ? JSON.stringify(data) : data,
        });
    },
    copyAndSend(form_id, data_id, node_id,params) {

        return post(`/api/flow/copyAndSend/${form_id}/${data_id}/${node_id}`, params);
    },
    handle(form_id, data_id, node_id,params) {

        return post(`/api/flow/handle/${form_id}/${data_id}/${node_id}`, params);
    },
    getFlowNodeBySession(params){
        return get(`/api/flow/getFlowNodeBySession`, params)
    }

}