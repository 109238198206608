<template>

  <div>
  <template v-if="context.parent && context.parent.tag==='subForm'">
    <multiple-file-upload-list v-if="type==='fill'" v-model="data[element.id]" :fileFormat="['png', 'jpg']" :params="{application:context.form.application_id,menu:context.form.id}"></multiple-file-upload-list>
    <div v-else-if="type==='detail'" style="width: 100%">
      <el-image v-for="(image,image_index) in data[element.id]" :key="image_index"
                style="width: 60px; height:60px;margin-right: 10px;border-radius: 4px;"
                :src="appStore.downloadUrl(image.id)"
                :preview-src-list="data[element.id].map(mapper=>appStore.downloadUrl(mapper.id))"
                :initial-index="image_index"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-teleported="true"
                fit="cover">
      </el-image>
      <div v-if="!data[element.id] || data[element.id].length===0"></div>
    </div>
  </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <multiple-file-upload v-if="type==='fill'" v-model="data[element.id]" :fileFormat="['png', 'jpg']" :params="{application:context.form.application_id,menu:context.form.id}"></multiple-file-upload>
    <div v-else-if="type==='detail'" style="width: 100%">
      <el-image v-for="(image,image_index) in data[element.id]" :key="image_index"
                style="width: 98px; height:98px;margin-right: 10px;border-radius: 4px;"
                :src="appStore.downloadUrl(image.id)"
                :preview-src-list="data[element.id].map(mapper=>appStore.downloadUrl(mapper.id))"
                :initial-index="image_index"
                :zoom-rate="1.2"
                :max-scale="7"
                :min-scale="0.2"
                :preview-teleported="true"
                fit="cover">
      </el-image>
      <div v-if="!data[element.id] || data[element.id].length===0" class="design-detail">⠀</div>
    </div>
  </el-form-item>
  </div>
</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import {useAppStore} from "@/stores/app";
import MultipleFileUpload from "@/components/common/multipleFileUpload.vue";
import multipleFileUploadList from "@/components/common/multipleFileUploadList.vue";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip, MultipleFileUpload,multipleFileUploadList},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapStores(useAppStore, useFlowStore)
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  created() {
    this.data = this.context.data;
    if (typeof this.data[this.element.id] === 'string') {
      this.data[this.element.id] = [];
    }

    this.type = this.flowStore.typeOfElement(this.element, this.context);
  }
}

</script>

<style scoped lang="scss">
:deep(.el-input.is-disabled .el-input__inner) {
  cursor: default;
  background-color: white;
}

:deep(.el-input__wrapper) {
  padding: 1px 1px;
}
</style>