<template>

<div>

  <el-form-item :label="element.label" :required="context.authentication.isRequired(element)">
    <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
       <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-input v-if="type==='fill'" placeholder="系统自动生成" readonly :model-value="data[element.id]" ></el-input>
    <div v-else-if="type==='detail'" class="design-detail">{{ data[element.id] || "⠀" }}</div>
  </el-form-item>
</div>

</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  }
}

</script>

<style scoped lang="scss">

</style>