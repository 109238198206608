<template>
  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">{{
          element.label
        }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-button v-prevent-re-click icon="Picture" style="color: #191E25;font-weight: normal">图片上传</el-button>
  </el-form-item>
</template>

<script>


import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      data: this.element,
      list: [],
    }
  },
  created() {
    this.list = [
      {
        "id": "65f3ea7433fdc4765c16ac21",
        "file_name": "20240304-091256-22194-135749980-7.jpg",
        "file_id": "65f3ea7433fdc4765c16ac22",
        "md5": "d21334bcaaa9d8bb4ac7e6c93fdef1a0",
        "format": "jpg",
        "type": "image",
        "size": "60KB"
      },
      {
        "id": "65f3c4ec33fdc4765c16ac18",
        "file_name": "20240304-092729-22194-135749980-3.jpg",
        "file_id": "65f3c4ec33fdc4765c16ac19",
        "md5": "9efad13fc6c6f751ae07889d609be80e",
        "format": "jpg",
        "type": "image",
        "size": "42KB"
      }
    ]
  },
  methods: {
    print() {
      console.log(JSON.stringify(this.list, null, 4))
    }
  }
}

</script>

<style scoped lang="scss">
:deep(.el-input.is-disabled .el-input__inner) {
  cursor: default;
  background-color: white;
}

:deep(.el-input__wrapper) {
  padding: 1px 1px;
}
</style>