<template>
  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">{{ element.label }}
       <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-switch :disabled="true" />
  </el-form-item>
</template>

<script>

import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: this.element
    }
  }
}

</script>

<style scoped lang="scss">
:deep(.el-input.is-disabled .el-input__inner){
  cursor: default;
  background-color: white;
}

:deep(.el-input__wrapper){
  padding: 1px 1px;
}
</style>