import {defineStore} from 'pinia'
import controller from "@/controller/attachmentController";
import {ElNotification} from 'element-plus'
import {useAppStore} from "@/stores/app";
import {download} from "@/plugins/axios";
import {upload} from "@/plugins/axios";

export const useAttachmentStore = defineStore('attachment', {
    state: () => {
        return {
            action: useAppStore().uploadUrl(),
        }
    },
    getters: {},
    actions: {
        preview(id) {
            return controller.preview(id);
        },
        async download(id, name) {
            await controller.download(id, name);
        },
        uploadImage(data, params) {
            let appStore = useAppStore();
            let formData = new FormData();
            formData.append('file', data, params.filename);
            if (params) {
                Object.keys(params).forEach(key => {
                    formData.append(key, params[key]);
                })

            }
            return upload(this.action, formData);
        },
        isCanReadFileType(type){
            return type === 'image' || type === 'pdf' ||  type === 'word' || type === 'excel';
        }

    },
    persist: {
        enabled: false
    }
})