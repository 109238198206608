<template>

  <el-popover ref="popover" width="600px" trigger="click" placement="bottom-start" :show-arrow="false">
    <el-input style="margin:10px 0 10px 0" v-model="keyword" placeholder="搜索" prefix-icon="Search" autofocus></el-input>
    <el-segmented style="width: 100%;margin-bottom: 10px" v-model="segmented" :options="['全部部门','我的部门']"></el-segmented>
    <el-row>

      <el-col :span="12" style="border-right: 1px solid #E3E3E3;min-height: 270px">
        <el-scrollbar style="height: 350px;padding-left: 10px;padding-right: 10px;margin-top: 10px">
          <div style="color:rgb(80,95,125);cursor: pointer" @click="keyword='';dept=null">
            {{ companyStore.selectedName() }}
          </div>
          <el-tree v-if="segmented==='全部部门'" ref="tree" :data="depts" style="padding: 4px;"
                   @node-click="(data)=>{ dept = data.id}" check-strictly node-key="id"
                   :props="{label: 'name', children: 'children'}" :expand-on-click-node="false">
            <template #default="{ node }">
              <span style="color:rgb(48,66,101);font-size: 13px">{{ node.label }}</span>
            </template>
          </el-tree>
        </el-scrollbar>
      </el-col>

      <el-col :span="12">
        <el-scrollbar style="height: 350px;padding-left: 10px;padding-right: 10px;margin-top: 10px">
          <ul>
            <template v-if="getUsers.length>0">
              <li v-for="user in getUsers" :key="user.id">
                <div style="display: flex;align-items: center">
                  <el-checkbox v-if="multiple" style="margin-right: 20px" :key="user.id" v-model="user.select" @change="handleChange(user)"></el-checkbox>
                  <el-radio v-else style="margin-right: 20px" :key="`radio-${user.id}`" :value="true" v-model="user.select" @change="handleChange(user)"></el-radio>
                  <el-avatar :size="32" :src="userStore.icon(user.icon)"></el-avatar>
                  <span style="margin-left: 20px;font-size: 13px"> {{ user.real_name }}</span>
                </div>
              </li>
            </template>
            <div v-else style="text-align: center;color: #334262">暂无人员</div>
          </ul>
        </el-scrollbar>
      </el-col>
    </el-row>

    <template #reference>
      <div :style="`border: 1px solid #E3E3E3;border-radius: 4px;width: ${width};min-height: ${height};padding-bottom: 1px;background-color:white`">
        <el-tag v-for="(item,index) in list" :key="index" style="margin: 2px" type="info" closable @close="onClose(index)">
        <span style="display: flex;align-items: center">
           <el-icon color="#3C4B6A"><User></User></el-icon>
          <span style="margin-left: 4px;color: #3C4B6A">{{ getNameByUserId(item) }}</span>
        </span>
        </el-tag>
      </div>
    </template>
  </el-popover>

</template>

<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useDeptStore} from "@/stores/dept";
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";

export default {
  emits:['change'],
  props: {
    modelValue: {
      type: [String, Array],
      default: () => ""
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    width: {
      type: [String, Number],
      default: () => '100%'
    },
    height: {
      type: [String, Number],
      default: () => '30px'
    }
  },
  computed: {
    ...mapStores(useDeptStore, useCompanyStore, useFormStore, useUserStore),
    getUsers() {

      if (this.segmented === '我的部门') {
        let userDept = this.userStore.user.depts[this.userStore.getCompanySelected]
        let users = this.users.filter(filter => filter.depts.some(dept => userDept.indexOf(dept) !== -1))
        if (this.keyword) {
          return users.filter(filter => (filter.real_name && filter.real_name.indexOf(this.keyword) > -1))
        } else {
          return users;
        }

      } else {
        if (this.keyword) {
          return this.users.filter(filter => (filter.real_name && filter.real_name.indexOf(this.keyword) > -1))
        } else if (this.dept) {
          return this.users.filter(filter => filter.depts?.indexOf(this.dept) > -1 || false)
        } else {
          return this.users;
        }
      }
    }
  },
  data() {
    return {
      users: [],
      depts: [],
      list: null,
      keyword: '',
      dept: '',
      segmented: '全部部门'
    }
  },
  async created() {


    //请求用戶列表
    let res = await this.formStore.listUser();
    this.users = res.data;

    //删除不存在的用戶
    this.list = this.list.filter(filter => filter.type !== 'user' || this.users.some(some => some.id === filter.id));
    //初始化是否选择
    this.users.forEach(user => {
      user.select = this.list.some(some => some === user.id)
    })
    this.deptStore.list().then(({data}) => {
      //组织tree数据结构
      this.depts = data.filter(filter => !filter.pid);
      this.depts.forEach(item => {
        item.users = this.users.filter(filter => {
            return  filter.depts && filter.depts.indexOf(item.id) !== -1
        });
        item.children = data.filter(filter => filter.pid === item.id);
        item.children.forEach(item => item.users = this.users.filter(filter => filter.depts && filter.depts.indexOf(item.id) !== -1))
      });
    });
  },
  watch: {
    modelValue: {
      handler(newVal, val) {
        try {
          this.list = Array.isArray(newVal) ? newVal : (newVal ? [newVal] : []);
          this.users.forEach(user => {
            user.select = this.list.some(some => some === user.id)
          })
        } catch (e) {

        }

      },
      immediate: true
    }
  },
  methods: {
    getNameByUserId(item) {
      let match = this.users.filter(filter => filter.id === item);
      return match.length > 0 ? match[0].real_name : "";
    },
    handleChange(user) {
      if (this.multiple) {
        if (user.select) {
          this.list.push(user.id);
        } else {
          this.list = this.list.filter(filter => filter !== user.id);
        }

        this.$emit('update:modelValue', this.list);
      } else {
        this.list = [user.id];
        this.$refs.popover.hide();
        this.$emit('update:modelValue', user.id);
        this.$emit('change',user)
      }
    },
    onClose(index) {
      let id = this.list.splice(index, 1)
      this.users.forEach(user => {
        if (user.id === id[0]) {
          user.select = false;
        }
      })

      if (this.multiple) {
        this.$emit('update:modelValue', this.list);
        this.$emit('change',this.list)
      } else {
        this.$emit('update:modelValue', this.list.length === 0 ? "" : this.list[0]);
        this.$emit('change',this.list.length === 0 ? "" : this.list[0])
      }
    },
    selectUserName(){
      return this.list.map((item) => this.getNameByUserId(item)).join(",");
    }
  }
}

</script>

<style scoped lang="scss">
ul {
  padding: 0;
  margin: 4px 0;
}

li {
  list-style-type: none;
  line-height: 40px;
  color: #595959;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 10px;
  border-bottom: 1px solid #F5F7FA;
}

li:hover {
  background-color: #F5F8FC;
}


</style>