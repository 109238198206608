import {defineStore} from 'pinia'
import {useUserStore} from "@/stores/user";
import companyController from "@/controller/companyController";

export const useCompanyStore = defineStore('company', {
    state: () => {
        return {
            companyList: [],
        }
    },
    actions: {
        async init() {
            await companyController.list().then(res => {
                if(Array.isArray(res.data)) {
                    this.companyList = res.data;
                }

            })
        },
        selectedName() {
            try {
                let selected = useUserStore().getCompanySelected;
                let filter = this.companyList.filter(filter => filter.id === selected);

                return filter.length > 0 ? filter[0].name : '未绑定公司/组织';
            } catch (e) {
                return '无法获取绑定公司/组织';
            }

        },
        isSelected(id) {
            return useUserStore().getCompanySelected === id;
        },
        isCreator(id) {
            if (!id) return false;
            return id === useUserStore().getCompanySelected;
        },
        listByUser() {
            let userCompanies = useUserStore().getCompanies;
            let selected = useUserStore().getCompanySelected;

            if (!userCompanies)
                return [];

            return this.companyList.filter(filter => userCompanies.indexOf(filter.id) !== -1).map(mapper => {
                mapper.isSelected = selected ? mapper.id === selected : false;
                return mapper;
            });
        }
    },
    persist: {
        enabled: false
    }
})