<template>

  <div :class="getClass" @click.stop="context.onClickElement(this.element)">
    <div class="designWrapper-button">
      <el-button v-prevent-re-click type="primary" icon="DocumentCopy" circle size="small" @click="context.onCopyElement(this.element)"></el-button>
      <el-button v-prevent-re-click type="danger" icon="Delete" circle size="small" @click="context.onDeleteElement(this.element,this.parent)"></el-button>
    </div>
    <slot></slot>
  </div>

</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  computed: {
    getClass() {
      if (this.context.select_id === this.element.id)
        return 'designWrapper designWrapper-on'
      else
        return 'designWrapper'
    }
  },
  methods: {}

}

</script>

<style scoped lang="scss">
.designWrapper {
  padding: 15px 30px 1px 30px;
  border-bottom: 1px solid #F3F5F8;
}

.designHbox .designWrapper{
  border-bottom: none;
}

.designWrapper:hover {
  background-color: #F5F8FC;
}

.designWrapper-on {
  background-color: white;
  border: 1px dashed #3581F7;
  z-index: 9999;
}

.designHbox .designWrapper-on{
  background-color: #EBF3FC;
  border: 1px dashed #3581F7;
  z-index: 9999;
}

.designWrapper-button {
  text-align: right;
  margin-top: -30px;
  display: none;
}

.designWrapper-on > .designWrapper-button {
  display: block;
}
</style>