<template>
  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{element.label}}
         <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <el-input :placeholder="eg" :readonly="true"></el-input>
  </el-form-item>
</template>

<script>

import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  computed:{
    eg() {
      let str = "";
      let rule = this.element.attrs.rule;
      rule.forEach(item => {
        switch (item.type) {
          case 'date':
            str += this.current(item.value)
            break
          case 'increment':
            str += "1".padStart(item.value,'0')
            break
          case 'string':
            str += item.value;
            break
          case 'form':
            str += 'XXXX';
            break
        }
      })

      return str;
    }
  },
  data() {
    return {
      data: this.element
    }
  },
  created() {
  }
}

</script>

<style scoped lang="scss">

</style>