import {
    get,
    post,
    post2,
    put,
    put2,
    $delete
} from '@/plugins/axios.js'

export default {
    post(params) {
        return post('/api/company', params);
    },
    delete(params) {
        return $delete('/api/company', params);
    },
    put(params) {
        return put2('/api/company', params);
    },
    findById(params) {
        return get('/api/company', params);
    },
    list(params) {
        return get('/api/company/list', params);
    },
}
