import {
	get,
	post,
	post2,
	put,
	put2,
	$delete, download
} from '@/plugins/axios.js';
import {useAppStore} from "@/stores/app";

export default {

	async post(params) {
		let appStore = useAppStore();
		return await post2(appStore.uploadUrl(), params);
	},
	async download(id,name) {
		let appStore = useAppStore();
		return await download(name,appStore.downloadUrl(id));
	},
	async get(params) {
		let appStore = useAppStore();
		return await get(appStore.resourceUrl('/attachment'), params);
	},
	async preview(id,params) {
		let appStore = useAppStore();
		return await get(appStore.resourceUrl(`/attachment/preview/${id}`), params);
	},
	async delete(params) {
		let appStore = useAppStore();
		return await $delete(appStore.resourceUrl('/attachment'), params);
	}

}
