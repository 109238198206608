<template>


  <div>
    <template v-if="context.parent && context.parent.tag==='subForm'">
      <div v-if="type==='fill'" class="select-form">
        <div style="flex: 1">
          <el-tag v-for="(item,index) in data[element.id]" :key="index" closable effect="plain" @close="remove(index)">
            {{ map[item] }}
          </el-tag>
        </div>
        <el-button v-prevent-re-click icon="Connection" class="connect" link @click.stop="handleSelectReferenceForm"></el-button>
      </div>
      <div v-else-if="type==='detail'">
        <el-link v-for="(item,index) in data[element.id]" :key="index" @click="handleViewReferenceForm(index)" type="primary">
          {{ map[item] }}
          <b v-if="index<data[element.id].length-1" style="margin: 0 4px">,</b>
        </el-link>
        <span v-if="!data[element.id] || data[element.id].length===0">⠀</span>
      </div>
    </template>

    <el-form-item v-else :required="context.authentication.isRequired(element)">
      <template #label>
      <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
      </template>

      <div v-if="type==='fill'" class="select-form">
        <div style="flex: 1">
          <el-tag v-for="(item,index) in data[element.id]" :key="index" closable effect="plain" @close="remove(index)">
            {{ map[item] }}
          </el-tag>
        </div>
        <el-button v-prevent-re-click icon="Connection" class="connect" link @click.stop="handleSelectReferenceForm"></el-button>
      </div>

      <div v-else-if="type==='detail'" class="design-detail">
        <el-link v-for="(item,index) in data[element.id]" :key="index" @click="handleViewReferenceForm(index)" type="primary">
          {{ map[item] }}
          <b v-if="index<data[element.id].length-1" style="margin: 0 4px">,</b>
        </el-link>
        <span v-if="!data[element.id] || data[element.id].length===0">⠀</span>
      </div>
    </el-form-item>

    <form-data-select ref="formDataSelect" multiple @callback="handleSelect"></form-data-select>
    <app-form-view ref="appFormView"></app-form-view>
  </div>


</template>

<script>

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import {useFormStore} from "@/stores/form";
import FormDataSelect from "@/components/common/formDataSelect.vue";
import AppFormView from "@/components/app-form/app-form-view.vue";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip, AppFormView, FormDataSelect},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      type: '',
      value: [],
      map: {}
    }
  },
  computed: {
    ...mapStores(useFlowStore, useFormStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
    this.$watch(`data.${this.element.id}`, (newVal, oldVal) => {
      if (newVal)
        newVal.forEach((item) => this.findColumnsById(item))
    }, {
      deep: true,
      immediate: true
    })
  },
  methods: {
    handleSelect(val) {
      this.data[this.element.id] = val;
    },
    async findColumnsById(id) {
      this.formStore.findColumnsById(this.element.attrs.form_id, id, this.element.attrs.show_columns).then((res) => {
        this.map[id] = res.data;
      })
    },
    handleSelectReferenceForm() {
      this.$refs.formDataSelect.call(this.element.attrs.form_id, this.data[this.element.id])
    },
    handleViewReferenceForm(index) {
      if (this.element.attrs.form_id && this.data[this.element.id]) {
        this.$refs.appFormView.call(this.element.attrs.form_id, this.data[this.element.id][index])
      }
    },
    remove(index) {
      this.data[this.element.id].splice(index, 1)
      this.value.splice(index, 1)
    }

  }
}

</script>

<style scoped lang="scss">

.el-tag {
  margin: 4px;
}

.select-form {
  border: 1px solid #DCDFE5;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  min-height: 28px
}

.connect {
  font-size: 16px;
  margin-right: 10px
}

</style>