<template>

  <el-dialog :title="title" v-model="dialogVisible" :modal="true" :append-to-body="true" :close-on-click-modal="false"
             :modal-append-to-body="true" :destroy-on-close="true" top="0" @close="content=''" width="50%" draggable>
    <el-scrollbar  max-height="height+'px'">
      <div v-if="loading" v-loading="loading"  element-loading-text=" 正在读取文件内容，请稍候..." style="width: 100%;height: 400px">


      </div>
      <div v-html="content" style="text-align: center;"></div>
    </el-scrollbar>
  </el-dialog>

</template>

<script>
import {mapStores} from "pinia";
import {useAttachmentStore} from "@/stores/attachment";

export default {
  data() {
    return {
      loading: false,
      title: '',
      dialogVisible: false,
      content: '',
      height:0,
    }
  },
  computed: {
    ...mapStores(useAttachmentStore)
  },
  created() {

  },
  methods: {
    call(id, title) {
      this.title = title
      this.loading = true;
      this.height = window.innerHeight;
      this.dialogVisible = true

      this.attachmentStore.preview(id).then(res => {
        this.loading = false;
        if (res.status === 'success') {
          this.content = res.data
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
:deep(.el-dialog__header) {
  width: 100%;
  position: fixed;
  background-color: #fff;
  // border-bottom: 1px #f0f2f5 solid;
  box-shadow: inset 0 -1px 0 0 #f0f2f5;
}

</style>