<template>
  <div>
    <el-dialog title="导入数据" v-model="isShow" :close-on-click-modal="false" :close-on-press-escape="false"
               append-to-body :modal="true" width="800px" simple @close="stepActive=1" style="border-radius: 10px">
      <div>
        <el-steps :space="200" :active="stepActive" align-center style="margin-top: 20px">
          <el-step title="选择Excel表"></el-step>
          <el-step title="数据预览"></el-step>
          <el-step title="导入设置"></el-step>
          <el-step title="导入数据"></el-step>
        </el-steps>
        <div class="content">
          <div v-show="stepActive===1">
            <dragFileUpload style="text-align: center;" @onUploadSuccess="onUploadSuccess"
                            :action="formTemplateStore.uploadFormDataUrl()"
                            :headers="{
                              'Authorization':userStore.getToken
                            }"></dragFileUpload>
            <h4 style="display: flex;align-items: center;color: #364260;font-size: 13px">
              <el-icon style="margin-right: 4px" :size="16">
                <UploadFilled/>
              </el-icon>
              为了保证数据导入顺利， 推荐您下载使用
              <import-template :subForm="subForm"></import-template>
              ，并按照规范示例录入数据
            </h4>
            <div>
              <p class="excel-tip-title"> 上传的 Excel 表应符合以下规范:</p>
              <p class="excel-tip-list"><span>• 文件大小不超过<span class="excel-tip-list--strong">20MB</span>，且单个sheet页数据量不超过10000行</span>
              </p>
              <p class="excel-tip-list">• 仅支持 <span class="excel-tip-list--strong">(*.xls 和 *.xlsx)</span>文件</p>
              <p class="excel-tip-list"><span>• 请确保您需要导入的sheet表头中<span class="excel-tip-list--strong">不包含空的单元格</span>，否则该sheet页数据系统将不做导入</span>
              </p>
              <p class="excel-tip-list"><span>• 导入文件<span class="excel-tip-list--strong">不支持Excel公式计算</span>，如SUM，=H2*J2等</span>
              </p>
              <p class="excel-tip-list">• 导入<span class="excel-tip-list--strong">关联表单</span>的值，请确保和表单<span
                  class="excel-tip-list--strong">数据标题</span>相匹配</p>
              <p class="excel-tip-list">• 暂不支持<span
                  class="excel-tip-list--strong">图片、关联表单下拉多选、多选、用户、部门</span>组件类型</p>
            </div>
          </div>
          <div v-show="stepActive===2">
            <el-form inline>
              <el-form-item label="工作表" required>
                <el-select v-model="selectSheet" style="width: 240px" @change="preview()">
                  <el-option v-for="(sheet,index) in sheets" :key="index" :label="sheet.name"
                             :value="sheet.index"></el-option>
                </el-select>
              </el-form-item>
            </el-form>

            <span style="font-size: 12px;color: #A8ABB0;">数据预览最多展示100条数据</span>
            <p v-if="!previewList">
              <el-tag style="width: 100%;text-align: center;" icon="Warning" type="warning">表格数据不符合规范</el-tag>
            </p>
            <el-table v-else :data="previewList" border size="small" :row-class-name="tableRowClassName"
                      :header-cell-style="{color:'#344262','background-color':'#F5F8FC'}"
                      :max-height="270" style="margin: 20px 0;border-radius: 6px" v-loading="previewLoading">
              <el-table-column label="" align="center" width="50">
                <template #default="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column width="100" v-for="(val,key,i) in previewList[0]" :key="i" :label="key" :prop="key"
                               align="center">
                <template #default="scope">
                  <span class="oneLine">{{ scope.row[key] }}</span>
                </template>
              </el-table-column>
              <el-table-column></el-table-column>
            </el-table>

            <div style="text-align: right;width: 100%;">
              <el-button v-prevent-re-click @click="stepActive=1">上一步</el-button>
              <el-button v-prevent-re-click type="primary" @click="gotoStep3()"
                         :disabled="previewList==null || previewList.length===0">下一步
              </el-button>
            </div>
          </div>
          <div v-show="stepActive===3">

            <el-form label-position="top">
              <el-form-item label="对应关系">
                <el-table :data="list" style="margin-bottom: 20px;width: 100%"
                          :header-cell-style="{color:'#344262','background-color':'#F5F8FC'}" border>
                  <el-table-column v-for="(value,key,index) in list[0]" :key="index" width="200" :label="key"
                                   align="center" min-width="100">
                    <template #default="scope">
                      <el-select v-model="scope.row[key]" filterable clearable style="font-size: 13px">
                        <el-option v-for="(column,columnIndex) in columns" :key="columnIndex"
                                   :label="'当前表单.'+column.label" :value="column.id"></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column></el-table-column>
                </el-table>
              </el-form-item>
            </el-form>


            <div style="text-align: right;width: 100%;">
              <el-button @click="stepActive=2">上一步</el-button>
              <el-button type="primary" @click="gotoStep4">导入
              </el-button>
            </div>
          </div>
          <div v-show="stepActive===4">
            <el-result icon="success">
              <template #icon>
                <el-image v-if="isImporting" style="width: 200px;" :src="loadingImage"></el-image>
                <template v-else>
                  <el-image v-if="importedMessage.startsWith('导入失败')" style="width: 200px;"
                            :src="failImage"></el-image>
                  <el-image v-else style="width: 200px;" :src="doneImage"></el-image>
                </template>

              </template>
              <template #extra>
                <label v-if="isImporting">系统正在处理中，请稍等...</label>
                <label v-else>{{ importedMessage }}</label>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useFormTemplateStore} from '@/stores/formTemplate'
import {useUserStore} from "@/stores/user";
import {useMenuStore} from "@/stores/menu";
import {useFormStore} from "@/stores/form";
import dragFileUpload from './dragFileUpload.vue'
import ImportTemplate from "@/components/import/excel/importTemplate.vue";

export default {
  emits: ['callback'],
  components: {
    ImportTemplate,
    dragFileUpload,
  },
  props: {
    subForm: {
      type: String,
      default: () => ""
    },
  },
  computed: {
    ...mapStores(useFormTemplateStore, useUserStore, useMenuStore, useFormStore)
  },
  data() {
    return {
      form: null,
      dialogVisibleTemplateManager: false,
      previewLoading: false,
      stepActive: 1,
      isShow: false,
      sheets: [],
      previewList: [],
      isImporting: false,
      importedMessage: '',
      loadingImage: require('@/assets/loading.gif'),
      doneImage: require('@/assets/svg/done.svg'),
      failImage: require('@/assets/svg/fail.svg'),
      selectSheet: null,
      columns: [],
      selectName: new Date().toDateString(),
      list: [],
    }
  },
  created() {

    let formId = this.getParam('form_id')
    this.menuStore.findById(formId).then(response => {
      this.form = response.data;
    })

  },
  methods: {
    gotoStep3() {
      let selectKvMapper = {}
      this.list.splice(0, this.list.length)
      let firstRow = this.previewList[0]
      for (let key in firstRow) {
        this.columns = this.formStore.columns(this.form).filter(filter => filter.id.indexOf('_property') === -1);
        if (this.subForm) {
          this.columns = this.columns.find(find => find.id === this.subForm).children;
        }
        selectKvMapper[key] = this.columns.find(filter => filter.label === key)?.id || '';
      }

      this.list.push(selectKvMapper)
      this.stepActive = 3
    },
    gotoStep4() {
      this.stepActive = 4
      this.isImporting = true;

      this.formTemplateStore.import(this.form.id, this.selectSheet, this.list[0],this.subForm).then(res => {
        this.isImporting = false;
        if (res.status === 'success') {
          this.importedMessage = `导入完毕， 成功添加数据 ${res.data.count} 条`
          this.$emit('callback', res.data.list)
        } else {
          this.importedMessage = '导入失败:' + res.message
        }
      })


    },
    call() {
      this.isShow = true
    },
    onUploadSuccess(sheets) {

      if (sheets.length > 0) {
        this.stepActive += 1;
        this.sheets = sheets;
        this.selectSheet = this.sheets[0].index
        if (this.selectSheet != null) this.preview()
      } else {
        this.$message.error('没有可读取的sheet')
      }

    },
    preview() {
      this.previewList = [];
      this.previewLoading = true;

      this.formTemplateStore.preview(this.selectSheet).then(res => {
        this.previewLoading = false;
        if (res.status === 'success') {
          this.previewList = res.data.length > 0 ? res.data : null;
        }
      })

    },
    tableRowClassName({row, rowIndex}) {
      if ((rowIndex + 1) % 4 == 1) {
        return 'warning-row';
      } else if ((rowIndex + 1) % 4 == 3) {
        return 'success-row';
      }

      return '';
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.el-step__title) {
  font-size: 14px;
}

.dragFileUpload {
  margin-top: 60px;
}

.content {
  margin-top: 10px;
  padding: 20px;
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.excel-tip-title {
  font-size: 12px;
  color: #38425E;
  letter-spacing: 0;
  line-height: 12px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.excel-tip-list {
  text-indent: 10px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 2px;
  color: #A8ABB1;
  font-size: 12px;
}

.excel-tip-list--strong {
  margin-left: 4px;
  margin-right: 4px;
  color: #364260;
  font-weight: 700;
}
</style>
