import {defineStore} from 'pinia'
import templateController from "@/controller/formTemplateController";
import {useAppStore} from "@/stores/app";
import {ElMessageBox} from "element-plus";
import {download, post} from "@/plugins/axios";

export const useFormTemplateStore = defineStore('formTemplate', {
    state: () => {
        return {}
    },
    actions: {
        uploadFormDataUrl() {
            return useAppStore().uploadFormDataUrl();
        },
        uploadFormTemplateUrl(formId) {
            return useAppStore().uploadFormTemplateUrl(formId);
        },
        listTemplate(formId,subForm){
            return templateController.listTemplate(formId,subForm);
        },
        downloadDefaultTemplate(name,formId,subForm){
            return templateController.downloadDefaultTemplate(name,formId,subForm);
        },
        downloadTemplate(name,fileId){
            return templateController.downloadTemplateByFileId(name,fileId);
        },
        deleteTemplate(fileId){
            return templateController.deleteTemplate(fileId);
        },
        preview(sheet_index){
            return templateController.preview(sheet_index);
        },
        import(formId, selectSheet, mapper,subForm="") {
            return templateController.import({
                formId,
                selectSheet,
                mapper:JSON.stringify(mapper),
                subForm
            });
        },
    },
    getters: {},
    persist: {
        enabled: false
    }
})