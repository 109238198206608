import {defineStore} from 'pinia'
import menuController from "@/controller/menuController";
import {ElMessage, ElMessageBox} from 'element-plus'
import {useUserStore} from "@/stores/user";
import {ElNotification} from 'element-plus'

export const useMenuStore = defineStore('menu', {
    state: () => {
        return {}
    },
    actions: {
        async list(types, application_id) {
            return await menuController.list({types, application_id});
        },
        async findById(id) {
            return await menuController.findById(id);
        },
        delete(id) {
            return menuController.delete(id);
        },
        groups(application_id) {
            return menuController.groups({application_id});
        },
        /**
         * 表单新增请使用 useFormStore#sumbit,此方法只针对除表单外类型
         * @param data
         * @param callback
         */
        submit(data, callback) {
            menuController.post(data).then(res => {
                if (callback) {
                    callback()
                }
            });
        },
        copyTo(application_id, id, company, name, callback) {

            ElMessageBox.prompt('输入新的名称', '消息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                autofocus:true,
                inputValue:name
            })
                .then(({value}) => {
                    if(value){
                        menuController.copyTo(application_id, id, company, value).then(({code}) => {
                            if (code === 200) {
                                callback();
                            }
                        })
                    }

                })
                .catch(() => {
                })

            return
        }
    },
    getters: {},
    persist: {
        enabled: false
    }
})