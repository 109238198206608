<template>
  <el-tooltip v-if="element.describe" :content="element.describe" placement="right-start">
    <template #content>
      <div style="width: 240px;font-size: 13px;color: white;border-radius: 6px"
           v-html="element.describe.replace(/\n/g, '<br>')"></div>
    </template>
    <span class="info">?</span>
  </el-tooltip>

</template>

<script>
export default {

  props: {
    element: {
      type: Object,
      default: () => {
      }
    }
  }

}

</script>


<style scoped lang="scss">

.info {
  background-color: #030F1C;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  padding: 0 4px;
  line-height: 1;
  margin-left: 5px;
}

</style>