<template>


  <div>

    <template v-if="context.parent && context.parent.tag==='subForm'">
      <address-select v-if="type==='fill'" v-model="data[element.id]" :level="element.attrs.level" ></address-select>
      <div v-else-if="type==='detail'" style="display: flex;align-items: center">
        {{data[element.id] ? data[element.id].join(" ") : ''}}
      </div>
    </template>

  <el-form-item v-else :required="context.authentication.isRequired(element)">
    <template #label>
     <span :style="{color:'#344262','font-weight':context.form.setting.style.PC.title}">{{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <address-select v-if="type==='fill'" v-model="data[element.id]" :level="element.attrs.level" ></address-select>
    <div v-else-if="type==='detail'" class="design-detail" style="display: flex;align-items: center">
       {{data[element.id] ? data[element.id].join(" ") : ''}}
    </div>
  </el-form-item>

  </div>
</template>

<script>

import AddressSelect from "@/components/common/addressSelect.vue";

import {mapStores} from "pinia";
import {useFlowStore} from "@/stores/flow";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip, AddressSelect},
  props: {
    context:{
      type:Object,
      default:()=>{}
    },
    element:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      data:{},
      type: '',
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
  },
  methods:{

  }
}

</script>

<style scoped lang="scss">

</style>