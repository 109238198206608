<template>

  <el-timeline :reverse="true" v-loading="loading" element-loading-text="努力加载中...">
    <el-timeline-item v-for="(record, recordIndex) in list" :type="getType(record)" :key="recordIndex"
                      :timestamp="record.time.replaceAll('-','/')">
      <template #default>

        <div v-if="record.event==='create'" class="item">
          <div class="title1">创建
            <el-icon color="#F1CB5F" style="margin-left: 10px" :size="14">
              <BellFilled/>
            </el-icon>
          </div>
          <div style="display: flex;align-items: center;" class="title2">
            表单由
            <user-card :value="record.actor" placement="bottom-start">
              <template #default="scope">
                <div style="display: flex;align-items: center;margin: 0 0 0 10px">
                  <el-avatar :src="userStore.icon(scope.user.icon)" :size="30"></el-avatar>
                  <el-button link type="primary" style="font-weight: normal;margin-left: 2px;font-size: 13px">
                    {{ scope.user.real_name }}
                  </el-button>
                </div>
              </template>
            </user-card>
            创建
          </div>
        </div>

        <div v-else-if="record.event==='update'" class="item">
          <div class="title1">️数据变更
            <el-icon color="#F1CB5F" style="margin-left: 10px" :size="14">
              <BellFilled/>
            </el-icon>
          </div>
          <div style="display: flex;align-items: center;" class="title2">
            <span style="font-size: 16px">@ </span>
            <user-card :value="record.actor" placement="bottom-start">
              <template #default="scope">
                <div style="display: flex;align-items: center;margin: 0 0 0 10px">
                  <el-avatar :src="userStore.icon(scope.user.icon)" :size="30"></el-avatar>
                  <el-button link type="primary" style="font-weight: normal;font-size: 13px">
                    {{ scope.user.real_name }}
                  </el-button>
                </div>
              </template>
            </user-card>
          </div>
          <div class="change">
            <div v-for="(item,index) in record.items" :key="index" style="display: flex;align-items: flex-start">
              <span class="column">{{ item.column.label }}</span>由
              <span class="before">
                <column-show v-if="item.before" :value="item.before" container="table"
                             :column="item.column"></column-show>
                <template v-else>空</template>
              </span>
              改为
              <span class="after">
                 <column-show v-if="item.after" :value="item.after" container="table"
                              :column="item.column"></column-show>
                 <template v-else>--</template>
              </span>
            </div>
          </div>
        </div>


      </template>
    </el-timeline-item>
  </el-timeline>

</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";
import {useFormStore} from "@/stores/form";
import UserCard from "@/components/common/userCard.vue";

export default {
  components: {UserCard},
  props: {
    form_id: {
      type: String,
      default: () => ""
    },
    data_id: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    ...mapStores(useUserStore, useFormStore),
  },
  data() {
    return {
      list: [],
      loading: false,
    }
  },
  created() {
    this.loading = true;
    this.formStore.getTimeline(this.form_id, this.data_id).then(({data}) => {
      this.loading = false;
      this.list = data;
      this.list.sort((o1, o2) => {
        return new Date(o2.time).getTime() - new Date(o1.time).getTime();
      })
    })
  },
  methods: {
    getType(item) {
      if (item.event === 'create') {
        return 'success'
      } else if (item.event === 'update') {
        return 'warning'
      }
      return '';
    }
  }

}
</script>


<style scoped lang="scss">

.item {
  background-color: #F8FAFC;
  min-height: 20px;
  padding: 10px 20px 20px 20px;
  border-radius: 4px;
}

.el-tag {
  padding: 0 8px 0 8px;
  height: 22px;
  margin: 0 4px;
}

.title1 {
  color: #364260;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.title2 {
  color: #525D77;
  font-size: 13px;
}

.change {
  margin-top: 20px;
  color: white;
  font-size: 13px;
  background-color: #292C33;
  padding: 15px 20px;
  line-height: 26px;
  border-radius: 4px;

}

.column {
  color: #F1C668;
  margin-right: 4px;
}

.before {
  color: #E39157;
  margin: 0 4px 0 4px;
}

.after {
  color: #E39157;
  margin: 0 4px 0 6px;
}

</style>