<template>
  <el-upload :action="action" :headers="headers" :before-upload="beforeAvatarUpload" :on-success="handelUploadSuccess" :show-file-list="false" drag>
    <i class="el-icon-upload"></i>
    <div class="el-upload__text">
      <div> 将文件拖到此处，或 <em>点击上传</em></div>
      <div style="color: #A8ABB0;font-size: 12px;margin-top: 2px">支持 xls,xlsx 类型的文件</div>
    </div>
  </el-upload>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: "",
    },
    headers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fileFormat: {
      type: Array,
      default: () => ['xls', 'xlsx']
    },
    fileSize: {
      type: Number,
      default: () => 20
    },
  },
  data() {
    return {

      list: []
    }
  },
  created() {
  },
  methods: {
    beforeAvatarUpload(file) {

      let file_format_check = false;

      for (let i = 0; i < this.fileFormat.length; i++) {
        if (file.name.toLowerCase().endsWith(this.fileFormat[i])) {
          file_format_check = true;
          break;
        }
      }

      if (!file_format_check) {
        this.message(`上传仅支持 ${this.fileFormat.toString().toUpperCase()} 格式`,'error');
        return false;
      }

      if (!(file.size / 1024 / 1024 < this.fileSize)) {
        this.$message.error('上传文件大小不能超过 ' + this.fileSize + 'MB!');
        return false;
      }

      return true;
    },
    handelUploadSuccess(res) {
      this.$emit('onUploadSuccess', res.data)
      this.$emit('input', res.data)
    },
  }

}
</script>

<style scoped lang="scss">
</style>
