<template>

  <el-popover trigger="click" width="380" :placement="placement">

    <div style="padding: 10px" v-loading="loading" element-loading-text="努力加载中...">
      <div style="display: flex;align-items: self-start">
        <el-avatar :src="userStore.icon(user.icon)" shape="square" :size="95"></el-avatar>
        <div style="margin-left: 20px;">
          <div style="font-size: 18px;font-weight: bold;margin-bottom: 10px">{{ user.real_name }}</div>
          <p>
            <span class="title">性别</span>
            <el-icon v-if="user.sex==='男'" color="#409EFF" :size="16">
              <Female/>
            </el-icon>
            <el-icon v-else-if="user.sex==='女'" color="#F56C6C" :size="16">
              <Male/>
            </el-icon>
            <span v-else>未知</span>
          </p>
          <p>
            <span class="title">电子邮箱</span>
            <span class="detail">{{ user.email }}</span>
          </p>
          <p>
            <span class="title">手机号码</span>
            <span class="detail">{{ user.cellphone }}</span>
          </p>
        </div>
      </div>
    </div>


    <template #reference>
      <slot :user="user"></slot>
    </template>

  </el-popover>

</template>

<script>
import {mapStores} from "pinia";
import {useUserStore} from "@/stores/user";

export default {
  props: {
    value: {
      type: [String, Object],
      default: () => ""
    },
    placement: {
      type: String,
      default: () => "bottom-end"
    }
  },
  computed: {
    ...mapStores(useUserStore)
  },
  data() {
    return {
      user: {},
      loading: false,
    }
  },
  created() {
    if (typeof this.value === 'string') {
      if(this.value){
        this.loading = true;
        this.userStore.findById(this.value).then(res => {
          this.user = res.data;
          this.loading = false;
        })
      }

    } else {
      this.user = this.value
    }


  },
  methods: {

  }


}

</script>

<style scoped lang="scss">
p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 10px;
}

.title {
  margin-right: 10px;
}

.detail {
  color: #111D33;
}
</style>