<template>

  <el-popover ref="popover" placement="right-start" width="260" @show="queryList()" trigger="click">
    <el-descriptions title="导入模版" :column="1" size="small">
      <el-descriptions-item label="默认模板">
        <el-button type="primary" @click="downloadDefaultTemplate()" icon="Download" text size="small">点击下载
        </el-button>
      </el-descriptions-item>
      <el-descriptions-item v-for="(item,index) in templates" :key="index" :label="item.file_name">
        <el-button v-prevent-re-click title="下载" type="primary" @click="downloadTemplate(item.file_name,item.file_id)"
                   icon="Download"></el-button>
      </el-descriptions-item>
    </el-descriptions>
    ˙
    <div style="text-align: right; margin: 20px 0 0 0">
      <el-button link @click="$refs.popover.hide()">关闭</el-button>
    </div>
    <template #reference>
      <el-button type="primary" style="color: #0089ff;" text @click="visible=true">导入模板</el-button>
    </template>

  </el-popover>

</template>

<script>
import {mapStores} from "pinia";
import {useFormTemplateStore} from '@/stores/formTemplate'

export default {
  props: {
    subForm: {
      type: String,
      default: () => ""
    },
  },
  data() {
    return {
      visible: false,
      formId: null,
      templates: [],
    }
  },
  computed: {
    ...mapStores(useFormTemplateStore)
  },
  created() {
    this.formId = this.getParam('form_id')
  },
  methods: {
    queryList() {
      this.formTemplateStore.listTemplate(this.formId, this.subForm).then(({data}) => {
        this.templates = data;
      })

    },
    downloadDefaultTemplate() {
      this.formTemplateStore.downloadDefaultTemplate(new Date().toGMTString() + '.xlsx', this.formId, this.subForm);
    },
    downloadTemplate(name, file_id) {
      this.formTemplateStore.downloadTemplateByFileId(name, file_id);
    },
  }

}

</script>

<style scoped lang="scss">

</style>