

<template>

  <el-popover trigger="click" width="400" placement="bottom-end">

    <div v-if="dept" style="padding: 10px">
     <div style="display: flex;align-items: self-start">
       <el-avatar  shape="square" :size="80" fit="fill" style="background-color: #5580D6">{{dept.name.length>4?dept.name.substr(0,4):dept.name}}</el-avatar>
       <div style="margin-left: 20px;">
         <div style="font-size: 18px;font-weight: bold;margin-bottom: 10px">{{dept.name}} ({{ dept.count }}人)</div>
           <p>
             <span class="title">部门主管</span>
             <span class="detail">
               {{ dept.chargeNames.join(",") }}
             </span>
           </p>
           <p >
             <span class="title">下级部门</span>
             <span class="detail">
               {{ dept.children.join(",") }}
             </span>
           </p>
       </div>
     </div>
    </div>


    <template #reference>
      <slot></slot>
    </template>

  </el-popover>

</template>

<script>
import {mapStores} from "pinia";
import {useDeptStore} from "@/stores/dept";

export default {
  props:{
    value:{
      type:[String,Object],
      default:()=>""
    }
  },
  computed:{
    ...mapStores(useDeptStore)
  },
  data(){
    return {
      user:null
    }
  },
  created() {
    if(typeof this.value==='string'){
      this.deptStore.findById(this.value).then(res=>{
        this.dept = res.data;
      })
    }else{
      this.dept = this.value
    }

  }


}

</script>

<style scoped lang="scss">
p{
  display: flex;
  align-items: center;
  margin-bottom: 0;
  margin-top: 10px;
}

.title{
  margin-right: 10px;
}

.detail{
  color: #111D33;
}
</style>