import {defineStore} from 'pinia'


export const useAppStore = defineStore('app', {
    state: () => {
        return {
            name: 'noCode',
            version: '1.0.0',
            //资源服务器（静态资源，上传/下载），开发环境下等于服务端地址，正式上线后可更改
            resourceServer: '/api',
            colors: [
                {
                    front: '#FFFFFF',
                    background: '#EA4E27',
                },
                {
                    front: '#FEFBFE',
                    background: '#3F99F6',
                },
                {
                    front: '#FFFFFF',
                    background: '#FADB7E',
                },
                {
                    front: '#FFFFFF',
                    background: '#6FD766',
                },
                {
                    front: '#F9FBF4',
                    background: '#6982F4',
                },
                {
                    front: '#FCF0D0',
                    background: '#975EA1',
                },
                {
                    front: '#F1EAFC',
                    background: '#956BEE',
                },
                {
                    front: '#9ED2CA',
                    background: '#000000',
                },
                {
                    front: '#DBD4CC',
                    background: '#333843',
                },
                {
                    front: '#FDFDFD',
                    background: '#010101',
                },
                {
                    front: '#F5CA6D',
                    background: '#303030',
                },
            ],
            iconCount: 72,
        }
    },
    getters: {
        getVersion() {
            return this.version;
        }
    },
    actions: {
        resourceUrl(path) {
            return this.resourceServer + (path.startsWith("/") ? path : `/${path}`);
        },
        downloadUrl(id) {
            return this.resourceServer + `/attachment/download/${id}`;
        },
        uploadUrl() {
            return this.resourceServer + '/attachment/upload';
        },
        uploadFormDataUrl() {
            return this.resourceServer + `/form-template/upload`;
        },
        avatarUploadUrl() {
            return this.resourceServer + '/user/avatarUpload';
        },
        imageUploadUrl() {
            return this.resourceUrl('/image/upload');
        },
        imageDownloadUrl(id) {
            return this.resourceServer + `/image/download/${id}`;
        },
        randomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        initAppData() {
            return {
                name: '未命名的应用',
                introduced: '',
                icon: '',
                icon_phone: {
                    value: parseInt(this.randomInt(1,this.iconCount)),
                    front: '#FFFFFF',
                    background: '#EA4E27',
                },
            }
        }
    },
    persist: {
        enabled: false
    }
})