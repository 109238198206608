<template>

  <el-row style="min-height: 96px; position: static;" class="designHbox">
    <el-col v-for="index in element.attrs.cols" :key=index :span="span(index)">

      <draggable :list="element[`list${index}`]"
                 :group="{ name:'design',pull:true,put:()=> onPut(index)}"
                 ghost-class="ghostClass" name="hbox"
                 chosen-class="chosenClass" drag-class="dragClass" :force-fallback="true" animation="300" :key="index"
                 item-key="id" style="min-height: 60px;width: 100%;background-color: transparent;"
                 :fallbackTolerance="10" :move="formStore.onDraggableMove" @add="onAdd(index)">
        <template #header>
          <div v-if="element[`list${index}`].length===0" style="font-size: 14px;color: #959EAD;text-align: center;">
            从左侧拖拽来添加字段
          </div>
          <el-button v-prevent-re-click
                     v-if="index!==1 && element[`list${index}`].length!==0 && element[`list${index-1}`]!==0"
                     icon="Switch" type="primary" circle style="float: left" link size="small"
                     @click="handleSwitch(index)"></el-button>
        </template>
        <template #item="scope">
          <design-wrapper :context="context" :element="scope.element" :parent="element[`list${index}`]">
            <component :is="`design${scope.element.tag.charAt(0).toUpperCase() + scope.element.tag.slice(1)}`"
                       :context="{...context,parent:element}" :element="scope.element"
                       :parent="element[`list${index}`]"></component>
          </design-wrapper>
        </template>

      </draggable>
    </el-col>
  </el-row>

</template>

<script>
import draggable from "vuedraggable";
import designWrapper from "@/components/design-form/items/designWrapper.vue";
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";

export default {
  components: {
    draggable, designWrapper
  },
  computed: {
    ...mapStores(useFormStore)
  },
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSwitch(index) {

      let element = this.element;

      let temp = element[`list${index}`][0];
      let last = element[`list${index - 1}`][0];

      element[`list${index}`] = [last];
      element[`list${index - 1}`] = [temp];
    },
    span(index) {

      try {
        return 24 * eval(this.element.attrs.formatOptions[this.element.attrs.cols.toString()][this.element.attrs.format][index - 1])
      } catch (e) {
        return 24 / this.element.cols;
      }

    },
    onPut(index){
     return  this.element[`list${index}`].length===0
    },
    onAdd(index){

      let element = this.element[`list${index}`][0];

      let columns = this.formStore.columns();
      if (element.tag === 'coding' && columns.some(some => some.tag === 'coding' && some.id !== element.id)) {
        let element = this.element;
        element[`list${index}`].splice(0,1);
        this.formStore.onDeleteElementCauseInfluence(element.id)
      }

    }
  }
}

</script>

<style scoped lang="scss">

</style>