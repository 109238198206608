

<template>

  <div class="layout">
    <span style="font-size: 16px;font-weight: bold">{{ element.label }}</span>
    <el-divider></el-divider>
  </div>

</template>

<script>
export  default {
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: this.element
    }
  },

}

</script>

<style scoped lang="scss">
.el-divider--horizontal{
  margin:  10px 0;
}


</style>