<template>

  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{element.label}}
       <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>


    <div style="width: 100%">
      <el-button icon="Camera" style="color: #191E25;font-weight: normal">识别填充 </el-button>
      <div  v-if="!context.parent || context.parent.tag!=='subForm'" style="font-size: 12px;color: #8A94A5;line-height: 16px;margin-top: 10px">请确保识别图片朝向正确、清晰、不反光、不过曝和不欠曝（亮度过低）</div>
    </div>

  </el-form-item>
</template>

<script>

import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import {_ocrTypeColumns} from "@/pojo/data-design-form-aside";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: this.element
    }
  },
  created() {


  },
  mounted() {
    let element = this.element;
    let parent = this.context.parent ? this.context.parent.tag : '';
    if (parent !== element.attrs.parent && (parent==='subForm' || element.attrs.parent==='subForm')) {
      element.attrs.operation = this.deepClone(_ocrTypeColumns[element.attrs.type]);
    }

    element.attrs.parent = parent;
  }
}

</script>

<style scoped lang="scss">

</style>