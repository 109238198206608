<template>

  <!-- 人员 -->
  <el-form-item :label="column.label" v-if="filter.tag==='people' " label-width="110px">
    <el-select style="width: 100px" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <user-select width="60%" height="23px" v-model="condition.value" @change="$emit('change')"></user-select>
  </el-form-item>

  <!-- 部门 -->
  <el-form-item :label="column.label" v-if="filter.tag==='dept' " label-width="110px">
    <el-select style="width: 100px" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <dept-select   v-model="condition.value" @change="$emit('change')"></dept-select>
  </el-form-item>

  <!-- 关联表单 -->
  <el-form-item :label="column.label" v-if="filter.tag==='referenceForm'" label-width="110px">
    <el-select style="width: 100px" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-input style="width: 60%;" :model-value="text" @click="$refs.formDataSelect.call(filter.attrs.form_id)" clearable
              @clear="text='';condition.value='';$emit('change')"></el-input>
  </el-form-item>

  <!-- 文本 -->
  <el-form-item :label="column.label" v-if="filter.tag==='input' || filter.tag==='textarea' || filter.tag==='coding'" label-width="110px">
    <el-select style="width: 100px" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
      <el-option label="包含" value="CONTAINS"></el-option>
      <el-option label="开始以" value="START_WITH"></el-option>
      <el-option label="结束以" value="END_WITH"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-input style="width: 60%;" v-model="condition.value" clearable @input="handleChange()"></el-input>
  </el-form-item>

  <!-- 数值 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='number'" label-width="110px">
    <el-select style="width: 100px;" v-model="condition.cs" placeholder="请选择" @change="handleBetween">
      <el-option label="大于" value="GT"></el-option>
      <el-option label="小于" value="LT"></el-option>
      <el-option label="介于" value="BETWEEN"></el-option>
      <el-option label="大于等于" value="GT_EQUALS"></el-option>
      <el-option label="小于等于" value="LT_EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-input v-if="condition.cs!=='BETWEEN'" v-model="condition.value" type="number" style="width: 60%;"
              @change="condition.value=condition.value?Number(condition.value):condition.value;$emit('change')"
              clearable></el-input>
    <span v-if="condition.cs==='BETWEEN'">
			<el-input v-model="condition.value[0]" type="number" style="width: 26%;"
                @change="condition.value[0]=condition.value[0]?Number(condition.value[0]):condition.value[0]" clearable>
			</el-input>到
			<el-input v-model="condition.value[1]" type="number" style="width: 26%;"
                @change="condition.value[1]=condition.value[1]?Number(condition.value[1]):condition.value[1];$emit('change')"
                clearable>
			</el-input>
		</span>
  </el-form-item>

  <!-- 日期 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='date'" label-width="110px">
    <el-select style="width: 100px;" v-model="condition.cs" placeholder="请选择" @change="reset">
      <el-option label="大于" value="GT"></el-option>
      <el-option label="小于" value="LT"></el-option>
      <el-option label="等于" value="EQUALS"></el-option>
      <el-option label="介于" value="BETWEEN"></el-option>
      <el-option label="大于等于" value="GT_EQUALS"></el-option>
      <el-option label="小于等于" value="LT_EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;

    <el-date-picker v-if="condition.cs!=='BETWEEN' && column.attrs.format!=='HH:mm'" placeholder="请设置"
                    v-model="condition.value" :editable="false"
                    :type="getDateType"
                    :format="column.attrs.format" :value-format="column.attrs.format" style="width: 60%"
                    clearable @change="$emit('change')"></el-date-picker>

    <el-time-picker v-if="condition.cs!=='BETWEEN' && column.attrs.format==='HH:mm'"
                    v-model="condition.value" :editable="false"
                    placeholder="请设置"
                    :format="column.attrs.format" :value-format="column.attrs.format" style="width: 60%"
                    clearable @change="$emit('change')"></el-time-picker>


    <template v-if="condition.cs==='BETWEEN' && column.attrs.format!=='HH:mm'">
      <el-date-picker v-model="between0" :editable="false"
                      placeholder="请设置"
                      :type="getDateType"
                      :format="column.attrs.format" :value-format="column.attrs.format" style="width: 32%"
                      clearable @change="handleBetweenTimeChange()"></el-date-picker>
      &nbsp;至&nbsp;
      <el-date-picker v-model="between1" :editable="false"
                      placeholder="请设置"
                      :type="getDateType"
                      :format="column.attrs.format" :value-format="column.attrs.format" style="width: 32%"
                      clearable @change="handleBetweenTimeChange()"></el-date-picker>
    </template>

    <template v-if="condition.cs==='BETWEEN' && column.attrs.format==='HH:mm'">
      <el-time-picker v-model="between0" :editable="false"
                      placeholder="请设置"
                      :format="column.attrs.format" :value-format="column.attrs.format" style="width: 32%"
                      clearable @change="handleBetweenTimeChange()"></el-time-picker>

      &nbsp;至&nbsp;
      <el-time-picker v-model="between1" :editable="false"
                      placeholder="请设置"
                      :format="column.attrs.format" :value-format="column.attrs.format" style="width: 32%"
                      clearable @change="handleBetweenTimeChange()"></el-time-picker>

    </template>


  </el-form-item>

  <!-- 单选 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='radio' || filter.tag==='select'" label-width="110px">
    <el-select style="width: 100px;" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-select v-model="condition.value" placeholder="请选择" style="width: 60%;" filterable clearable
               @change="$emit('change')">
      <el-option v-for="(item,index) in filter.attrs.options" :key="index" :label="item" :value="item"></el-option>
    </el-select>
  </el-form-item>

  <!-- 是/否 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='switch'" label-width="110px">
    <el-select style="width: 100px;" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="EQUALS"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-radio-group v-model="condition.value" @change="$emit('change')">
      <el-radio label="全部" value=""></el-radio>
      <el-radio label="是" :value="true"></el-radio>
      <el-radio label="否" :value="false"></el-radio>
    </el-radio-group>
  </el-form-item>

  <!-- 多选 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='checkbox'" label-width="110px">
    <el-select style="width: 100px;" v-model="condition.cs" placeholder="请选择">
      <el-option label="等于" value="IN"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-select v-model="condition.value" placeholder="请选择" multiple style="width: 60%;" clearable
               @change="$emit('change')">
      <el-option v-for="(item,index) in filter.attrs.options" :key="index" :label="item" :value="item"></el-option>
    </el-select>
  </el-form-item>

  <!-- 地址 -->
  <el-form-item :label="column.label" v-else-if="filter.tag==='address'" label-width="110px">
    <el-select style="width: 100px" v-model="condition.cs" placeholder="请选择">
      <el-option label="包含" value="CONTAINS"></el-option>
    </el-select> &nbsp;&nbsp;
    <el-input style="width: 60%;" v-model="condition.value" clearable @change="$emit('change')"></el-input>
  </el-form-item>


  <form-data-select v-if="filter.tag==='referenceForm'" ref="formDataSelect" @callback="(data)=>{
      condition.value = data.id;
      text=data.title;
      $emit('change')
    }"></form-data-select>



</template>

<script>
import UserSelect from "@/components/common/userSelect.vue";
import DeptSelect from "@/components/explorer-dept/deptSelect.vue";

export default {
  components: { DeptSelect, UserSelect},
  emits: ['change'],
  props: {
    column: {
      type: Object,
      default: () => {
      },
    },
    filter: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    getDateType() {
      switch (this.column.attrs.format) {
        case 'YYYY-MM-DD':
          return 'date';
        case 'YYYY-MM':
          return 'month';
        case 'YYYY':
          return 'year';
        case 'YYYY-MM-DD HH:mm':
          return 'datetime';
      }

      return '';
    }
  },
  data() {
    return {
      condition: {
        fieldName: '',
        cs: '',
        value: '',
      },
      text:'',
      timer: null,
      between0: '',
      between1: '',
    }
  },
  created() {
    this.condition = {
      fieldName: this.filter.id,
      cs: '',
      value: ''
    }

    if (this.filter.tag === 'input' || this.filter.tag === 'textarea' || this.filter.tag === 'address' || this.filter.tag === 'coding'
        ) {
      this.condition.cs = 'CONTAINS';
    } else if (this.filter.tag === 'number') {
      this.condition.cs = 'GT';
    } else if (this.filter.tag === 'date') {
      this.condition.cs = 'GT';
    } else if (this.filter.tag === 'radio' || this.filter.tag === 'select' || this.filter.tag === 'people'
        || this.filter.tag === 'dept' ||this.filter.tag === 'switch' || this.filter.tag === 'referenceForm') {
      this.condition.cs = 'EQUALS';
    }else if (this.filter.tag === 'checkbox') {
      this.condition.cs = 'IN';
    }
  },
  methods: {
    reset() {
      if (this.condition.cs === 'BETWEEN') {
        this.between0 = '';
        this.between1 = '';
        this.condition.value = [];
      } else {
        this.condition.value = ''
      }
    },
    handleBetween() {
      if (this.condition.cs === 'BETWEEN') {
        this.condition.value = [];
      }
    },
    handleBetweenTimeChange() {
      // value[1] = value[1].split(" ")[0] + " 23:59:59"
      // condition.value = value;

      if (this.between0 && this.between1) {
        this.condition.value = [this.between0, this.between1];
        if (this.column.attrs.format === 'YYYY-MM-DD') {
          this.condition.value = [this.between0, this.between1 + " 23:59:59"];
        }
        this.$emit('change');
      } else if (!this.between0 && !this.between1) {
        this.condition.value = [];
        this.$emit('change');
      }


    },
    getCondition() {
      return this.condition;
    },
    handleChange() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.$emit('change')
        clearInterval(this.timer)
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
:deep( [class^="el-icon-"],
[class*=" el-icon-"]) {
  line-height: 0.6;
}

.el-form-item{
  display: flex;
  align-items: center;
}
</style>
