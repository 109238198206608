<template>

  <el-popover ref="popover" width="600px" trigger="click" placement="bottom-start" :show-arrow="false">
    <el-input style="margin:10px 0 10px 0" v-model="keyword" placeholder="搜索" prefix-icon="Search"
              autofocus></el-input>
    <el-segmented style="width: 100%;margin-bottom: 10px" v-model="segmented"
                  :options="['全部部门','我的部门']"></el-segmented>
    <el-row>
      <el-col :span="24" style="min-height: 270px">
        <el-scrollbar style="height: 350px;padding-left: 10px;padding-right: 10px;margin-top: 10px">
          <div style="color:rgb(80,95,125);cursor: pointer" @click="keyword='';dept=null">
            {{ companyStore.selectedName() }}
          </div>
          <el-tree ref="tree" :data="getDepts" style="padding: 4px;" check-strictly default-expand-all node-key="id" :props="{label: 'name', children: 'children'}"
                   :expand-on-click-node="false">
            <template #default="{ node,data }">
              <el-checkbox v-if="multiple" v-model="data.select" @change="handleChange(data)">
                <span style="color:rgb(48,66,101);font-size: 13px">{{ node.label }}</span>
              </el-checkbox>
              <el-radio v-else :key="`radio-${data.id}`" :value="true" v-model="data.select" @change="handleChange(data)">
                <span style="color:rgb(48,66,101);font-size: 13px;font-weight: normal">{{ node.label }}</span>
              </el-radio>

            </template>
          </el-tree>

        </el-scrollbar>
      </el-col>
    </el-row>

    <template #reference>
      <div :style="`border: 1px solid #E3E3E3;border-radius: 4px;width: ${width};min-height: 30px;padding-bottom: 2px`">
        <el-tag v-for="(item,index) in list" :key="item" style="margin: 2px" type="info" closable
                @close="onClose(index)">
        <span style="display: flex;align-items: center">
           <el-icon color="#3C4B6A"><Stamp></Stamp></el-icon>
          <span style="margin-left: 4px;color: #3C4B6A">{{ getNameByDeptId(item) }}</span>
        </span>
        </el-tag>
      </div>
    </template>
  </el-popover>

</template>

<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useDeptStore} from "@/stores/dept";
import {useUserStore} from "@/stores/user";
import {useCompanyStore} from "@/stores/company";

export default {
  props: {
    modelValue: {
      type: [String, Array],
      default: () => ""
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    width: {
      type: [String, Number],
      default: () => '100%'
    }
  },
  computed: {
    ...mapStores(useDeptStore, useCompanyStore, useFormStore, useUserStore),
    getDepts() {
      if (this.segmented === '我的部门') {
        return this.rawDepts.filter(filter => this.userStore.getDepts.indexOf(filter.id) !== -1);
      } else if (this.segmented === '全部部门') {
        if (this.keyword) {
          return this.rawDepts.filter(filter => filter.name.indexOf(this.keyword) > -1);
        } else {
          return this.depts;
        }
      }

      return [];
    }
  },
  data() {
    return {
      users: [],
      depts: [],
      rawDepts: [],
      list: null,
      keyword: '',
      dept: '',
      segmented: '全部部门'
    }
  },
  watch: {
    modelValue: {
      handler(newVal, val) {
        try {
          this.list = Array.isArray(newVal) ? newVal : (newVal ? [newVal] : []);
          this.rawDepts.forEach(dept => {
            dept.select = this.list.some(some => some === dept.id)
          })
        } catch (e) {

        }

      },
      immediate: true
    },
  },
  async created() {
    this.deptStore.list().then(({data}) => {
      this.rawDepts = data;
      this.rawDepts.forEach(dept => {
        dept.select = this.list.some(some => some === dept.id)
      })
      //组织tree数据结构
      this.depts = data.filter(filter => !filter.pid);
      this.getChildren(this.depts, data)
    });
  },
  methods: {
    getChildren(depts, data) {
      depts.forEach(item => {
        item.children = data.filter(filter => filter.pid === item.id);
        if (item.children.length > 0) {
          this.getChildren(item.children, data)
        }
      });
    },
    getNameByDeptId(item) {
      let match = this.rawDepts.filter(filter => filter.id === item);
      return match.length > 0 ? match[0].name : "";
    },
    handleChange(dept) {
      if (this.multiple) {
        if (dept.select) {
          this.list.push(dept.id);
        } else {
          this.list = this.list.filter(filter => filter !== dept.id);
        }

        this.$emit('update:modelValue', this.list);
      } else {
        this.list = [dept.id];
        this.$refs.popover.hide();
        this.$emit('update:modelValue', dept.id);
        this.$emit('change', dept)
      }
    },
    onClose(index) {
      let id = this.list.splice(index, 1)
      this.rawDepts.forEach(dept => {
        if (dept.id === id[0]) {
          dept.select = false;
        }
      })

      if (this.multiple) {
        this.$emit('update:modelValue', this.list);
      } else {
        this.$emit('update:modelValue', this.list.length === 0 ? "" : this.list[0]);
      }
    },
  }
}

</script>

<style scoped lang="scss">
ul {
  padding: 0;
  margin: 4px 0;
}

li {
  list-style-type: none;
  line-height: 28px;
  color: #595959;
  cursor: pointer;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #F5F7FA;
}

li:hover {
  background-color: #F5F8FC;
}


</style>