import {useUserStore} from "@/stores/user";
import _ from 'lodash'

window.$isRole = function (role) {
    return useUserStore().isRole(role);
}
window.$isDept = function (dept) {
    return useUserStore().isDept(dept);
}
window.$isUser = function (user) {
    return useUserStore().isUser(user);
}
window.$dayOffset = function dayOffset(dateString, days) {
    // 创建一个Date对象
    const date = new Date(dateString);

    // 检查日期是否有效
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date string');
    }

    // 设置新的日期（加上偏移量）
    date.setDate(date.getDate() + parseInt(days));

    // 格式化日期为YYYY-MM-DD格式
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1
    const day = String(date.getDate()).padStart(2, '0');

    // 返回格式化后的日期字符串
    return `${year}-${month}-${day}`;
}
window.$today = function(format = "yyyy-MM-dd HH:mm:ss") {
    var now = new Date();
    var year = now.getFullYear();
    var month = now.getMonth() + 1; // 月份是从0开始的，所以需要+1
    var day = now.getDate();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var seconds = now.getSeconds();

    // 补零操作，确保月、日、时、分、秒都是两位数
    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    hours = (hours < 10 ? "0" : "") + hours;
    minutes = (minutes < 10 ? "0" : "") + minutes;
    seconds = (seconds < 10 ? "0" : "") + seconds;

    return format.replaceAll("yyyy", year).replaceAll("MM", month).replaceAll("dd", day).replaceAll("HH", hours).replaceAll("mm", minutes).replaceAll("ss", seconds);
}


export const dynamicFunction = function (script, rawParams) {

    let params = _.cloneDeep(rawParams);
    Object.keys(rawParams).map(key => {
        if (key.startsWith("_property.")) {
            params[key.replace(".", "_")] = params[key];
            delete params[key];
        }
    });

    script = script.replaceAll("_property.", "_property_")
    return new Function(...Object.keys(params), script.includes("return") ?script: `return ${script}`)(...Object.values(params))
}

